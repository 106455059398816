import { useCallback } from 'react';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import {
  EditSealedCustomCollectionItemFormPayload,
  EditSportsCardCustomCollectionItemFormPayload,
} from '../EditCustomCollectionItemDialog/forms/EditCustomCollectionItemForm';
import useCollectionItemMutations from '../useCollectionItemMutations';
import { useCollectionPermissions } from '../useCollectionPermissions';
import { useAddCollectionItemDialogStore } from './store';
import { AddCollectionItemForms, OnAddCollectionItemFormSubmitFn } from './AddCollectionItemForms';
import { trackEvent } from '@/features/analytics/trackEvent';
import useCurrencyFormatter from '@/hooks/useCurrencyFormatter';
import { withErrorBoundary } from '@/sci-ui-components/ErrorBoundary/ErrorBoundary';
import { collectibleNames } from '@/sci-ui-components/collectibles/constants';
import { useOnValueChange } from '@/hooks/useOnValueChange';

export const AddCollectionItemDialog = withErrorBoundary(function AddCollectionItemDialog() {
  const { collectibleType, close, currentStep, isOpen, featuredCollectibleId } = useAddCollectionItemDialogStore();

  const { checkIfCanAddToCollection } = useCollectionPermissions();
  useOnValueChange(isOpen, () => {
    if (!isOpen) {
      return;
    }
    if (!checkIfCanAddToCollection({ collectibleType })) {
      close(false);
      return;
    }
    trackEvent({
      eventName: 'COLLECTION_ADD_STARTED',
      collectibleType,
    });
  });

  const { isMutating, addCollectibleToCollection, addCustomCollectibleToCollection } = useCollectionItemMutations();
  const { convertPriceToUSD } = useCurrencyFormatter();
  const handleCancel = useCallback(() => close(false), [close]);
  const handleSubmit = useCallback<OnAddCollectionItemFormSubmitFn>(
    (values) => {
      const { collectibleType, purchaseDetails } = values;
      if (values.featuredCollectibleId !== null) {
        const { featuredCollectibleId } = values;
        addCollectibleToCollection.mutate(
          {
            collectibleType,
            collectibleId: featuredCollectibleId,
            isCustom: false,
            purchaseDate: purchaseDetails.purchaseDate,
            purchasePricePerItem: convertPriceToUSD({ value: purchaseDetails.purchasePricePerItemInDisplayCurrency }),
            gradingPricePerItem: convertPriceToUSD({ value: purchaseDetails.gradingPricePerItemInDisplayCurrency }),
            quantity: purchaseDetails.quantity ?? 1,
            note: purchaseDetails.notes,
            categoryId: purchaseDetails.categoryId,
          },
          {
            onSuccess: () => close(true),
            onError: () =>
              trackEvent({
                eventName: 'COLLECTION_ADD_FAILED',
                collectibleType,
              }),
          }
        );
      } else {
        const { customCollectibleInfo, customCollectibleValueInfo } = values;
        const customCollectibleInfoSportsCard = customCollectibleInfo as EditSportsCardCustomCollectionItemFormPayload;
        const customCollectibleInfoSealed = customCollectibleInfo as EditSealedCustomCollectionItemFormPayload;
        addCustomCollectibleToCollection.mutate(
          {
            collectibleType,
            sportName: customCollectibleInfo.sportName,
            cardSetId: customCollectibleInfo.setId,
            cardSetName: customCollectibleInfo.setName,
            cardSetYear: customCollectibleInfo.setYear,
            currentValue: convertPriceToUSD({ value: customCollectibleValueInfo.currentPriceInDisplayCurency }),
            playerId: customCollectibleInfoSportsCard.playerId,
            playerName: customCollectibleInfoSportsCard.playerName,
            gradeName: customCollectibleInfoSportsCard.gradeName,
            variationName: customCollectibleInfoSportsCard.variationName,
            cardNumber: customCollectibleInfoSportsCard.cardNumber,
            specificQualifier: customCollectibleInfoSportsCard.specificQualifier,
            boxTypeName: customCollectibleInfoSealed.boxTypeName,
            customQuery: customCollectibleValueInfo.customQuery,
            purchaseDate: purchaseDetails.purchaseDate,
            purchasePricePerItem: convertPriceToUSD({ value: purchaseDetails.purchasePricePerItemInDisplayCurrency }),
            gradingPricePerItem: convertPriceToUSD({ value: purchaseDetails.gradingPricePerItemInDisplayCurrency }),
            quantity: purchaseDetails.quantity ?? 1,
            note: purchaseDetails.notes,
            categoryId: purchaseDetails.categoryId,
            imageUrl: customCollectibleValueInfo.imageUrl,
          },
          {
            onSuccess: () => close(true),
            onError: () =>
              trackEvent({
                eventName: 'COLLECTION_ADD_FAILED',
                collectibleType,
              }),
          }
        );
      }
    },
    [convertPriceToUSD, addCollectibleToCollection, addCustomCollectibleToCollection, close]
  );

  return (
    <ModalDialog
      open={isOpen}
      title={
        currentStep !== 'search' && !featuredCollectibleId
          ? `Add Manual ${collectibleNames[collectibleType].singular}`
          : 'Add to Collection'
      }
      onCancel={handleCancel}
      footer={null}
      variant="primary"
      width="fit-content"
    >
      <AddCollectionItemForms isLoading={isMutating} onCancel={handleCancel} onSubmit={handleSubmit} />
    </ModalDialog>
  );
});

import { DetailedHTMLProps, HTMLAttributes } from 'react';
import clsx from 'clsx';

import classes from './DefaultCellPadding.module.scss';

export type DefaultCellPaddingProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function DefaultCellPadding({ className, ...other }: DefaultCellPaddingProps) {
  return (
    <div {...other} className={classes.root}>
      <div className={clsx(classes.inner, className)} {...other} />
    </div>
  );
}

import { ColGroup, Column } from './types';

export function getColGroups<
  TRowData extends object,
  TRowKey extends string | number = number,
  TSortKey extends string = string,
  TFilters extends { [key: string]: any[] } = { [key: string]: any[] }
>(
  columns: Column<TRowData, TRowKey, TSortKey, TFilters>[],
  hasGroups: boolean
): {
  startIndicesSet: Set<number>;
  endIndicesSet: Set<number>;
  groups: ColGroup[];
} {
  if (!hasGroups) {
    const groups = [
      {
        start: 1,
        span: columns.length,
      },
    ];
    return {
      groups,
      startIndicesSet: new Set([0]),
      endIndicesSet: new Set([columns.length - 1]),
    };
  }
  const { startIndicesSet, endIndicesSet, groups } = columns.reduce<{
    groups: ColGroup[];
    prevColumn?: Column<TRowData, TRowKey, TSortKey, TFilters>;
    startIndicesSet: Set<number>;
    endIndicesSet: Set<number>;
  }>(
    (acc, column, index) => {
      const { groupId } = column;
      const { groups, prevColumn, startIndicesSet, endIndicesSet } = acc;
      const prevGroupId = prevColumn?.groupId;
      const lastGroup = groups[groups.length - 1];
      if (prevGroupId && prevGroupId === groupId && lastGroup) {
        lastGroup.span += 1;
        endIndicesSet.delete(index - 1);
        endIndicesSet.add(index);
      } else if (groupId) {
        groups.push({
          start: index + 1,
          span: 1,
        });
        startIndicesSet.add(index);
      }

      return {
        groups,
        startIndicesSet,
        endIndicesSet,
        prevColumn: column,
      };
    },
    { groups: [], startIndicesSet: new Set(), endIndicesSet: new Set() }
  );

  return {
    groups,
    startIndicesSet,
    endIndicesSet,
  };
}

import { useMemo } from 'react';
import { parseISO } from 'date-fns';
import { ChartColors } from '../../../../sci-ui-components/styles/chartColors';
import TimeLineChart, { LineConfig } from '../../../../sci-ui-components/charts/TimeLineChart/TimeLineChart';
import SubSectionHeader from '../../../../sci-ui-components/SubSectionHeader/SubSectionHeader';
import { createTrendLineEquation } from '../../../../sci-ui-components/charts/utils/trendLine';
import ChartSettingsDropdown from '../../ChartSettingsDropdown/ChartSettingsDropdown';
import { FullScreenContainer } from '../../../../sci-ui-components/FullScreen';
import OnlyIf from '../../../app/OnlyIf';
import useChartSettings from '../../useChartSettings';
import useChartFullScreenState from '../../useChartFullScreenState';
import { AggregatedUntrackedCollectibleSales } from '../types';

import classes from './UntrackedCollectiblesChart.module.scss';
import useFormatStatValue, { FormatStatValueFn } from 'hooks/useFormatStatValue';

export default function UntrackedCollectiblesChart({
  className,
  data,
  filtersDescriptionText,
  generatedQuery,
  isLoading,
  emptyMessage,
  noFullScreen = false,
}: {
  className?: string;
  data: AggregatedUntrackedCollectibleSales[];
  filtersDescriptionText: string;
  generatedQuery?: string | null;
  isLoading: boolean;
  emptyMessage: string;
  noFullScreen?: boolean;
}) {
  const { formatStatValue } = useFormatStatValue();
  const { chartSettings, setGroupBy, setTrend, setYBaseline } = useChartSettings({ dateRange: null });
  const [isFullScreen, toggleFullScreen] = useChartFullScreenState(noFullScreen);
  const chartSettingsControls = (
    <ChartSettingsDropdown
      chartSettings={chartSettings}
      setGroupBy={setGroupBy}
      setTrend={setTrend}
      setYBaseline={setYBaseline}
    />
  );

  const chartLines = useMemo<LineConfig<AggregatedUntrackedCollectibleSales, string>[]>(() => {
    const getXValue = (item: AggregatedUntrackedCollectibleSales) => parseISO(item.groupKey).valueOf();
    const trendLineEquation = createTrendLineEquation({
      data,
      getXValue,
      getYValue: (item) => item.averageSalePrice,
    });
    return [
      {
        dataKey: (item) => item.averageSalePrice ?? null,
        label: filtersDescriptionText,
        color: ChartColors.L1,
        key: 'averageSalePrice',
        strokeWidth: 3,
        renderStatsInTooltip,
      },
      {
        dataKey: (item) => trendLineEquation(getXValue(item)),
        label: filtersDescriptionText,
        color: ChartColors.L1,
        key: 'averageSalePrice_trend',
        strokeWidth: 3,
        renderStatsInTooltip,
        isTrend: true,
      },
    ];
  }, [filtersDescriptionText, data]);

  return (
    <FullScreenContainer
      className={className}
      fullScreenClassName={classes.fullscreen}
      isFullScreen={isFullScreen}
      toggleFullScreen={toggleFullScreen}
    >
      <TimeLineChart
        isLoading={isLoading}
        data={data || []}
        lines={chartLines}
        height={400}
        chartHeader={
          <div>
            <SubSectionHeader>{filtersDescriptionText}</SubSectionHeader>
            <p className={classes.dataMessage}>
              Chart Other Card data will only show results for up to the past 90 days.
            </p>
            <OnlyIf isAdmin>
              {!!generatedQuery && (
                <p className={classes.query}>
                  <span className={classes.queryLabel}>Generated query:</span> {generatedQuery}
                </p>
              )}
            </OnlyIf>
          </div>
        }
        formatYAxisValue={(value) =>
          formatStatValue({
            value,
            type: 'price',
          })
        }
        xAxisDataKey="groupKey"
        chartSettings={chartSettingsControls}
        connectNulls
        withDots
        isFullScreen={isFullScreen}
        toggleFullScreen={toggleFullScreen}
        emptyMessage={emptyMessage}
        hideNonTrendLines={chartSettings.showTrend === 'trend'}
        hideTrendLines={chartSettings.showTrend === 'line'}
        yAxisStartFromMin={chartSettings.yBaseline === 'min'}
      />
    </FullScreenContainer>
  );
}

function renderStatsInTooltip(
  item: AggregatedUntrackedCollectibleSales,
  {}: { color: string | undefined },
  formatStatValue: FormatStatValueFn
) {
  if (!item || item.averageSalePrice === null) {
    return null;
  }
  return (
    <>
      <span>
        {formatStatValue({
          value: item.totalSalesCount,
          type: 'count',
        })}
        {' Sales'}
      </span>
      <span>
        Avg:{' '}
        {formatStatValue({
          value: item.averageSalePrice,
          type: 'price',
        })}
      </span>
    </>
  );
}

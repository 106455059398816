import { useState } from 'react';
import CurrencySelector, { CurrencyDisclaimer } from '../CurrencySelector/CurrencySelector';
import { UserPreferencesModalProps } from './types';
import ModalDialog from 'sci-ui-components/ModalDialog/ModalDialog';
import useUserSettings, { currencyUpdateSuccesMessage } from 'features/settings/useUserSettings';

interface Props extends UserPreferencesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function UserPreferencesModal({ onClose, isOpen = false }: Props) {
  const [newCurrencyCode, setNewCurrencyCode] = useState<string>('USD');
  const { updateMutation } = useUserSettings();

  return (
    <ModalDialog
      width={720}
      visible={isOpen}
      title={`User Preferences`}
      okText={'Update'}
      onCancel={() => {
        onClose();
      }}
      okButtonProps={{
        disabled: updateMutation.isLoading,
      }}
      cancelButtonProps={{
        disabled: updateMutation.isLoading,
      }}
      cancelText="Cancel"
      confirmLoading={updateMutation.isLoading}
      onOk={() => {
        updateMutation.mutate(
          {
            globalSettings: {
              preferences: {
                currencyCode: newCurrencyCode,
              },
            },
            successMessage: currencyUpdateSuccesMessage,
          },
          {
            onSuccess: () => {
              onClose();
            },
          }
        );
      }}
    >
      <CurrencySelector onChange={(currencyCode) => setNewCurrencyCode(currencyCode)} />
      <CurrencyDisclaimer />
    </ModalDialog>
  );
}

import { PopulationChartDataPoint } from '../usePopulationCountChartData';

export interface PopulationCountSummaryStats {
  populationCount: number;
  populationHigher: number;
  totalGrowthNumber: number;
  totalGrowthPercentage: number | null;
}

export default function getSummaryStats(points: PopulationChartDataPoint[]): PopulationCountSummaryStats | null {
  if (!points?.length) {
    return null;
  }
  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];
  const populationCountAtEnd = lastPoint.count ?? 0; // NOTE: last and first points count value should never be null
  const populationHigherAtEnd = lastPoint.higher ?? 0; // NOTE: last and first points count value should never be null
  const populationCountAtStart = firstPoint.count ?? 0;
  const totalGrowthNumber = populationCountAtEnd - populationCountAtStart;
  const totalGrowthPercentage =
    populationCountAtStart === 0 ? null : (totalGrowthNumber * 100) / populationCountAtStart;

  return {
    totalGrowthPercentage,
    totalGrowthNumber,
    populationCount: populationCountAtEnd,
    populationHigher: populationHigherAtEnd,
  };
}

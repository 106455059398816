import { useQueryClient } from 'react-query';
import { useListCollectionItemsKeyPrefix } from './useListCollectionItems';
import { getUseCollectionStatsQueryKey } from './useCollectionStats';
import { MmApiInput, useAuthenticatedMMApiMutation, mmApiClient, MmApiOutput } from 'services/mmApiX';
import { showError, showSuccess } from 'services/toaster';

export type UpdateCollectibleCustomPriceInput = Exclude<
  MmApiInput['private']['collection']['updateCollectibleCustomPrice'],
  void
>;
export type UpdateCollectibleCustomPriceOutput = MmApiOutput['private']['collection']['updateCollectibleCustomPrice'];

export default function useCustomFeaturedCollectiblePriceMutations() {
  const queryClient = useQueryClient();
  const updateCollectibleCustomPrice = useAuthenticatedMMApiMutation(
    (params: UpdateCollectibleCustomPriceInput) =>
      mmApiClient.private.collection.updateCollectibleCustomPrice.mutate(params),
    {
      mutationKey: ['updateCollectibleCustomPrice'],
      onSuccess: () => {
        queryClient.refetchQueries([useListCollectionItemsKeyPrefix]);
        queryClient.invalidateQueries(
          getUseCollectionStatsQueryKey({
            params: {
              filters: { collectibleType: 'sports-card' },
            },
          })
        );
        showSuccess({
          description: `Successfully updated last sale price`,
        });
      },
      onError: () => {
        showError({
          description: 'Failed to update last sale price. Please try again.',
        });
      },
    }
  );

  return {
    updateCollectibleCustomPrice,
  };
}

export const collectionDataKeys = [
  'quantity',
  'date-purchased',
  'purchase-price-per-card',
  'notes',
  'category',
] as const;
export type CollectionDataKey = (typeof collectionDataKeys)[number];

export const commonKeys = ['sport', 'year', 'set'] as const;
export type CommonKey = (typeof commonKeys)[number];

export const cardKeys = ['player-name', 'variation', 'grade', 'card-number', 'specific-qualifier'] as const;
export type CardKey = CommonKey | (typeof cardKeys)[number];

export const waxKeys = ['box-type'] as const;
export type WaxKey = CommonKey | (typeof waxKeys)[number];

export type CollectionImportDataKey = CollectionDataKey | CardKey | WaxKey;

import { parseISO, startOfMonth, startOfWeek } from 'date-fns';
import { formatISODate } from '../../../../sci-ui-components/utils/date';
import { GroupOption } from '../../useChartSettings';
import { PopulationChartDataPoint } from '../usePopulationCountChartData';

export interface ExtendedPopulationCountChartPoint {
  date: string;
  count: number | null;
  growthNumber: number | null;
  growthPercentage: number | null;
}

export default function getExtendedChartPoints(
  points: PopulationChartDataPoint[],
  {
    groupBy,
  }: {
    groupBy: GroupOption;
  }
): ExtendedPopulationCountChartPoint[] {
  const goupedPoints = points.reduce<ExtendedPopulationCountChartPoint[]>((acc, point) => {
    const date = getGroupValue(point, groupBy);
    if (!acc.length || acc[acc.length - 1].date !== date) {
      // NOTE: new date key found -> adding point
      acc.push({
        date,
        count: point.count,
        growthPercentage: null,
        growthNumber: null,
      });
    }
    const currentPoint = acc[acc.length - 1];
    const prevPoint = acc[acc.length - 2];
    if (!!prevPoint && prevPoint?.count !== null && currentPoint.count !== null) {
      // NOTE: have 2 consecutive data points -> calculating change
      currentPoint.growthNumber = currentPoint.count - prevPoint.count;
      currentPoint.growthPercentage =
        prevPoint.count === 0 ? null : (currentPoint.growthNumber * 100) / prevPoint.count;
    }
    return acc;
  }, []);
  return goupedPoints;
}

function getGroupValue(item: PopulationChartDataPoint, groupBy: GroupOption): string {
  switch (groupBy) {
    case 'day':
      return item.date;
    case 'month':
      return formatISODate(startOfMonth(parseISO(item.date)));
    case 'week':
      return formatISODate(startOfWeek(parseISO(item.date)));
    default:
      return item.date;
  }
}

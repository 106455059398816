import React from 'react';

import PopulationCount from '../../../collectibles/PopulationCount/PopulationCount';
import RookieIndicator from '../../../sport-cards/RookieIndicator/RookieIndicator';
import GradeIndicator from '../../../sport-cards/GradeIndicator/GradeIndicator';
import { formatDateInTimeZone } from '../../../utils/date';

import { SportsCardSearch } from '../../../../services/sciApi/search/types';
import SearchItem from './SearchItem';

import classes from './SearchItem.module.scss';
import useFormatStatValue from 'hooks/useFormatStatValue';
import { makeSearchDescription } from 'features/search/utils/searchDescription';

export default function SportSearchItem({
  selected,
  onChartButtonClick,
  onClick,
  onPopulationCountClick,
  ...searchItem
}: SportsCardSearch & {
  selected?: boolean;
  onChartButtonClick?: () => void;
  onClick?: () => void;
  onPopulationCountClick?: () => void;
}) {
  const { formatStatValue } = useFormatStatValue();
  const { descriptionLine1, descriptionLine2, title } = makeSearchDescription(searchItem, 'sports-card');
  return (
    <SearchItem
      selected={selected}
      imageUrl={searchItem.imageUrl}
      title={title}
      titleSuffix={<RookieIndicator isRookie={searchItem.rc} className={classes.rookieIndicator} />}
      descriptionText={descriptionLine1}
      extraText={descriptionLine2}
      gradeAndCount={
        <>
          <GradeIndicator grade={searchItem.gradeName} />
          <PopulationCount
            className={classes.popCount}
            count={searchItem.populationCount}
            onClick={onPopulationCountClick}
          />
        </>
      }
      priceText={
        searchItem.allEndAvg && searchItem.allEndAvg > 0
          ? formatStatValue({ value: searchItem.allEndAvg, type: 'price' })
          : ''
      }
      saleDateText={searchItem.lastSaleDate ? formatDateInTimeZone(searchItem.lastSaleDate) : 'No Recorded Sales'}
      onChartButtonClick={onChartButtonClick}
      onClick={() => onClick?.()}
    />
  );
}

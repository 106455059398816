import { useMemo } from 'react';
import { CollectibleType } from '@sportscardinvestor/schemas';
import { UntrackedCollectibleSalesSearchFilters } from '../sales/UntrackedCollectibleSalesSearch/types';
import {
  CustomCollectible,
  CustomSealedWaxCollectible,
  CustomSportsCardCollectible,
} from '../../sci-ui-components/types/collectible';

const useUntrackedCollectibleSearchFilters = ({
  customCollectible,
  collectibleType,
}: {
  customCollectible?: CustomCollectible;
  collectibleType: CollectibleType;
}) => {
  const filters = useMemo<UntrackedCollectibleSalesSearchFilters>(() => {
    if (!customCollectible) {
      return {};
    }
    if (customCollectible.customQuery) {
      return { singleFieldQuery: customCollectible.customQuery };
    }
    switch (collectibleType) {
      case 'sports-card': {
        const customSportsCardCollectible = customCollectible as CustomSportsCardCollectible;
        return {
          cardSetName:
            customSportsCardCollectible.cardSet?.name ?? customSportsCardCollectible.customCardSetName ?? undefined,
          cardSetVariationName: customSportsCardCollectible.variationName ?? undefined,
          playerName: customSportsCardCollectible.player?.name ?? customSportsCardCollectible.playerName ?? undefined,
          sportName:
            customSportsCardCollectible.sportName ?? customSportsCardCollectible?.player?.sport?.name ?? undefined,
          year: customSportsCardCollectible?.customCardSetYear ? Number(customCollectible) : undefined,
          sportsCardGradeName: customSportsCardCollectible?.gradeName ?? undefined,
        };
      }
      case 'sealed-wax-card': {
        const customSealedWaxCollectible = customCollectible as CustomSealedWaxCollectible;
        return {
          cardSetName:
            customSealedWaxCollectible.cardSet?.name ?? customSealedWaxCollectible.customCardSetName ?? undefined,
          sportName: customSealedWaxCollectible.sportName ?? undefined,
          year: customSealedWaxCollectible?.customCardSetYear ? Number(customCollectible) : undefined,
          sealedWaxBoxTypeName: customSealedWaxCollectible?.boxTypeName ?? undefined,
        };
      }
      default:
        return {};
    }
  }, [customCollectible, collectibleType]);

  return {
    filters,
  };
};

export default useUntrackedCollectibleSearchFilters;

import { Dropdown } from 'antd';
import { faFilter } from '@fortawesome/pro-light-svg-icons';
import { faFilter as faFilterSolid } from '@fortawesome/pro-solid-svg-icons';
import IconButton from '../../../buttons/IconButton/IconButton';
import { Checkbox } from '../../../forms/Checkboxes/Checkboxes';

import classes from './FilterButton.module.scss';

export default function FilterButton<TFilters extends { [key: string]: any[] } = { [key: string]: any[] }>({
  filters = {} as TFilters,
  filterKey,
  filterOptions,
  onFiltersChange,
  isSingleSelect,
}: {
  filters?: TFilters;
  filterKey: keyof TFilters;
  filterOptions: { value: TFilters[string][number]; label: string }[];
  onFiltersChange: (newFilters: TFilters) => void;
  isSingleSelect?: boolean;
}) {
  const selectedValues = filters[filterKey] ?? [];
  const selectedValuesSet = new Set(selectedValues);
  const isApplied = selectedValuesSet.size > 0;
  const allSelected = filterOptions.every(({ value }) => selectedValuesSet.has(value));

  const handleSelect = (value: TFilters[string][number]) => {
    const newKeys = selectedValuesSet.has(value)
      ? isSingleSelect
        ? []
        : selectedValues.filter((v) => v !== value)
      : isSingleSelect
      ? [value]
      : [...selectedValues, value];
    onFiltersChange({
      ...filters,
      [filterKey]: newKeys,
    });
  };
  const handleSelectAll = () => {
    const newKeys = isApplied || isSingleSelect ? [] : filterOptions.map(({ value }) => value);
    onFiltersChange({
      ...filters,
      [filterKey]: newKeys,
    });
  };

  const overlay = (
    <div className={classes.overlay}>
      <Checkbox
        className={classes.allCheckbox}
        checked={allSelected}
        indeterminate={isApplied && !allSelected}
        onChange={handleSelectAll}
        size="small"
      >
        {isApplied || isSingleSelect ? 'Clear' : 'Select all'}
      </Checkbox>
      <ul className={classes.list}>
        {filterOptions.map(({ label, value }) => {
          const isSelected = selectedValues.includes(value);
          const id = `filter-${String(filterKey)}-${value}`;
          return (
            <li
              key={value}
              className={classes.item}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                className={classes.checkbox}
                checked={isSelected}
                id={id}
                onChange={() => handleSelect(value)}
                size="small"
                thin
              >
                {label}
              </Checkbox>
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <Dropdown placement="bottomRight" overlay={overlay} trigger={['click']}>
      <IconButton
        faIcon={faFilter}
        faHighlightedIcon={faFilterSolid}
        title="Change Filter"
        highlighted={isApplied}
        color="inherit"
      />
    </Dropdown>
  );
}

import type { CollectionItem } from '@sportscardinvestor/schemas';
import type {
  CollectionItem as OldCollectionItem,
  BaseCollectionItem as OldBaseCollectionItem,
} from '../../../sci-ui-components/types/collectionItem';

export function temp_convertCollectionItemToOldCollectionItem(item: CollectionItem): OldCollectionItem {
  const { collectibleType, id: collectionId } = item;
  const old: OldBaseCollectionItem = {
    ...item,
    datePurchased: item.datePurchased ?? '',
    collectionCategories: item.collectionCategories.map((v) => ({
      ...v,
      collectionId,
      collectibleType,
    })),
    deletedAt: null,
    lastSale: (item.isCustom ? item.customCollectible?.priceDate : item.collectible?.stats?.lastSaleDate) ?? null,
    customPrice: item.customPrice?.price,
  };

  return old;
}

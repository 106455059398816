import html2canvas from 'html2canvas';
import { showSuccess } from 'services/toaster';
import useBrowserEnvironmentState from 'hooks/useBrowserEnvironmentState';
import { usePublicMMApiMutation, mmApiClient, useAuthenticatedMMApiMutation } from 'services/mmApiX/index';
import useAuth from '@/features/auth/useAuth';
import useStableFunctionIdentity from '@/sci-ui-components/hooks/useStableFunctionIdentity';

export interface BugReportInput {
  problemDescription: string;
}

export function useSubmitBugReport() {
  const { data: browserEnvironmentState } = useBrowserEnvironmentState();
  const { isLoggedIn } = useAuth();
  const { mutateAsync: submitBugReportAnonymous } = usePublicMMApiMutation(
    mmApiClient.public.help.submitBugReport.mutate
  );
  const { mutateAsync: submitBugReportAuthenticated } = useAuthenticatedMMApiMutation(
    mmApiClient.private.help.submitBugReport.mutate
  );

  const submitBugReport = useStableFunctionIdentity(async ({ problemDescription }: BugReportInput) => {
    try {
      // https://html2canvas.hertzen.com/configuration
      const canvas = await html2canvas(document.body, { logging: false });
      document.body.appendChild(canvas);
      const { cookieEnabled, language, userAgent } = navigator;
      await (isLoggedIn ? submitBugReportAuthenticated : submitBugReportAnonymous)({
        cookieEnabled,
        language,
        userAgent,
        href: location.href,
        imageBase64: canvas.toDataURL('image/png'),
        problemDescription,
        ...browserEnvironmentState,
      });
      showSuccess({
        message: 'Success!',
        description:
          'Thank you for your submission. Our team will review your issue and get in touch to help resolve it as quickly as possible.',
      });
    } catch (err) {
      console.error(err);
    }
  });

  return {
    submitBugReport,
  };
}

import {
  isSealedWaxSearch,
  isSportCardSearch,
  MaybeString,
  SealedWaxSearch,
  SportsCardSearch,
} from '../services/sciApi/search/types';

const getTermsToHighlight = (
  customCollectible: SportsCardSearch | SealedWaxSearch,
  popularCollectible: SportsCardSearch | SealedWaxSearch
): string[] => {
  const terms: string[] = [];

  const addTermIfDifferent = (terms: string[], term1: MaybeString, term2: MaybeString) => {
    if (areTermsDifferent(term1, term2) && term1) {
      terms.push(term1);
    }
  };

  const areTermsDifferent = (str1: MaybeString, str2: MaybeString) => {
    return str1?.toLowerCase() !== str2?.toLowerCase();
  };

  // Generic terms, applicable to both cards and wax
  addTermIfDifferent(terms, customCollectible?.setYear, popularCollectible?.setYear);
  addTermIfDifferent(terms, customCollectible?.setName, popularCollectible?.setName);

  if (isSportCardSearch(customCollectible) && isSportCardSearch(popularCollectible)) {
    // Terms only applicable to cards
    addTermIfDifferent(terms, customCollectible?.playerName, popularCollectible?.playerName);
    addTermIfDifferent(terms, customCollectible?.cardNumber, popularCollectible?.cardNumber);
    addTermIfDifferent(terms, customCollectible?.variationName, popularCollectible?.variationName);
    addTermIfDifferent(terms, customCollectible?.specificQualifier, popularCollectible?.specificQualifier);
    addTermIfDifferent(terms, customCollectible?.printRun, popularCollectible?.printRun);
    addTermIfDifferent(terms, customCollectible?.gradeName, popularCollectible?.gradeName);
  }

  if (isSealedWaxSearch(customCollectible) && isSealedWaxSearch(popularCollectible)) {
    // Terms only applicable to wax
    addTermIfDifferent(terms, customCollectible?.boxTypeName, popularCollectible?.boxTypeName);
  }
  return terms;
};

const getTermsToHighlightFromRawStrings = (a: string, b: string) => {
  const aTerms = a.split(' ').map((term) => term.trim().toLowerCase());
  const bTerms = new Set(b.split(' ').map((term) => term.trim().toLowerCase()));
  return aTerms.filter((aTerm) => !bTerms.has(aTerm));
};

export { getTermsToHighlight, getTermsToHighlightFromRawStrings };

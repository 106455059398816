import { CollectibleType } from 'sci-ui-components/types/collectibleType';

export function getApiCollectibleType(collectibleType: CollectibleType) {
  switch (collectibleType) {
    case 'sports-card': {
      return 'CARD';
    }
    case 'sealed-wax-card': {
      return 'WAX';
    }
    default: {
      return null;
    }
  }
}

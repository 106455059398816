import { YoutubeIntroDialogInfoProps } from './types';
import createDialogSingleton, { DialogProviderProps } from 'sci-ui-components/utils/createDialogSingleton';

const { invokeDialog: editYoutubeIntroDialog, registerDialog } = createDialogSingleton<
  YoutubeIntroDialogInfoProps,
  void
>();

export { editYoutubeIntroDialog, registerDialog };
export type YoutubeIntroDialogProviderProps = DialogProviderProps<YoutubeIntroDialogInfoProps, void>;

import { UntrackedCollectibleSalesSearchFilters } from '../types';

export default function checkIfHasMinimalFilters(filters: UntrackedCollectibleSalesSearchFilters): boolean {
  if (filters.singleFieldQuery) {
    return true;
  }
  if (filters.playerName && filters.sportsCardGradeName) {
    return true;
  }
  if (filters.sportName && filters.sealedWaxBoxTypeName) {
    return true;
  }
  if (filters.cardSetName) {
    return true;
  }
  if (filters.termsToInclude?.length) {
    return true;
  }
  return false;
}

import { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import ChartUntrackedCollectiblesDialog from './ChartUntrackedCollectiblesDialog';
import { registerDialog, ChartUntrackedCollectiblesDialogProviderProps } from './index';

type State = Partial<ChartUntrackedCollectiblesDialogProviderProps> & {
  isOpen: boolean;
};

export default withErrorBoundary(function ChartUntrackedCollectiblesDialogProvider({ id }: { id: string }) {
  const [{ isOpen, customCollectible, collectibleType, resolve }, setState] = useState<State>({
    isOpen: false,
  });

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          ...props,
          isOpen: true,
        });
      }),
    [id]
  );

  return (
    <ChartUntrackedCollectiblesDialog
      isOpen={isOpen}
      customCollectible={customCollectible!}
      collectibleType={collectibleType!}
      onClose={(hasGoneToCharts: boolean) => {
        setState((s) => ({ ...s, isOpen: false }));
        resolve?.(hasGoneToCharts);
      }}
    />
  );
});

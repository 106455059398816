import { AlertThreshold } from 'sci-ui-components/types/priceAlert';
import SimpleSelect from 'sci-ui-components/forms/SimpleSelect/SimpleSelect';

interface Props {
  onChange: (threshold: AlertThreshold | null) => void;
  value: AlertThreshold | null;
  id?: string;
}

export default function PriceThresholdSelect({ onChange, value, id }: Props) {
  return (
    <SimpleSelect
      options={[
        { value: 'Above', label: 'Above' },
        { value: 'Below', label: 'Below' },
      ]}
      value={value}
      onChange={onChange}
      id={id}
    />
  );
}

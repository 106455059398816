import { createContext, ReactNode, useEffect, useMemo, useCallback } from 'react';
import { useRouter } from 'next/router';
import { isValidCollectibleType, CollectibleType } from '../../sci-ui-components/types/collectibleType';
import useQueryString, { getStringParam } from '../../hooks/useQueryString';
import useLocalStorageValue from '../../hooks/useLocalStorageValue';
import { useActiveRouteCollectibleTypes } from '../app/useNavigationMenuItems';
import navigationConfig from '../app/navigationConfig';
import { Route } from '../app/constants';
import { trackEvent } from '../analytics/trackEvent';
import { collectibleTypeQueryKey } from './constants';
import { CollectibleTypeValueContextProvider } from './CollectibleTypeValueContext';

const defaultValue: CollectibleType = 'sports-card';
const storageKey = 'sci_collectible_type';

export interface CollectibleTypeContextValue {
  collectibleType: CollectibleType;
  setCollectibleType: (collectibleType: CollectibleType) => void;
}
export const CollectibleTypeContext = createContext<CollectibleTypeContextValue | undefined>(undefined);

export default function CollectibleTypeContextProvider({ children }: { children: ReactNode | ReactNode[] }) {
  const router = useRouter();
  const { query, setQueryParam } = useQueryString();
  const collectibleTypeFromQuery = useMemo(() => {
    const typeFromQs = getStringParam<CollectibleType | null>(query, collectibleTypeQueryKey, null);
    if (typeFromQs && isValidCollectibleType(typeFromQs)) {
      return typeFromQs;
    }
    return null;
  }, [query]);

  const [storageValue, setStorageValue] = useLocalStorageValue(storageKey);
  const collectibleTypeFromStorage = useMemo(() => {
    if (storageValue && isValidCollectibleType(storageValue)) {
      return storageValue;
    }
    return null;
  }, [storageValue]);

  const collectibleTypesFromActiveRoute = useActiveRouteCollectibleTypes(navigationConfig);

  let collectibleType = collectibleTypeFromQuery ?? collectibleTypeFromStorage ?? defaultValue;
  if (collectibleTypesFromActiveRoute.length) {
    // NOTE: if user opens direct url, they may end up on a page that does not support current collectible type
    // NOTE: if current page supports only some collectible types, use one of those types
    collectibleType =
      collectibleTypesFromActiveRoute.find((t) => t === collectibleType) ?? collectibleTypesFromActiveRoute[0];
  }

  const setCollectibleType = useCallback(
    (newCollectibleType: CollectibleType) => {
      if (collectibleTypesFromActiveRoute.length && !collectibleTypesFromActiveRoute.includes(newCollectibleType)) {
        // NOTE: current page does not support new collectible type => redirecct to dashboard with the new collectible type
        router.push(`${Route.Dashboard}?${collectibleTypeQueryKey}=${newCollectibleType}`);
      } else {
        // If "/charts?" is part of the URL it means we were on a Chart page (not chart search), and we want to
        // forcefully redirect a user to the chart search page when switching universes.
        if (router.asPath.includes('/charts?')) {
          router.push(`${Route.ChartsSearch}?${collectibleTypeQueryKey}=${newCollectibleType}`);
        } else {
          // query takes priority so it is enough to set value there
          // also simultaneously clear any other query string params
          setQueryParam(collectibleTypeQueryKey, newCollectibleType, true);
        }
      }
      trackEvent({
        eventName: 'COLLECTIBLE_TYPE_CHANGED',
        collectibleType: newCollectibleType,
      });
    },
    [setQueryParam, router, collectibleTypesFromActiveRoute]
  );

  useEffect(() => {
    // sync Query String
    if (collectibleTypeFromQuery !== collectibleType) {
      setQueryParam(collectibleTypeQueryKey, collectibleType);
    }
  }, [collectibleType, collectibleTypeFromQuery, setQueryParam]);

  useEffect(() => {
    // sync LocalStorage
    if (collectibleTypeFromStorage !== collectibleType) {
      setStorageValue(collectibleType);
    }
  }, [collectibleType, collectibleTypeFromStorage, setStorageValue]);

  const value = useMemo(
    () => ({
      setCollectibleType,
      collectibleType,
    }),
    [collectibleType, setCollectibleType]
  );

  return (
    <CollectibleTypeContext.Provider value={value}>
      <CollectibleTypeValueContextProvider collectibleType={collectibleType}>
        {children}
      </CollectibleTypeValueContextProvider>
    </CollectibleTypeContext.Provider>
  );
}

import { useEffect } from 'react';
import YoutubeIntroDialog from './YoutubeIntroDialog';
import { registerDialog } from './index';
import useLocalStorageValue from 'hooks/useLocalStorageValue';

const storageKey = 'sci_user_has_seen_video';

export default function YoutubeIntroDialogProvider({ id }: { id: string }) {
  const [_hasSeenVideo, setHasSeenVideo] = useLocalStorageValue<boolean | null>(storageKey, true);

  //const hasSeenVideo = _hasSeenVideo === true;

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setHasSeenVideo(false);
      }),
    [id, setHasSeenVideo]
  );

  return (
    <YoutubeIntroDialog
      // TODO uncomment when we have the video
      isOpen={false}
      onClose={() => {
        setHasSeenVideo(true);
      }}
    />
  );
}

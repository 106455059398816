import { CollectibleType } from '@sportscardinvestor/schemas';
import { create } from 'zustand';
import { trackEvent } from '@/features/analytics/trackEvent';

interface EditCollectionItemPurchaseDetailsDialogState {
  collectionItemId: number | null;
  collectibleType: CollectibleType;
  close: () => void;
}

const defaultCollectibleType: CollectibleType = 'sports-card';

export const useEditCollectionItemPurchaseDetailsDialogStore = create<EditCollectionItemPurchaseDetailsDialogState>()(
  (set) => ({
    collectionItemId: null,
    collectibleType: defaultCollectibleType,
    close: () => set({ collectionItemId: null, collectibleType: defaultCollectibleType }),
  })
);

export interface EditCollectionItemPurchaseDetailsDialogProps {
  collectionItemId: number;
  collectibleType: CollectibleType;
}
export function showEditCollectionItemPurchaseDetailsDialog({
  collectionItemId,
  collectibleType,
}: EditCollectionItemPurchaseDetailsDialogProps) {
  useEditCollectionItemPurchaseDetailsDialogStore.setState({
    collectionItemId,
    collectibleType,
  });
  trackEvent({
    eventName: 'COLLECTION_EDIT_PURCHASE_DETAILS_STARTED',
    collectibleType,
    collectionItemId,
  });
}

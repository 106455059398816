import React from 'react';
import Button from '../../sci-ui-components/buttons/Button/Button';
import classes from './SignupIncentiveModal.module.scss';
import ModalDialog from 'sci-ui-components/ModalDialog/ModalDialog';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function SignupIncentiveModal({ isOpen, onClose }: Props) {
  return (
    <ModalDialog
      className={classes.modalDialogWidth}
      visible={isOpen}
      title={`This feature is not available to the public.`}
      onCancel={onClose}
      footer={null}
    >
      <div className={classes.mainText}>
        Sign up today to access all features and experience the hobby&apos;s most powerful collection tracking and
        analytics platform!
      </div>
      <div className={classes.amountText}>GET A 7 DAY FREE TRIAL</div>
      <div className={classes.couponText}>
        when you use coupon code{' '}
        <b>
          <i>TRIAL</i>
        </b>
      </div>
      <Button onClick={() => {}} href={'https://marketmoversapp.com/'} type="primary" target="_blank">
        TRY FREE TODAY!{' '}
      </Button>
    </ModalDialog>
  );
}

import { useMemo } from 'react';
import { CollectionItem } from '../../sci-ui-components/types/collectionItem';
import { Collectible } from '../../sci-ui-components/types/collectible';
import { collectionItemHasNotes } from './helpers/collectionItemHasNotes';
import { collectionItemNoteDialog } from './CollectionItemNoteDialog/index';
import { markAsSoldDialog } from './MarkAsSoldDialog/index';
import useCollectionItemMutations from './useCollectionItemMutations';
import { isCollectionItemSold } from './helpers/isCollectionItemSold';
import {
  showConvertCollectionItemGradeDialog,
  canConvertCollectionItemGrade,
} from './ConvertCollectionItemGradeDialog/store';
import { showEditCustomCollectionItemDialog } from './EditCustomCollectionItemDialog/store';
import { showEditCollectionItemPurchaseDetailsDialog } from './EditCollectionItemPurchaseDetailsDialog/store';
import { useCollectionItem } from './useListCollectionItems';
import useCurrencyFormatter from 'hooks/useCurrencyFormatter';

export function useCommonCollectionActions() {
  const { deleteCollectionItem } = useCollectionItemMutations();
  const { convertPriceFromUSD } = useCurrencyFormatter();

  return useMemo(
    () => ({
      markAsSold: ({ collectionItem }: { collectionItem: CollectionItem }) => {
        const isSold = !!collectionItem.dateSold;
        if (isSold !== true) {
          markAsSoldDialog({
            initialValues: {
              ...collectionItem,
              purchasePricePerItem: convertPriceFromUSD({ value: collectionItem.purchasePricePerItem ?? 0 })!,
              purchasePriceTotal: convertPriceFromUSD({ value: collectionItem.purchasePriceTotal ?? 0 })!,
            },
            collectibleType: collectionItem.collectibleType,
          });
        }
      },
      updateNotes: ({ collectionItem }: { collectionItem: CollectionItem }) =>
        collectionItemNoteDialog({
          initialValues: collectionItem,
        }),
      checkIfHasNotes: ({ collectionItem }: { collectionItem: CollectionItem }) =>
        collectionItemHasNotes(collectionItem),
      deleteItem: (collectionItemId: number) => deleteCollectionItem(collectionItemId),
      showConvertCollectionItemGradeDialog,
      canConvertCollectionItemGrade,
      showEditCollectionItemPurchaseDetailsDialog,
      showEditCustomCollectionItemDialog,
    }),
    [deleteCollectionItem, convertPriceFromUSD]
  );
}

export interface CommonCollectionItemActions {
  markAsSold?: () => void;
  hasNotes: boolean;
  updateNotes: () => void;
  deleteItem: () => void;
  showConvertCollectionItemGradeDialog: (params?: { newGradeCollectibleId?: number }) => void;
  canConvertCollectionItemGrade: boolean;
  showEditCollectionItemPurchaseDetailsDialog: () => void;
  /**
   * Only defined for custom items
   */
  showEditCustomCollectionItemDialog: undefined | (() => void);
  isCustom: boolean;
}

export function useCommonCollectionItemActions({
  collectionItem,
  collectible,
}: {
  collectionItem: CollectionItem;
  collectible: Collectible;
}) {
  const collectibleType = collectionItem.collectibleType;
  const isSold = isCollectionItemSold(collectionItem);
  const actions = useCommonCollectionActions();

  const { collectionItem: extendedCollectionItem } = useCollectionItem({
    collectionItemId: collectionItem.id,
    collectibleType: collectible.collectibleType,
  }); // NOTE: delete this once collectionItem is of CollectionItem type from multiverse

  return useMemo<CommonCollectionItemActions>(
    () => ({
      hasNotes: actions.checkIfHasNotes({ collectionItem }),
      markAsSold: isSold ? undefined : () => actions.markAsSold({ collectionItem }),
      updateNotes: () => actions.updateNotes({ collectionItem }),
      deleteItem: () => actions.deleteItem(collectionItem.id),
      showEditCollectionItemPurchaseDetailsDialog: () =>
        showEditCollectionItemPurchaseDetailsDialog({ collectibleType, collectionItemId: collectionItem.id }),
      isCustom: extendedCollectionItem?.isCustom ?? false,
      showEditCustomCollectionItemDialog: extendedCollectionItem?.isCustom
        ? () => showEditCustomCollectionItemDialog({ collectibleType, collectionItemId: collectionItem.id })
        : undefined,
      showConvertCollectionItemGradeDialog: (params) =>
        showConvertCollectionItemGradeDialog({
          collectibleType: collectible.collectibleType,
          collectionItemId: collectionItem.id,
          newGradeCollectibleId: params?.newGradeCollectibleId,
        }),
      canConvertCollectionItemGrade: extendedCollectionItem
        ? canConvertCollectionItemGrade({ collectionItem: extendedCollectionItem })
        : false,
    }),
    [collectionItem, actions, isSold, collectible, extendedCollectionItem, collectibleType]
  );
}

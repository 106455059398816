import { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import EditSavedSearchDialog from './EditSavedSearchDialog';
import { registerDialog, EditSavedSearchDialogProviderProps } from './index';

type State = Partial<EditSavedSearchDialogProviderProps> & {
  isOpen: boolean;
};

export default withErrorBoundary(function EditSavedSearchDialogProvider({ id }: { id: string }) {
  const [{ isOpen, resolve, categoryId, mode, savedSearchId, title, collectibleType = null }, setState] =
    useState<State>({
      isOpen: false,
    });

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          isOpen: true,
          ...props,
        });
      }),
    [id]
  );

  return (
    <EditSavedSearchDialog
      isOpen={isOpen}
      onClose={() => setState((s) => ({ ...s, isOpen: false }))}
      onAdd={() => resolve?.()}
      savedSearchId={savedSearchId}
      categoryId={categoryId}
      mode={mode}
      title={title}
      collectibleType={collectibleType}
    />
  );
});

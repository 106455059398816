import { useCallback } from 'react';
import { CollectibleType } from '@sportscardinvestor/schemas';
import useUser from '../user/useUser';
import { showSignupIncentiveModal } from '../signupIncentive';
import { useGlobalCollectionStats } from 'features/collection/useCollectionStats';
import { showError } from 'services/toaster';
import { getLimitForMembershipTierPermissionCategory } from 'features/user/utils';

export function useCollectionPermissions() {
  const { data: user } = useUser();
  const isPublic = !user;
  const membershipTier = user?.membershipTier;
  const { cardStats, waxStats } = useGlobalCollectionStats();

  const checkIfCanAddToCollection = useCallback(
    ({ collectibleType }: { collectibleType: CollectibleType }) => {
      if (isPublic) {
        showSignupIncentiveModal();
        return false;
      }
      const stats = collectibleType === 'sealed-wax-card' ? waxStats : cardStats;
      const limit = getLimitForMembershipTierPermissionCategory({ membershipTier }, 'collection');
      if (
        limit !== Infinity &&
        stats?.numberOfOwnedUniqueCollectibles &&
        stats?.numberOfOwnedUniqueCollectibles >= limit
      ) {
        showError({
          description: (
            <div>
              You&apos;ve reached your plan&apos;s limit for items in your collection.{' '}
              <a
                href="https://www.sportscardinvestor.com/account/?action=subscriptions"
                target="_blank"
                rel="noreferrer"
              >
                Remove an item or upgrade your plan to add more items.
              </a>
            </div>
          ),
        });
        return false;
      }
      return true;
    },
    [isPublic, waxStats, cardStats, membershipTier]
  );

  return {
    checkIfCanAddToCollection,
  };
}

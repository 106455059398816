import qs from 'qs';
import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import { Route } from '../../../app/constants';
import { UntrackedCollectibleSalesSearchFilters } from '../../../sales/UntrackedCollectibleSalesSearch/types';
import { getQsFromFilters } from '../../../sales/UntrackedCollectibleSalesSearch/useUntrackedCollectibleSalesSearchFilters';
import { collectibleTypeQueryKey } from '../../../collectibles/constants';
import checkIfHasMinimalFilters from 'features/sales/UntrackedCollectibleSalesSearch/utils/checkIfHasMinimalFilters';

export function generateChartUntrackedCollectiblesPageHref(
  filters: UntrackedCollectibleSalesSearchFilters,
  collectibleType: CollectibleType
): [string, boolean] {
  const hasMinimalFilters = checkIfHasMinimalFilters(filters);
  return [
    `${Route.ChartsUntrackedCollectibles}${qs.stringify(
      {
        ...getQsFromFilters(filters),
        [collectibleTypeQueryKey]: collectibleType,
      },
      {
        addQueryPrefix: true,
        arrayFormat: 'repeat',
      }
    )}`,
    hasMinimalFilters,
  ];
}

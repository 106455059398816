import {
  PriceAlert,
  PriceAlertBase,
  SportsCardPriceAlert,
  SealedWaxPriceAlert,
} from 'sci-ui-components/types/priceAlert';
import { CollectibleType } from 'sci-ui-components/types/collectibleType';
import cardSetFromApi from 'services/sciApi/collectibles/transformers/cardSetFromApi';
import sealedWaxBoxtypeFromApi from 'services/sciApi/collectibles/transformers/sealedWaxBoxtypeFromApi';
import cardVariationFromApi from 'services/sciApi/collectibles/transformers/cardVariationFromApi';
import cardSetVariationFromApi from 'services/sciApi/collectibles/transformers/cardSetVariationFromApi';
import playerFromApi from 'services/sciApi/collectibles/transformers/playerFromApi';
import cardGradeFromApi from 'services/sciApi/collectibles/transformers/cardGradeFromApi';
import { ApiPriceAlert, ApiSportsCardPriceAlert, ApiSealedWaxPriceAlert } from 'services/sciApi/types';
import { thresholdFromApiComparison } from 'services/sciApi/alerts/transformers/thresholdFromApiComparison';
import { alertStatusFromApi } from 'services/sciApi/alerts/transformers/alertStatusFromApi';
import sealedWaxCardSetFromApi from 'services/sciApi/alerts/transformers/sealedWaxCardSetFromApi';
import { UnknownPriceAlert } from 'sci-ui-components/types/priceAlert';
import { queryFromApiAlert } from 'services/sciApi/alerts/transformers/queryFromApiAlert';
import { historicalStatsFromAlertsApi } from 'services/sciApi/alerts/transformers/historicalStatsFromAlertsApi';

interface Params {
  collectibleType: CollectibleType;
}

export default function priceAlertFromApi(apiItem: ApiPriceAlert, params: Params): PriceAlert {
  const { collectibleType } = params;

  const base: Omit<PriceAlertBase, 'collectibleId'> = {
    id: apiItem.id,
    query: queryFromApiAlert({ collectibleType, apiItem }),
    threshold: thresholdFromApiComparison(apiItem.comparison),
    name: apiItem.name,
    createdAt: apiItem.created_at,
    updatedAt: apiItem.updated_at,
    triggeredAt: apiItem.triggered_at,
    triggeredValue: apiItem.triggered_value,
    userId: apiItem.user_id,
    alertPrice: apiItem.value,
    status: alertStatusFromApi(apiItem.triggered_at),
    historicalStats: historicalStatsFromAlertsApi({ collectibleType, apiItem }),
    collectibleType,
  };

  if (collectibleType === 'sports-card') {
    const apiItemSC = apiItem as ApiSportsCardPriceAlert;
    const sportsCardItem: SportsCardPriceAlert = {
      ...base,
      collectibleId: apiItemSC.card_id,
      cardSet: cardSetFromApi(apiItemSC.card.card_set),
      grade: cardGradeFromApi(apiItemSC.card.grade),
      player: playerFromApi(apiItemSC.card.player),
      setVariation: cardSetVariationFromApi(apiItemSC.card.set_variation),
      variation: cardVariationFromApi(apiItemSC.card.variation),
    };
    return sportsCardItem;
  }

  if (collectibleType === 'sealed-wax-card') {
    const apiItemSW = apiItem as ApiSealedWaxPriceAlert;
    const sealedWaxItem: SealedWaxPriceAlert = {
      ...base,
      collectibleId: apiItemSW.sealed_wax_id,
      boxType: sealedWaxBoxtypeFromApi(apiItemSW.sealed_wax.box_type),
      cardSet: sealedWaxCardSetFromApi(apiItemSW.sealed_wax.card_set),
    };
    return sealedWaxItem;
  }
  return base as UnknownPriceAlert;
}

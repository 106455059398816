import React from 'react';
import clsx from 'clsx';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import CollectibleImage from '../../../collectibles/CollectibleImage/CollectibleImage';
import CollectibleText from '../../../collectibles/CollectibleText/CollectibleText';
import IconButton from '../../../buttons/IconButton/IconButton';
import classes from './SearchItem.module.scss';

type SearchItemType = {
  titlePrefix?: React.ReactNode;
  titleSuffix?: React.ReactNode;
  title: string;
  imageUrl?: string | null;
  descriptionText: string;
  extraText: string;
  gradeAndCount: React.ReactNode;
  priceText: string;
  saleDateText: string | null;
  selected?: boolean;
  onClick?: () => void;
  onChartButtonClick?: () => void;
};
const SearchItem: React.FC<SearchItemType> = (item) => {
  return (
    <div
      className={clsx(classes.searchItemWrapper, {
        [classes.clickable]: !!item.onClick,
        [classes.selected]: item.selected,
      })}
      onClick={(event) => {
        event.stopPropagation();
        item.onClick?.();
      }}
      role={item.onClick ? 'button' : undefined}
    >
      <div className={classes.left}>
        <CollectibleImage
          url={item.imageUrl}
          collectibleType="sports-card"
          alt={item.title}
          className={classes.image}
          size="small"
        />
        <div className={classes.description}>
          <div className={classes.row}>
            {item.titlePrefix}
            <CollectibleText type="title" className={classes.titleText}>
              {item.title}
            </CollectibleText>
            {item.titleSuffix}
          </div>
          <CollectibleText className={classes.descriptionText}>{item.descriptionText}</CollectibleText>
          <CollectibleText className={classes.descriptionText}>{item.extraText}</CollectibleText>
          <div className={classes.row}>{item.gradeAndCount}</div>
        </div>
      </div>
      <div className={classes.right}>
        <div className={classes.stats}>
          <p className={classes.price}>{item.priceText}</p>
          <p className={classes.date}>{item.saleDateText}</p>
        </div>
        {!!item.onChartButtonClick && (
          <IconButton
            title={item.selected ? 'Remove' : 'Add'}
            size="large"
            color="grey"
            faIcon={faCircleCheck}
            highlighted={item.selected}
            className={classes.chartButton}
            onClick={(e) => {
              e.stopPropagation();
              item.onChartButtonClick?.();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SearchItem;

import { SetStateAction, Dispatch, useEffect, useState } from 'react';

type SectionName = 'PurchaseDetails' | 'ForSale' | 'Sold';

const getOpenFromStorage = (key: string): boolean => localStorage.getItem(key) !== 'false';

export function useCollapsibleStateFromStorage(sectionName: SectionName): [boolean, Dispatch<SetStateAction<boolean>>] {
  const key = `flyout${sectionName}Open`;

  const [open, setOpen] = useState<boolean>(getOpenFromStorage(key));

  useEffect(() => {
    localStorage.setItem(key, String(open));
  }, [open, key]);

  return [open, setOpen];
}

import { useEffect, useState } from 'react';
import SignupIncentiveModal from './SignupIncentiveModal';
import { registerDialog } from './index';

export default function SignupIncentiveProvider({ id }: { id: string }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(
    () =>
      registerDialog(id, () => {
        setIsOpen(true);
      }),
    [id]
  );

  return <SignupIncentiveModal isOpen={isOpen} onClose={() => setIsOpen(false)} />;
}

import { normalizeListingType } from '@sportscardinvestor/marketplace-helpers';
import { ApiUntrackedCollectibleSale } from '../../types';
import { UntrackedCollectibleSale } from '../../../../sci-ui-components/types/sales';
import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import { marketplaceHelpers } from 'services/marketplaceHelpers';

export default function untrackedCollectibleSaleFromApi(
  apiItem: ApiUntrackedCollectibleSale,
  {
    collectibleType,
  }: {
    collectibleType: CollectibleType;
  }
): UntrackedCollectibleSale {
  const finalPrice = apiItem.best_offer_enabled && apiItem.offer_price ? apiItem.offer_price : apiItem.selling_price;

  return {
    collectibleType,
    bestOfferEnabled: apiItem.best_offer_enabled,
    endDateTime: apiItem.end_time,
    imageUrl: apiItem.image_url,
    included: apiItem.included,
    listingId: apiItem.auction_id,
    listingType: apiItem.listing_type,
    offerPrice: apiItem.offer_price,
    offerPriceChecked: apiItem.offer_price_checked,
    offerPriceMessage: apiItem.offer_price_message,
    sellingPrice: apiItem.selling_price,
    title: apiItem.title,
    auctionUrl: marketplaceHelpers.makeDirectItemUrl({
      url: apiItem.auction_url,
      marketplace: 'ebay_jobs',
      listingType: normalizeListingType(apiItem.listing_type),
    }),
    finalPrice,
  };
}

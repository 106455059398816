import { makeCollectibleDescriptionsFromParts } from '@sportscardinvestor/collectible-helpers';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import { SealedWaxSearch, SportsCardSearch } from '../../../services/sciApi/search/types';

export function makeSearchDescription(
  item: SealedWaxSearch | SportsCardSearch,
  collectibleType: CollectibleType
): {
  title: string;
  descriptionLine1: string;
  descriptionLine2: string;
  fullDescription: string;
} {
  switch (collectibleType) {
    case 'sports-card': {
      const sportsCardItem = item as SportsCardSearch;
      return makeCollectibleDescriptionsFromParts(
        {
          collectibleType: 'sports-card',
          playerName: sportsCardItem.playerName,
          cardNumber: sportsCardItem.cardNumber,
          setName: sportsCardItem.setName,
          setYear: sportsCardItem.setYear,
          printRun: sportsCardItem.printRun,
          specificQualifier: sportsCardItem.specificQualifier,
          variationName: sportsCardItem.variationName,
        },
        {
          includePrintRun: true,
        }
      );
    }
    case 'sealed-wax-card': {
      const waxItem = item as SealedWaxSearch;
      return makeCollectibleDescriptionsFromParts({
        collectibleType: 'sealed-wax-card',
        setName: waxItem.setName,
        boxType: waxItem.boxTypeName,
        setYear: waxItem.setYear,
        sportName: waxItem.sportName,
      });
    }
  }
}

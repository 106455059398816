import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import useFavoriteCollectibleCategoriesByType from '../useFavoriteCollectibleCategoriesByType';
import useFavoriteCollectibleMutations from '../useFavoriteCollectibleMutations';
import FavoriteCategoryFilter from '../FavoriteCategoryFilter';
import { AddOrEditFavoriteDialogInfoProps } from './types';

const formId = 'add-edit-favorite';
const categoryFieldId = `${formId}-category`;

type FormValues = {
  categoryId: number | null;
};
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  categoryId: Yup.number().nullable().required('Required'),
});

export default function AddOrEditFavoriteDialog({
  isOpen,
  onClose,
  onSuccess,
  collectibleType,
  collectibleId,
  favoriteId,
}: AddOrEditFavoriteDialogInfoProps & {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}) {
  const { data: categories } = useFavoriteCollectibleCategoriesByType({
    collectibleType,
  });
  const { addFavoriteCollectible, moveToCategory } = useFavoriteCollectibleMutations();

  const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    if (favoriteId) {
      await moveToCategory.mutateAsync({
        categoryId: values.categoryId!,
        favoriteId: favoriteId,
        collectibleType: collectibleType!,
      });
    } else {
      await addFavoriteCollectible.mutateAsync({
        categoryId: values.categoryId!,
        entityId: collectibleId!,
        entityType: collectibleType!,
      });
    }
    onSuccess?.();
    onClose();
    setSubmitting(false);
    resetForm();
  };

  const visible = isOpen && !!collectibleId && !!collectibleType;

  return (
    <Formik
      initialValues={{
        categoryId: categories?.length ? categories[0].id : null,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ values, handleChange, errors, isSubmitting }) => (
        <>
          <ModalDialog
            visible={visible}
            title={favoriteId ? 'Move to Favorite Category' : 'Add to Favorite Category'}
            okText={favoriteId ? 'Move' : 'Add'}
            onCancel={onClose}
            cancelText="Cancel"
            okButtonProps={{
              htmlType: 'submit',
              form: formId,
              disabled: isSubmitting,
            }}
            cancelButtonProps={{
              disabled: isSubmitting,
            }}
          >
            <Form id={formId}>
              <FavoriteCategoryFilter
                collectibleType={collectibleType!}
                onChange={(categoryId) => handleChange({ target: { name: 'categoryId', value: categoryId } })}
                selectedCategoryId={values.categoryId}
                id={categoryFieldId}
                noValueLabel="Please select"
                error={errors.categoryId}
              />
            </Form>
          </ModalDialog>
        </>
      )}
    </Formik>
  );
}

import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import useFavoritePlayersCategories from '../useFavoritePlayersCategories';
import useFavoritePlayersMutations from '../useFavoritePlayersMutations';
import FavoritePlayersCategoryFilter from '../FavoritePlayersCategoryFilter';
import { AddOrEditFavoritePlayerDialogInfoProps } from './types';

const formId = 'add-favorite-player';
const categoryFieldId = `${formId}-category`;

type FormValues = {
  categoryId: number | null;
};
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  categoryId: Yup.number().nullable().required('Required'),
});

export default function AddOrEditFavoritePlayerDialog({
  isOpen,
  onClose,
  onSuccess,
  playerId,
  favoriteId,
  categoryId,
}: AddOrEditFavoritePlayerDialogInfoProps & {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}) {
  const { data: categories } = useFavoritePlayersCategories();
  const { addFavoritePlayer, moveToCategory } = useFavoritePlayersMutations();

  const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    if (favoriteId) {
      await moveToCategory.mutateAsync({
        categoryId: values.categoryId!,
        favoriteId,
      });
    } else {
      await addFavoritePlayer.mutateAsync({
        categoryId: values.categoryId!,
        entityId: playerId!,
        entityType: 'player',
      });
    }
    onSuccess?.();
    onClose();
    setSubmitting(false);
    resetForm();
  };

  const visible = isOpen && !!playerId;

  return (
    <Formik
      initialValues={{
        categoryId: categoryId ?? (categories?.length ? categories[0].id : null),
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ values, handleChange, errors, isSubmitting }) => (
        <>
          <ModalDialog
            visible={visible}
            title={favoriteId ? 'Move Favorite Player to Category' : 'Add to Favorite Players'}
            okText={favoriteId ? 'Move' : 'Add'}
            onCancel={onClose}
            cancelText="Cancel"
            okButtonProps={{
              htmlType: 'submit',
              form: formId,
              disabled: isSubmitting,
            }}
            cancelButtonProps={{
              disabled: isSubmitting,
            }}
          >
            <Form id={formId}>
              <FavoritePlayersCategoryFilter
                onChange={(categoryId) => handleChange({ target: { name: 'categoryId', value: categoryId } })}
                selectedCategoryId={values.categoryId}
                id={categoryFieldId}
                noValueLabel="Please select"
                error={errors.categoryId}
              />
            </Form>
          </ModalDialog>
        </>
      )}
    </Formik>
  );
}

import { CollectibleType } from '@sportscardinvestor/schemas';
import { create } from 'zustand';
import { RawCompletedSaleSearchItem } from '@/features/sales-history/useRawCompletedSalesSearch';
import { trackEvent } from '@/features/analytics/trackEvent';

interface EditCustomCollectionItemDialogState {
  collectionItemId: number | null;
  collectibleType: CollectibleType;
  /**
   * Allows displaying sales history item at the top to make it easier for user to type manual info
   */
  salesHistoryItem?: RawCompletedSaleSearchItem | null;
  close: () => void;
}

const defaultCollectibleType: CollectibleType = 'sports-card';

export const useEditCustomCollectionItemDialogStore = create<EditCustomCollectionItemDialogState>()((set) => ({
  collectionItemId: null,
  collectibleType: defaultCollectibleType,
  close: () => set({ collectionItemId: null, collectibleType: defaultCollectibleType, salesHistoryItem: null }),
}));

export interface EditCustomCollectionItemDialogProps {
  collectionItemId: number;
  collectibleType: CollectibleType;
  salesHistoryItem?: RawCompletedSaleSearchItem | null;
}
export function showEditCustomCollectionItemDialog(params: EditCustomCollectionItemDialogProps) {
  useEditCustomCollectionItemDialogStore.setState(params);
  trackEvent({
    eventName: 'COLLECTION_EDIT_CARD_STARTED',
    collectibleType: params.collectibleType,
    collectionItemId: params.collectionItemId,
  });
}

import { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import AddOrEditFavoritePlayerDialog from './AddOrEditFavoritePlayerDialog';
import { registerDialog, AddOrEditFavoritePlayerDialogProviderProps } from './index';

type State = Partial<AddOrEditFavoritePlayerDialogProviderProps> & {
  isOpen: boolean;
};

export default withErrorBoundary(function AddOrEditFavoritePlayerDialogProvider({ id }: { id: string }) {
  const [{ isOpen, resolve, playerId, favoriteId, categoryId }, setState] = useState<State>({
    isOpen: false,
  });

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          isOpen: true,
          ...props,
        });
      }),
    [id]
  );

  return (
    <AddOrEditFavoritePlayerDialog
      isOpen={isOpen}
      playerId={playerId!}
      favoriteId={favoriteId}
      categoryId={categoryId}
      onClose={() => setState((s) => ({ ...s, isOpen: false }))}
      onSuccess={() => resolve?.()}
    />
  );
});

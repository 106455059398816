import { useCallback } from 'react';
import { z } from 'zod';
import { Collectible, CollectibleType } from '@sportscardinvestor/schemas';
import { Button } from '@sportscardinvestor/sci-ui/components/buttons/button';
import { SearchTextField } from '@sportscardinvestor/sci-ui/components/forms/search-text-field';
import { getCollectibleId } from '@sportscardinvestor/collectible-helpers';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditCollectionItemPurchaseDetailsInitialValues } from '../../EditCollectionItemPurchaseDetailsDialog/EditCollectionItemPurchaseDetailsForm';
import { useMakeAddToCollectionInitialValues } from '../useMakeAddToCollectionInitialValues';
import { EditCustomCollectibleValueFormInitialValues } from '../../EditCustomCollectibleValueDialog/EditCustomCollectibleValueForm';
import { EditCustomCollectionItemFormInitialValues } from '../../EditCustomCollectionItemDialog/forms/EditCustomCollectionItemForm';
import { useCollectibleCacheHelper } from '@/features/collectibles/useCollectible';
import { RawCompletedSaleSearchItem } from '@/features/sales-history/useRawCompletedSalesSearch';
import { EditForm, FormSeparator } from '@/sci-ui-components/forms/EditForm';
import { useOnValueChange } from '@/hooks/useOnValueChange';
import { collectibleNames } from '@/sci-ui-components/collectibles/constants';
import FieldLabelBox from '@/sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { useUnifiedSearch } from '@/features/quick-search/useUnifiedSearch';
import { SearchResultsTypeTabsToggle } from '@/features/quick-search/SearchResultsTypeTabsToggle';
import VerticalTilesList from '@/sci-ui-components/VerticalTilesList/VerticalTilesList';
import { SalesHistoryItem } from '@/features/sales-history/SalesHistoryItem';
import { CollectibleItem } from '@/features/collectibles/CollectibleItem';
import { ClickableWrapper } from '@/sci-ui-components/ClickableWrapper';

const formSchema = z.object({
  searchText: z.string().nullable().optional(),
});

export type SearchFormInitialValues = z.input<typeof formSchema>;
type FormInput = z.input<typeof formSchema>;
type FormValues = z.output<typeof formSchema>;

export interface SearchFormPayload {
  initialSearchText: string;
  featuredCollectibleId: number | null;
  initialCustomCollectibleInfo?: EditCustomCollectionItemFormInitialValues | null;
  initialCustomCollectibleValueInfo?: EditCustomCollectibleValueFormInitialValues | null;
  initialPurchaseDetails?: EditCollectionItemPurchaseDetailsInitialValues | null;
  salesHistoryItem?: RawCompletedSaleSearchItem | null;
}
export type OnSearchFormSubmitFn = (params: SearchFormPayload) => void;

export function SearchForm({
  onSubmit,
  formId = 'SearchForm',
  initialValues,
  collectibleType,
}: {
  onSubmit: OnSearchFormSubmitFn;
  formId?: string;
  initialValues: SearchFormInitialValues | null;
  collectibleType: CollectibleType;
}) {
  const form = useForm<FormInput, unknown, FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues ?? undefined,
    mode: 'onSubmit',
  });
  useOnValueChange(initialValues, () => form.reset(initialValues ?? undefined));

  const searchText = form.watch('searchText') ?? '';
  const unifiedSearch = useUnifiedSearch({
    collectibleType,
    searchText,
    debounceTimeInMs: 500,
    enableSpellCheck: false,
    enableForEmptySearchText: false,
  });
  const {
    debouncedSearchText,
    searchResultsType,
    isDebouncing,
    toggleSearchResultsType,
    hasSearchText,
    salesHistory,
    featured,
  } = unifiedSearch;

  const { setCollectible } = useCollectibleCacheHelper();
  const { makeFromSalesHistoryItem, isLoading: isLoadingCollectionItemInitialInfo } =
    useMakeAddToCollectionInitialValues();
  const handleAddCustom = useCallback(() => {
    onSubmit({
      featuredCollectibleId: null,
      initialCustomCollectibleInfo: null,
      initialCustomCollectibleValueInfo: null,
      initialPurchaseDetails: null,
      initialSearchText: debouncedSearchText,
      salesHistoryItem: null,
    });
  }, [onSubmit, debouncedSearchText]);
  const handleSelectFeaturedCollectible = useCallback(
    (collectible: Collectible) => {
      setCollectible(collectible); // NOTE: to skip fetching collectible in useCollectible
      onSubmit({
        featuredCollectibleId: getCollectibleId(collectible),
        initialCustomCollectibleInfo: null,
        initialCustomCollectibleValueInfo: null,
        initialPurchaseDetails: null,
        initialSearchText: debouncedSearchText,
        salesHistoryItem: null,
      });
    },
    [onSubmit, setCollectible, debouncedSearchText]
  );
  const handleSelectSalesHistoryItem = useCallback(
    async (item: RawCompletedSaleSearchItem) => {
      const {
        featuredCollectibleId,
        initialCustomCollectibleInfo,
        initialCustomCollectibleValueInfo,
        initialPurchaseDetails,
        salesHistoryItem,
      } = await makeFromSalesHistoryItem({
        collectibleType,
        item,
        searchText: debouncedSearchText,
      });
      onSubmit({
        featuredCollectibleId,
        initialCustomCollectibleInfo,
        initialCustomCollectibleValueInfo,
        initialPurchaseDetails,
        initialSearchText: debouncedSearchText,
        salesHistoryItem,
      });
    },
    [debouncedSearchText, onSubmit, collectibleType, makeFromSalesHistoryItem]
  );

  const handleSubmit = form.handleSubmit(() => {
    // NOTE: do nothing, form is never submitted
  });

  return (
    <>
      <FormSeparator noTopMargin />
      <EditForm
        onSubmit={handleSubmit}
        id={formId}
        className="flex flex-col gap-2 w-[612px] max-w-full max-h-[max(640px,70vh)]"
        isLoading={isLoadingCollectionItemInitialInfo}
      >
        <div className="flex items-center justify-between gap-8 flex-wrap">
          <h2 className="text-lg font-semibold">Search All {collectibleNames[collectibleType].shortPlural}</h2>
          <Button size="small" variant="default" shape="round" onClick={handleAddCustom}>
            Add Manual {collectibleNames[collectibleType].shortSingular}
          </Button>
        </div>
        <Controller
          name="searchText"
          control={form.control}
          render={({ field, fieldState }) => (
            <FieldLabelBox
              className="w-full"
              label={null}
              fieldId="searchText"
              error={fieldState.error?.message}
              variant="neater"
            >
              <SearchTextField
                {...field}
                value={field.value ?? ''}
                id="searchText"
                placeholder="e.g. Luka Doncic"
                shape="rounded"
                isLoading={isDebouncing}
              />
            </FieldLabelBox>
          )}
        />
        {hasSearchText && (
          <section className="bg-lightBg dark:bg-darkBg border border-lightBg-lightGrey dark:border-darkBg-lightGrey flex flex-col items-stretch overflow-hidden rounded-sm">
            <SearchResultsTypeTabsToggle
              className="grow-0 shrink-0 m-4"
              collectibleType={collectibleType}
              onSearchResultsTypeChange={toggleSearchResultsType}
              searchResultsType={searchResultsType}
            />
            {searchResultsType === 'featured' && (
              <VerticalTilesList
                className="grow shrink overflow-hidden min-h-80"
                canLoadMore={featured.hasNextPage}
                isLoading={featured.isLoading || featured.isFetchingNextPage}
                onApproachingEnd={featured.fetchNextPageIfAvailable}
                endApproachThreshold={5}
              >
                {featured.exactMatchItems.map(({ item: collectible }) => (
                  <ClickableWrapper key={collectible.id} onClick={() => handleSelectFeaturedCollectible(collectible)}>
                    <CollectibleItem
                      className="px-4 py-2"
                      collectible={collectible}
                      collectibleType={collectibleType}
                      size="lg"
                    />
                  </ClickableWrapper>
                ))}
              </VerticalTilesList>
            )}
            {searchResultsType === 'salesHistory' && (
              <VerticalTilesList
                className="grow shrink overflow-hidden min-h-80"
                canLoadMore={salesHistory.hasNextPage}
                isLoading={salesHistory.isLoading || salesHistory.isFetchingNextPage}
                onApproachingEnd={salesHistory.fetchNextPageIfAvailable}
                endApproachThreshold={5}
              >
                {[
                  ...salesHistory.exactMatchItems.map((item) => (
                    <ClickableWrapper key={item.id} onClick={() => handleSelectSalesHistoryItem(item)}>
                      <SalesHistoryItem className="px-4 py-2" collectibleType={collectibleType} item={item} size="lg" />
                    </ClickableWrapper>
                  )),
                  ...salesHistory.fuzzyMatchItems.map((item) => (
                    <ClickableWrapper key={`fuzzy-${item.id}`} onClick={() => handleSelectSalesHistoryItem(item)}>
                      <SalesHistoryItem className="px-4 py-2" collectibleType={collectibleType} item={item} size="lg" />
                    </ClickableWrapper>
                  )),
                ]}
              </VerticalTilesList>
            )}
          </section>
        )}
      </EditForm>
    </>
  );
}

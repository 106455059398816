import { useEffect, useState } from 'react';
import MarkAsSoldDialog from './MarkAsSoldDialog';
import { registerDialog, MarkAsSoldDialogProviderProps } from './index';
import { trackEvent } from '@/features/analytics/trackEvent';

type State = Partial<MarkAsSoldDialogProviderProps> & {
  isOpen: boolean;
};

export default function MarkAsSoldDialogProvider({ id }: { id: string }) {
  const [{ isOpen, resolve, initialValues, collectibleType }, setState] = useState<State>({
    isOpen: false,
  });
  const isEdit = !!initialValues?.dateSold;

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          isOpen: true,
          ...props,
        });
        trackEvent({
          eventName: isEdit ? 'COLLECTION_EDIT_SALE_DETAILS_STARTED' : 'COLLECTION_MARK_SOLD_STARTED',
          collectibleType: props.collectibleType,
          collectionItemId: props.initialValues?.collectibleId,
        });
      }),
    [id, isEdit]
  );

  if (!initialValues) {
    return null;
  }

  return (
    <MarkAsSoldDialog
      isOpen={isOpen}
      collectibleType={collectibleType!}
      initialValues={initialValues!}
      onClose={() => setState((s) => ({ ...s, isOpen: false }))}
      onSubmit={() => resolve?.()}
      isEdit={isEdit}
    />
  );
}

import { CollectionItemNoteDialogInfoProps } from './types';
import createDialogSingleton, { DialogProviderProps } from 'sci-ui-components/utils/createDialogSingleton';

const { invokeDialog: collectionItemNoteDialog, registerDialog } = createDialogSingleton<
  CollectionItemNoteDialogInfoProps,
  void
>();

export { collectionItemNoteDialog, registerDialog };
export type CollectionItemNoteDialogProviderProps = DialogProviderProps<CollectionItemNoteDialogInfoProps, void>;

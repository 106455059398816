import qs from 'qs';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import { SortDirection } from '../../../sci-ui-components/types/sort';
import { ForSaleListingItem, CompletedSalesListingItem } from '../../../sci-ui-components/types/sales';
import { AdvancedChartDataFilters, ChartDataSeller } from '../../../sci-ui-components/types/chartData';
import { ApiForSaleResponse, ApiCompletedSalesResponse } from '../types';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import fetchFromSciApi from '../fetchFromSciApi';
import forSaleListingItemFromApi from './transformers/forSaleListingItemFromApi';
import completedSalesListingItemFromApi from './transformers/completedSalesListingItemFromApi';

export interface GetForSaleListingsParams {
  offset: number;
  limit: number;
  collectibleIds: number[];
  collectibleType: CollectibleType;
  sortBy?: GetForSaleListingsSortField | null;
  sortDirection?: SortDirection;
  isPublic?: boolean | undefined;
  listingTypes?: string[];
  advancedFilters?: Pick<AdvancedChartDataFilters, 'excludePlatforms'>;
}

export type GetForSaleListingsSortField =
  | 'buy_it_now_price'
  | 'card_id'
  | 'id'
  | 'player_name'
  | 'set_year'
  | 'set_name'
  | 'buy_it_now_price'
  | 'title'
  | 'end_time'
  | 'selling_price'
  | 'offer_price'
  | 'number_of_bids'
  | 'price_change_amount'
  | 'price_change_percentage'
  | 'best_offer_enabled';

export interface GetForSaleListingsResponse {
  items: ForSaleListingItem[];
  collectibleIds: number[];
  collectibleType: CollectibleType;
  totalCount: number;
}

export async function getForSaleListings(
  {
    limit,
    offset,
    collectibleIds,
    collectibleType,
    sortBy = null,
    sortDirection = 'asc',
    isPublic = false,
    listingTypes = ['Store'],
    advancedFilters,
  }: GetForSaleListingsParams,
  signal: AbortSignal | undefined
): Promise<GetForSaleListingsResponse> {
  const configs: Record<
    CollectibleType,
    {
      path: string;
      idKey: string;
    }
  > = {
    'sports-card': { path: 'cardactiveresults', idKey: 'card_id' },
    'sealed-wax-card': { path: 'sealed-wax-active-results', idKey: 'sealed_wax_id' },
  };
  const config = configs[collectibleType];
  const fetchFunction = isPublic ? fetchFromSciApi : authenticatedFetchFromSciApi;
  const response = await fetchFunction<ApiForSaleResponse>(
    `/${config.path}${isPublic ? '/public' : ''}${qs.stringify(
      {
        [config.idKey]: collectibleIds,
        listing_type: listingTypes,
        limit,
        skip: offset,
        sortBy,
        orderBy: sortDirection,
        excludedPlatforms: advancedFilters?.excludePlatforms,
      },
      {
        skipNulls: true,
        addQueryPrefix: true,
      }
    )}`,
    {
      method: 'GET',
      signal,
    }
  );
  return {
    collectibleIds,
    collectibleType,
    items: response.data.map((item) => forSaleListingItemFromApi(item, collectibleType)),
    totalCount: response.totalCount,
  };
}

export interface GetCompletedSalesListingsParams {
  offset: number;
  limit: number;
  collectibleId: number;
  collectibleType: CollectibleType;
  sortBy?: GetCompletedSalesListingsSortField | null;
  sortDirection?: SortDirection;
  isoStartDate?: string | null;
  isoEndDate?: string | null;
  advancedFilters?: AdvancedChartDataFilters;
}

export type GetCompletedSalesListingsSortField =
  | 'start_time'
  | 'end_time'
  | 'buyer_feedback_rating'
  | 'feedback_rating'
  | 'seller_name'
  | 'listing_type'
  | 'selling_price'
  | 'offer_price'
  | 'sold_price';

export interface GetCompletedSalesListingsResponse {
  items: CompletedSalesListingItem[];
  collectibleId: number;
  collectibleType: CollectibleType;
  totalCount: number;
}

const configs: Record<
  CollectibleType,
  {
    path: string;
    idKey: string;
  }
> = {
  'sports-card': { path: 'cardresults', idKey: 'card_id' },
  'sealed-wax-card': { path: 'sealed-wax-results', idKey: 'sealed_wax_id' },
};

export async function getCompletedSalesListings(
  {
    limit,
    offset,
    collectibleId,
    collectibleType,
    sortBy = null,
    sortDirection = 'asc',
    advancedFilters,
    isoEndDate,
    isoStartDate,
  }: GetCompletedSalesListingsParams,
  signal: AbortSignal | undefined
): Promise<GetCompletedSalesListingsResponse> {
  const config = configs[collectibleType];
  const excludeSellers: ChartDataSeller[] = advancedFilters?.excludeSellers ? [...advancedFilters?.excludeSellers] : [];
  if (excludeSellers.includes('pwcc_auctions')) {
    excludeSellers.push('PWCC');
  }
  const response = await authenticatedFetchFromSciApi<ApiCompletedSalesResponse>(
    `/${config.path}${qs.stringify(
      {
        [config.idKey]: collectibleId,
        limit,
        skip: offset,
        sortBy,
        orderBy: sortDirection,
        startDate: isoStartDate,
        endDate: isoEndDate,
        excludeAuctions: advancedFilters?.saleType?.excludeAuctions,
        excludeBuyItNow: advancedFilters?.saleType?.excludeBuyItNow,
        excludeSaleWithMakeOffer: advancedFilters?.saleType?.excludeSaleWithMakeOffer,
        excludePlatformsFromChartResults: advancedFilters?.excludePlatforms,
        excludeSellersFromChartResults: excludeSellers,
        excludeNoSellerFeedback: advancedFilters?.seller?.excludeNoSellerFeedback,
      },
      {
        skipNulls: true,
        addQueryPrefix: true,
        arrayFormat: 'repeat',
      }
    )}`,
    {
      method: 'GET',
      signal,
    }
  );
  return {
    collectibleId,
    collectibleType,
    items: response.data.map((item) => completedSalesListingItemFromApi(item, collectibleType)),
    totalCount: Number(response.totalCount),
  };
}

export type RemoveListingsByAdminParams = {
  resultIds: number[];
  collectibleType: CollectibleType;
};

export async function removeListingsByAdmin({ resultIds, collectibleType }: RemoveListingsByAdminParams) {
  const config = configs[collectibleType];
  await authenticatedFetchFromSciApi<[number]>(`/${config.path}/updateMany`, {
    method: 'PUT',
    body: JSON.stringify({
      auction_ids: resultIds,
      flag: 'exclude',
      setCardImages: false,
    }),
  });
}

import { CollectibleType } from '@sportscardinvestor/schemas';
import { create } from 'zustand';
import { trackEvent } from '@/features/analytics/trackEvent';

interface EditFeaturedCollectibleCustomValueDialogState {
  collectibleId: number | null;
  collectibleType: CollectibleType;
  currentValue: number | null;
  currentValueDate: string | null;
  close: () => void;
}

const defaultCollectibleType: CollectibleType = 'sports-card';

export const useEditFeaturedCollectibleCustomValueDialogStore = create<EditFeaturedCollectibleCustomValueDialogState>()(
  (set) => ({
    collectibleId: null,
    collectibleType: defaultCollectibleType,
    currentValue: null,
    currentValueDate: null,
    close: () =>
      set({ collectibleId: null, collectibleType: defaultCollectibleType, currentValue: null, currentValueDate: null }),
  })
);

export type EditFeaturedCollectibleCustomValueDialogProps = Pick<
  EditFeaturedCollectibleCustomValueDialogState,
  'collectibleId' | 'collectibleType' | 'currentValue' | 'currentValueDate'
>;
export function showEditFeaturedCollectibleCustomValueDialog(props: EditFeaturedCollectibleCustomValueDialogProps) {
  useEditFeaturedCollectibleCustomValueDialogStore.setState(props);
  trackEvent({
    eventName: 'COLLECTION_EDIT_FEATURED_PRICE_STARTED',
    collectibleType: props.collectibleType,
  });
}

import React, { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { Drawer } from 'antd';
import { useRouter } from 'next/router';
import { scrollablePageContentElementId } from '../../../../../sci-ui-components/styles/constants';
import DesktopSideMenu from '../../../../../sci-ui-components/navigationMenus/DesktopSideMenu/DesktopSideMenu';
import InfoBar from '../../../InfoBar';
import { Route } from '../../../constants';
import useNavigationMenuItems from '../../../useNavigationMenuItems';
import navigationConfig from '../../../../app/navigationConfig';
import useCollectibleType from '../../../../collectibles/useCollectibleType';
import useAuth from '../../../../auth/useAuth';
import useLayoutControlState from '../useLayoutControlState';
import Header from './Header/Header';
import classes from './DefaultLayout.module.scss';
import useLocalStorageValue from 'hooks/useLocalStorageValue';

const drawerSeenKey = 'sci_mobile_drawer_seen';

export default function AppLayout({
  children,
  stickyHeader = false,
  isPublic = false,
}: {
  children: ReactNode;
  stickyHeader?: boolean;
  isPublic?: boolean;
}) {
  const { collectibleType } = useCollectibleType();
  const { isHeaderVisible } = useLayoutControlState();
  const router = useRouter();
  const navigationMenuItems = useNavigationMenuItems(navigationConfig, {
    collectibleType,
  });
  const { isLoggedIn, impersonatedByUserId } = useAuth();
  const [drawerSeen, setDrawerSeen] = useLocalStorageValue<boolean | null>(drawerSeenKey, true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const onClose = () => {
    setDrawerSeen(true);
    setDrawerOpen(false);
  };
  useEffect(() => {
    setDrawerOpen(isMobile && !drawerSeen);
  }, [drawerSeen, setDrawerSeen]);

  return (
    <div className={clsx(classes.root, { [classes.stickyHeader]: stickyHeader })}>
      {!isPublic || isLoggedIn ? (
        <DesktopSideMenu
          className={classes.desktopSideNavMenu}
          items={navigationMenuItems}
          logoHref={Route.Dashboard}
        />
      ) : null}
      <div className={classes.page} id={scrollablePageContentElementId}>
        {(!isPublic || isLoggedIn) && isHeaderVisible ? (
          <Header className={classes.header} menuItems={navigationMenuItems} logoHref={Route.Dashboard} />
        ) : null}
        {router.asPath.includes(Route.Dashboard) ? <InfoBar className={classes.infoBar} /> : null}
        <div className={classes.content}>{children}</div>
      </div>
      {!!impersonatedByUserId && <div className={classes.warning} />}
      {isMobile && (
        <Drawer
          title="DOWNLOAD THE MOBILE APP"
          placement="bottom"
          closable
          height="auto"
          onClose={onClose}
          maskClosable={false}
          visible={drawerOpen}
          autoFocus
        >
          <p>
            To have the best Market Movers experience on your mobile device, please DOWNLOAD THE MOBILE APP. The mobile
            app is FREE to use with any Market Mover membership!
          </p>
          <div className={classes.appBadges}>
            <a
              href="https://apps.apple.com/us/app/market-movers/id1561027917#?platform=iphone"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/app-store-badge.png" alt="Apple App Store" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.sportscardinvestor.marketmovers&hl=en_US&gl=US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/google-play-badge.png" alt="Google Play Store" />
            </a>
          </div>
        </Drawer>
      )}
    </div>
  );
}

import { Button, ButtonProps } from '@sportscardinvestor/sci-ui/components/buttons/button';
import clsx from 'clsx';

/**
 * A styled sci-ui button to edit, etc.
 */
export default function ActionButton({ className, ...rest }: Omit<ButtonProps, 'variant' | 'size'>) {
  return (
    <Button
      {...rest}
      size="custom"
      variant="custom"
      className={clsx(
        className,
        'uppercase py-1 px-1 font-bold text-lg text-primary border-transparent bg-transparent hover:bg-transparent hover:text-primary/80'
      )}
    />
  );
}

export const defaultSealedWaxBoxTypeQuery = '(box,boxes,case,cases,pack,packs)';

export const sealedWaxBoxTypeOptions = [
  {
    id: 0,
    label: 'Any Boxes',
    value: 'box -case',
  },
  {
    id: 1,
    label: 'Any Cases',
    value: 'case -hit',
  },
  {
    id: 2,
    label: 'Any Packs',
    value: 'pack -(box,boxes,case,cases)',
  },
  {
    id: 3,
    label: 'Hobby Box',
    value: 'hobby box -(case,blaster,fat,cello,hanger,mega)',
  },
  {
    id: 4,
    label: 'Hobby Box Case',
    value: 'hobby case -(hit,blaster,fat,cello,hanger,mega)',
  },
  {
    id: 5,
    label: 'Jumbo Box',
    value: 'jumbo box -(case,fat,hanger,cello,mega)',
  },
  {
    id: 6,
    label: 'Jumbo Box Case',
    value: 'jumbo case -(fat,hanger,cello,mega)',
  },
  {
    id: 7,
    label: 'Blaster Box',
    value: 'blaster box -(case,cello,hobby,fat,hanger,mega)',
  },
  {
    id: 8,
    label: 'Blaster Box Case',
    value: 'blaster case -(cello,hobby,fat,hanger,mega)',
  },
  {
    id: 9,
    label: 'Hanger Box',
    value: 'hanger box -(case,cello,hobby,fat,blaster,mega)',
  },
  {
    id: 10,
    label: 'Hanger Box Case',
    value: 'hanger case -(cello,hobby,fat,blaster,mega)',
  },
  {
    id: 11,
    label: 'Cello Box',
    value: 'cello box -(case,hanger,hobby,fat,blaster,mega)',
  },
  {
    id: 12,
    label: 'Cello Box Case',
    value: 'cello case -(hanger,hobby,fat,blaster,mega)',
  },
  {
    id: 13,
    label: 'Retail Box',
    value: 'retail box -(case,hanger,hobby,fat,blaster,cello,mega)',
  },
  {
    id: 14,
    label: 'Retail Box Case',
    value: 'retail case -(hit,hanger,hobby,fat,blaster,cello)',
  },
  {
    id: 15,
    label: 'Mega Box',
    value:
      'mega box -(case,hanger,hobby,fat,blaster,cello,auto) -(refractor,refractors,mojo,prospects,prospect,sgc,bgs,psa)',
  },
  {
    id: 16,
    label: 'Mega Box Case',
    value:
      'mega case -(hanger,hobby,fat,blaster,cello,auto) -(refractor,refractors,mojo,prospects,prospect,sgc,bgs,psa)',
  },
  {
    id: 17,
    label: 'Fat Pack',
    value: 'fat pack -(box,case)',
  },
  {
    id: 18,
    label: 'Fat Packs Case',
    value: 'fat pack case -hit',
  },
  {
    id: 19,
    label: 'Jumbo Pack',
    value: 'jumbo pack -(box,case)',
  },
  {
    id: 20,
    label: 'Jumbo Packs Case',
    value: 'jumbo pack case -hit',
  },
  {
    id: 21,
    label: 'Value Pack',
    value: 'value pack -(box,case)',
  },
  {
    id: 22,
    label: 'Value Packs Case',
    value: 'value pack case -hit',
  },
  {
    id: 23,
    label: 'Cello Pack',
    value: 'cello pack -(box,case)',
  },
  {
    id: 24,
    label: 'Cello Packs Case',
    value: 'cello case -hit',
  },
];

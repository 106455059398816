import { useState } from 'react';
import clsx from 'clsx';
import { faMagnifyingGlassPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CollectibleImage, { CollectibleImageProps } from '../CollectibleImage/CollectibleImage';

import classes from './CollectibleImageModal.module.scss';
import ModalDialog from 'sci-ui-components/ModalDialog/ModalDialog';

export type CollectibleImageModalProps = CollectibleImageProps;

export default function CollectibleImageModal({
  collectibleType,
  className,
  url,
  alt,
  size,
  ...otherProps
}: CollectibleImageModalProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div {...otherProps} className={clsx(classes.root, className)}>
        <CollectibleImage collectibleType={collectibleType} url={url} alt={alt} size={size} />
        <button className={classes.button} onClick={() => setIsOpen(true)} title="Click to view larger image">
          <FontAwesomeIcon icon={faMagnifyingGlassPlus} className={classes.icon} />
        </button>
      </div>
      <ModalDialog visible={isOpen} onCancel={() => setIsOpen(false)} footer={null}>
        <CollectibleImage
          className={classes.largeImage}
          collectibleType={collectibleType}
          url={url}
          alt={alt}
          size={size ? size : 'large'}
        />
      </ModalDialog>
    </>
  );
}

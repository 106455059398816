import { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import AddOrEditFavoriteCategoryDialog from './AddOrEditFavoriteCategoryDialog';
import { registerDialog, AddOrEditFavoriteCategoryDialogProviderProps } from './index';

type State = Partial<AddOrEditFavoriteCategoryDialogProviderProps> & {
  isOpen: boolean;
};

export default withErrorBoundary(function AddOrEditFavoriteCategoryDialogProvider({ id }: { id: string }) {
  const [{ isOpen, resolve, collectibleType, categoryId, name }, setState] = useState<State>({
    isOpen: false,
  });

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          isOpen: true,
          categoryId: null,
          name: '',
          ...props,
        });
      }),
    [id]
  );

  return (
    <AddOrEditFavoriteCategoryDialog
      isOpen={isOpen}
      collectibleType={collectibleType!}
      categoryId={categoryId}
      name={name}
      onClose={() => setState((s) => ({ ...s, isOpen: false }))}
      onCreate={(createdCategory) => resolve?.(createdCategory)}
    />
  );
});

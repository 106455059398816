import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import { ApiError } from '../../../utils/api';
import { UntrackedCollectibleSale } from '../../../sci-ui-components/types/sales';
import constructEbayQuery, { ConstructEbayQueryParams } from '../../affiliates/utils/constructEbayQuery';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { ApiUntrackedCollectibleSale } from '../types';
import untrackedCollectibleSaleFromApi from './transformers/untrackedCollectibleSaleFromApi';

export type SearchUntrackedCollectiblesResponse = {
  items: UntrackedCollectibleSale[];
  truncatedItems?: UntrackedCollectibleSale[];
  generatedQuery: string | null; // NOTE: nullable because in future query generation should be done on backend and hidden from non-admin users
};

export type SearchUntrackedCollectiblesParams = {
  collectibleType: CollectibleType;
  setName?: string;
} & ConstructEbayQueryParams;

interface SearchUntrackedCollectibleSalesApiError {
  message: string;
  name: string;
}
function isApiError(
  response: ApiUntrackedCollectibleSale[] | SearchUntrackedCollectibleSalesApiError
): response is SearchUntrackedCollectibleSalesApiError {
  return !!(response as SearchUntrackedCollectibleSalesApiError).name;
}

// TODO: this API is a mess. Move all of the business logic of constructing the ebay query to the backend
export default async function searchUntrackedCollectibleSales(
  { setName, collectibleType, ...constructEbayQueryParams }: SearchUntrackedCollectiblesParams,
  signal?: AbortSignal
): Promise<SearchUntrackedCollectiblesResponse> {
  const query = constructEbayQuery(constructEbayQueryParams);
  const apiItems = await authenticatedFetchFromSciApi<
    ApiUntrackedCollectibleSale[] | SearchUntrackedCollectibleSalesApiError
  >('/ebay/alternativesearch', {
    method: 'POST',
    body: JSON.stringify({
      query,
      set: setName,
    }),
    signal,
  });

  if (isApiError(apiItems)) {
    throw new ApiError(apiItems.message, 500, apiItems);
  }

  return {
    items: apiItems?.map((item) => untrackedCollectibleSaleFromApi(item, { collectibleType })) ?? [],
    generatedQuery: query,
  };
}

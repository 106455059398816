import { UntrackedCollectibleSalesSearchFilters } from '../types';

export default function getFiltersDescriptionText(filters: UntrackedCollectibleSalesSearchFilters): string {
  const year = filters.year && !filters.cardSetName?.includes(String(filters.year)) ? filters.year : null;
  return [
    filters.sportName,
    filters.playerName,
    year,
    filters.cardSetName,
    filters.cardSetVariationName,
    filters.sportsCardGradeName,
    filters.sealedWaxBoxTypeName,
    formatTerms(filters.termsToInclude, 'including'),
    formatTerms(filters.termsToExclude, 'excluding'),
  ]
    .filter((v) => !!v)
    .join(' ');
}

function formatTerms(terms: string[] | undefined, prefix: string): string | null {
  if (!terms?.length) {
    return null;
  }
  const listFormat = new Intl.ListFormat('en-US');
  return `${prefix} ${listFormat.format(terms.map((t) => `"${t}"`))}`;
}

import { useCallback, useMemo } from 'react';
import { CollectibleType, CustomCollectible } from '@sportscardinvestor/schemas';
import {
  EditCollectionItemPurchaseDetailsForm,
  EditCollectionItemPurchaseDetailsFormPayload,
  OnEditCollectionItemPurchaseDetailsFormSubmitFn,
} from '../EditCollectionItemPurchaseDetailsDialog/EditCollectionItemPurchaseDetailsForm';
import {
  EditCustomCollectionItemForm,
  EditCustomCollectionItemFormInitialValues,
  getEditCustomCollectionItemFormInitialValues,
} from '../EditCustomCollectionItemDialog/forms/EditCustomCollectionItemForm';
import {
  EditCustomCollectibleValueCallbacks,
  EditCustomCollectibleValueForm,
  EditCustomCollectibleValueFormInitialValues,
} from '../EditCustomCollectibleValueDialog/EditCustomCollectibleValueForm';
import { EditCustomCollectionItemCallbacks } from '../EditCustomCollectionItemDialog/forms/callbacks';
import { useAddCollectionItemDialogStore } from './store';
import { makeTempCustomCollectibleFromValues } from './utils/makeTempCustomCollectibleFromValues';
import { SearchForm, SearchFormInitialValues } from './forms/SearchForm';
import useCurrencyFormatter from '@/hooks/useCurrencyFormatter';
import { useCollectible } from '@/features/collectibles/useCollectible';
import { FormLoadingIndicator } from '@/sci-ui-components/forms/EditForm';
import { trackEvent } from '@/features/analytics/trackEvent';

export type OnAddCollectionItemFormSubmitFn = (
  payload: {
    purchaseDetails: EditCollectionItemPurchaseDetailsFormPayload;
    customCollectibleInfo: EditCustomCollectionItemFormInitialValues | null;
    customCollectibleValueInfo: EditCustomCollectibleValueFormInitialValues | null;
    featuredCollectibleId: number | null;
    collectibleType: CollectibleType;
  } & (
    | {
        customCollectibleInfo: null;
        customCollectibleValueInfo: null;
        featuredCollectibleId: number;
      }
    | {
        customCollectibleInfo: EditCustomCollectionItemFormInitialValues;
        customCollectibleValueInfo: EditCustomCollectibleValueFormInitialValues;
        featuredCollectibleId: null;
      }
  )
) => void;

export function AddCollectionItemForms({
  isLoading,
  onCancel,
  onSubmit,
}: {
  onCancel: () => void;
  onSubmit: OnAddCollectionItemFormSubmitFn;
  isLoading: boolean;
}) {
  const {
    collectibleType,
    currentStep,
    initialSearchText,
    featuredCollectibleId,
    salesHistoryItem,
    customCollectibleInfo,
    initialCustomCollectibleInfo,
    customCollectibleValueInfo,
    initialCustomCollectibleValueInfo,
    initialPurchaseDetails,
    initialStep,
    goBackTo,
    saveSearchResult,
    saveCustomCollectibleInfo,
    saveCustomCollectibleValueInfo,
  } = useAddCollectionItemDialogStore();
  const [cancelText, handleCancelButton] = useMemo<[string | undefined, () => void]>(() => {
    if (currentStep === 'custom_collectible' && initialStep !== 'custom_collectible') {
      return ['Back', () => goBackTo('search')];
    }
    if (currentStep === 'check_value') {
      return ['Back', () => goBackTo('custom_collectible')];
    }
    if (currentStep === 'purchase_details' && initialStep !== 'purchase_details') {
      if (featuredCollectibleId) {
        return ['Back', () => goBackTo('search')];
      } else {
        return ['Back', () => goBackTo('check_value')];
      }
    }
    return [, onCancel];
  }, [initialStep, currentStep, onCancel, goBackTo, featuredCollectibleId]);
  const handleSubmit = useCallback<OnEditCollectionItemPurchaseDetailsFormSubmitFn>(
    (purchaseDetails) => {
      if (!purchaseDetails) {
        throw new Error('Missing purchase details'); // NOTE: should never happen
      }
      if (featuredCollectibleId) {
        onSubmit({
          collectibleType,
          featuredCollectibleId,
          purchaseDetails,
          customCollectibleInfo: null,
          customCollectibleValueInfo: null,
        });
      } else {
        if (!customCollectibleInfo) {
          throw new Error('Missing custom Collectible details'); // NOTE: should never happen
        }
        if (!customCollectibleValueInfo) {
          throw new Error('Missing custom Collectible value details'); // NOTE: should never happen
        }
        onSubmit({
          collectibleType,
          purchaseDetails,
          customCollectibleInfo,
          customCollectibleValueInfo,
          featuredCollectibleId: null,
        });
      }
    },
    [customCollectibleInfo, customCollectibleValueInfo, featuredCollectibleId, collectibleType, onSubmit]
  );

  const checkValueCallbacks = useMemo<EditCustomCollectibleValueCallbacks>(
    () => ({
      onCustomQueryGenerated: () =>
        trackEvent({
          eventName: 'COLLECTION_ADD_GENERATE_QUERY',
          collectibleType,
        }),
      onCustomQueryUpdated: () =>
        trackEvent({
          eventName: 'COLLECTION_ADD_QUERY_UPDATED',
          collectibleType,
        }),
      onValueUsed: () =>
        trackEvent({
          eventName: 'COLLECTION_ADD_USE_PRICE',
          collectibleType,
        }),
      onPhotoUsed: () =>
        trackEvent({
          eventName: 'COLLECTION_ADD_USE_PHOTO',
          collectibleType,
        }),
      onPhotoSelected: () =>
        trackEvent({
          eventName: 'COLLECTION_ADD_CARD_UPDATE_IMAGE',
          collectibleType,
        }),
    }),
    [collectibleType]
  );
  const editCustomCallbacks = useMemo<EditCustomCollectionItemCallbacks>(
    () => ({
      onPhotoSelected: () =>
        trackEvent({
          eventName: 'COLLECTION_ADD_CARD_UPDATE_IMAGE',
          collectibleType,
        }),
    }),
    [collectibleType]
  );

  const searchFormInitialValues = useMemo<SearchFormInitialValues>(
    () => ({
      searchText: initialSearchText,
    }),
    [initialSearchText]
  );
  const customCollectionItemInitialValues = useMemo<EditCustomCollectionItemFormInitialValues>(() => {
    if (customCollectibleInfo) {
      return customCollectibleInfo;
    }
    if (initialCustomCollectibleInfo) {
      return initialCustomCollectibleInfo;
    }
    return getEditCustomCollectionItemFormInitialValues({
      collectibleType,
      customCollectible: null,
    });
  }, [customCollectibleInfo, initialCustomCollectibleInfo, collectibleType]);

  const { convertPriceToUSD } = useCurrencyFormatter();

  const { data: featuredCollectible, isLoading: isLoadingFeaturedCollectible } = useCollectible(
    {
      collectibleId: featuredCollectibleId!,
      collectibleType,
    },
    {
      enabled: !!featuredCollectibleId,
    }
  );
  const tempCustomCollectible = useMemo<CustomCollectible | null>(() => {
    if (!customCollectibleInfo) {
      return null;
    }
    return makeTempCustomCollectibleFromValues({
      convertPriceToUSD,
      customCollectibleInfo,
      customCollectibleValueInfo,
    });
  }, [customCollectibleInfo, convertPriceToUSD, customCollectibleValueInfo]);
  const initialCustomCollectibleValueInfoWithImage = useMemo<EditCustomCollectibleValueFormInitialValues>(
    () =>
      initialCustomCollectibleValueInfo
        ? {
            ...initialCustomCollectibleValueInfo,
            imageUrl: customCollectibleInfo?.imageUrl ?? initialCustomCollectibleValueInfo?.imageUrl,
          }
        : {
            imageUrl: customCollectibleInfo?.imageUrl,
          },
    [initialCustomCollectibleValueInfo, customCollectibleInfo?.imageUrl]
  );
  if (currentStep === 'check_value' && !tempCustomCollectible) {
    goBackTo('custom_collectible');
  }

  return (
    <>
      {currentStep === 'search' && (
        <SearchForm
          collectibleType={collectibleType}
          initialValues={searchFormInitialValues}
          onSubmit={saveSearchResult}
        />
      )}
      {currentStep === 'custom_collectible' && (
        <EditCustomCollectionItemForm
          initialValues={customCollectionItemInitialValues}
          onSubmit={saveCustomCollectibleInfo}
          submitText="Next"
          onCancel={handleCancelButton}
          cancelText={cancelText}
          salesHistoryItem={salesHistoryItem}
          {...editCustomCallbacks}
        />
      )}
      {currentStep === 'check_value' && (
        <EditCustomCollectibleValueForm
          customCollectible={tempCustomCollectible!}
          initialValues={customCollectibleValueInfo ?? initialCustomCollectibleValueInfoWithImage ?? null}
          onSubmit={saveCustomCollectibleValueInfo}
          submitText="Next"
          onCancel={handleCancelButton}
          cancelText={cancelText}
          {...checkValueCallbacks}
        />
      )}
      {currentStep === 'purchase_details' && !!featuredCollectibleId && isLoadingFeaturedCollectible && (
        <FormLoadingIndicator className="w-[612px]" />
      )}
      {currentStep === 'purchase_details' && (!!featuredCollectible || !!tempCustomCollectible) && (
        <EditCollectionItemPurchaseDetailsForm
          collectibleType={collectibleType}
          collectible={featuredCollectible || tempCustomCollectible!}
          initialValues={initialPurchaseDetails}
          cancelText={cancelText}
          onCancel={handleCancelButton}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          isLoadingCollectible={isLoadingFeaturedCollectible}
          submitText="Add"
          showSubHeader
        />
      )}
    </>
  );
}

import { CollectibleType, isSportsCardCollectibleType } from 'sci-ui-components/types/collectibleType';
import { thresholdFromApiComparison } from 'services/sciApi/alerts/transformers/thresholdFromApiComparison';
import { alertStatusFromApi } from 'services/sciApi/alerts/transformers/alertStatusFromApi';
import { AddEditPriceAlertResponse } from 'sci-ui-components/types/priceAlert';
import { ApiAddEditPriceAlertResponse } from 'services/sciApi/types';

interface Params {
  collectibleType: CollectibleType;
}

export default function priceAlertResponseFromApi(
  apiItem: ApiAddEditPriceAlertResponse,
  params: Params
): AddEditPriceAlertResponse {
  const { collectibleType } = params;

  const isSportsCard = isSportsCardCollectibleType(collectibleType);

  const editAlertResponse: AddEditPriceAlertResponse = {
    id: apiItem.id,
    threshold: thresholdFromApiComparison(apiItem.comparison),
    name: apiItem.name,
    createdAt: apiItem.created_at,
    updatedAt: apiItem.updated_at,
    triggeredAt: apiItem.triggered_at,
    alertPrice: apiItem.value,
    status: alertStatusFromApi(apiItem.triggered_at),
    userId: apiItem.user_id,
    collectibleType,
    collectibleId: isSportsCard ? apiItem.card_id! : apiItem.sealed_wax_id!,
  };

  return editAlertResponse;
}

import { RawCompletedSaleSearchItem } from '../../../features/sales-history/useRawCompletedSalesSearch';

export type SearchItem = SportsCardSearch | SealedWaxSearch;

export type MaybeString = string | null | undefined;
export type MaybeNumber = number | null | undefined;

export interface SearchBase {
  id: number;
  adminImageUrl: MaybeString;
  allEndAvg: MaybeNumber;
  lastSaleDate: MaybeString;
  imageUrl: MaybeString;
  last14Count: MaybeNumber;
  last14EndAvg: MaybeNumber;
  last14StartAvg: MaybeNumber;
  last14Total: MaybeNumber;
  last14ChangeAmount: MaybeNumber;
  last14ChangePercentage: MaybeNumber;
}

export interface SportsCardSearch extends SearchBase {
  sportName: string;
  sportId: number;
  specificQualifier: MaybeString;
  gradeName: string;
  printRun: MaybeString;
  playerAlias: MaybeString;
  playerId: number;
  setName: string;
  gradeId: number;
  setId: number;
  packOdds: MaybeString;
  playerName: string;
  setYear: string;
  cardNumber: MaybeString;
  rc: boolean;
  variationId: number;
  variationName: string;
  populationCount: MaybeNumber;
}

export interface SealedWaxSearch extends SearchBase {
  sportName: string;
  sportId: number;
  boxTypeName: string;
  boxTypeId: number;
  setName: string;
  setId: number;
  setYear: string;
}

export type FeaturedCollectibleSearch = SealedWaxSearch | SportsCardSearch;

export function isSportCardSearch(search: SportsCardSearch | SealedWaxSearch): search is SportsCardSearch {
  return (<SportsCardSearch>search).playerName !== undefined || (<SportsCardSearch>search).playerId !== undefined;
}

export function isSealedWaxSearch(search: SportsCardSearch | SealedWaxSearch): search is SealedWaxSearch {
  return (<SportsCardSearch>search).playerName === undefined && (<SportsCardSearch>search).playerId === undefined;
}

export function isFeaturedCollectibleSearch(
  search: FeaturedCollectibleSearch | RawCompletedSaleSearchItem
): search is FeaturedCollectibleSearch {
  return (<RawCompletedSaleSearchItem>search).displayTitle === undefined;
}

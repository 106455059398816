import { KeyboardEventHandler, MouseEventHandler, ReactNode } from 'react';
import clsx from 'clsx';

export interface ClickableWrapperProps {
  className?: string;
  onClick: () => void;
  children: ReactNode;
}

export function ClickableWrapper({ onClick, className: classNameParam, ...rest }: ClickableWrapperProps) {
  const handleClick: MouseEventHandler<HTMLDivElement> = () => {
    onClick(); // TODO: handle detecting inner clicks to stop propagation
  };
  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      onClick();
    }
  };
  const className = clsx(
    'twp cursor-pointer w-full h-full hover:bg-lightBg-accentPrimary dark:hover:bg-darkBg-accentPrimary',
    classNameParam
  );
  return <div {...rest} className={className} onClick={handleClick} onKeyDown={onKeyDown} tabIndex={0} role="button" />;
}

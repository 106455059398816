import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import clsx from 'clsx';

import classes from './NumberField.module.scss';

export type NumberFieldProps = Omit<InputNumberProps<number>, 'value' | 'onChange' | 'type'> & {
  value: number | null | undefined;
  onChange?: (newValue: number | null, name?: string) => void;
};

export default React.forwardRef<HTMLInputElement, NumberFieldProps>(function NumberField(
  { className, value, onChange, placeholder = 'Enter number', id, name, ...other }: NumberFieldProps,
  ref
) {
  const handleChange = (value: number | null) => {
    onChange?.(value !== null ? value : null, name);
  };
  return (
    <InputNumber
      {...other}
      ref={ref}
      type="string"
      onChange={handleChange}
      value={typeof value === 'number' ? value : undefined}
      placeholder={placeholder}
      className={clsx(classes.root, className)}
      id={id}
      name={name}
    />
  );
});

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { faLockOpen } from '@fortawesome/pro-solid-svg-icons';
import HelpMenu from '../../../../../help/HelpMenu/HelpMenu';
import UserAccountMenu from '../../../../../../sci-ui-components/UserAccountMenu/UserAccountMenu';
import MobileMenu from '../../../../../../sci-ui-components/navigationMenus/MobileMenu/MobileMenu';
import { NavigationMenuItem } from '../../../../../../sci-ui-components/navigationMenus/types';
import QuickSearch from '../../../../../quick-search/QuickSearch';
import CollectibleTypeSelector from '../../../../../collectibles/CollectibleTypeSelector/CollectibleTypeSelector';
import useCollectibleType from '../../../../../collectibles/useCollectibleType';
import classes from './Header.module.scss';
import useAccessFlags from 'hooks/useAccessFlags';
import CurrencySelector from 'features/user/CurrencySelector/CurrencySelector';
import useQueryString, { getBooleanParam } from 'hooks/useQueryString';
import useLocalStorageValue from 'hooks/useLocalStorageValue';
import IconButton from 'sci-ui-components/buttons/IconButton/IconButton';
import { headerScrollLockTooltipMessage } from 'features/app/shells/util';

const hasChosenFallbackStorageKey = 'sci_has_chosen_fallback';
const fallbackChoiceStorageKey = 'sci_fallback_choice_is_mm1';

export default function Header({
  className,
  menuItems,
  logoHref,
}: {
  className?: string;
  menuItems: NavigationMenuItem[];
  logoHref: string;
}) {
  const [isSticky, setIsSticky] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const isUserPreferenceVisible = useAccessFlags({ flag: 'isUserPreferenceVisible' });

  const { collectibleType, setCollectibleType } = useCollectibleType();
  const [hasChosenFallback, setHasChosenFallback] = useLocalStorageValue<boolean | null>(
    hasChosenFallbackStorageKey,
    true
  );
  const [choiceIsMM1, setChoiceIsMM1] = useLocalStorageValue<boolean | null>(fallbackChoiceStorageKey, true);

  const { query } = useQueryString();
  const choiceIsMM1FromQuery = getBooleanParam<boolean | null>(query, fallbackChoiceStorageKey, null);

  useEffect(() => {
    if (choiceIsMM1 !== false && choiceIsMM1FromQuery === false) {
      setChoiceIsMM1(false);
      setHasChosenFallback(false);
    } else if (hasChosenFallback && choiceIsMM1) window.location.href = 'https://mm1.sportscardinvestor.com';
  });

  return (
    <div className={clsx(classes.wrapper, { [classes.searching]: isSearching, [classes.sticky]: isSticky }, className)}>
      <MobileMenu
        className={classes.mobileMenu}
        menuItems={menuItems}
        logoHref={logoHref}
        collectibleType={collectibleType}
        onCollectibleTypeChange={setCollectibleType}
      />
      <CollectibleTypeSelector className={classes.collectibleTypeSelector} />
      <QuickSearch className={classes.search} onOpenChange={setIsSearching} isOpen={isSearching} />
      <div className={classes.rightSection}>
        {isUserPreferenceVisible && (
          <CurrencySelector
            selectorClassName={classes.currencySelector}
            hasHeader={false}
            shouldSaveChangesAutomatically
          />
        )}
        <IconButton
          size="small"
          color={isSticky ? 'default' : 'grey'}
          faIcon={isSticky ? faLock : faLockOpen}
          title={headerScrollLockTooltipMessage(isSticky)}
          onClick={() => setIsSticky(!isSticky)}
        />
        <HelpMenu className={classes.helpMenu} />
        <UserAccountMenu />
      </div>
    </div>
  );
}

import qs from 'qs';
import {
  CollectibleType,
  isSealedWaxCardCollectibleType,
  isSportsCardCollectibleType,
} from 'sci-ui-components/types/collectibleType';
import { ApiAddEditPriceAlertResponse } from 'services/sciApi/types';
import authenticatedFetchFromSciApi from 'services/sciApi/authenticatedFetchFromSciApi';
import { getApiCollectibleType } from 'services/sciApi/alerts/transformers/getApiCollectibleType';
import priceAlertFromApi from 'services/sciApi/alerts/transformers/priceAlertFromApi';
import { ApiPriceAlert } from 'services/sciApi/types';
import { AlertThreshold } from 'sci-ui-components/types/priceAlert';
import { apiComparisonFromThreshold } from 'services/sciApi/alerts/transformers/thresholdFromApiComparison';
import priceAlertResponseFromApi from 'services/sciApi/alerts/transformers/priceAlertResponseFromApi';

export async function getPriceAlerts({ collectibleType }: { collectibleType: CollectibleType }) {
  const type = getApiCollectibleType(collectibleType);
  const url = `/alert${qs.stringify({ type }, { addQueryPrefix: true, skipNulls: true })}`;

  const apiPriceAlerts = await authenticatedFetchFromSciApi<ApiPriceAlert[]>(url, {
    method: 'GET',
  });

  return apiPriceAlerts.map((i) => priceAlertFromApi(i, { collectibleType }));
}

export interface CreatePriceAlertParams {
  name: string;
  threshold: AlertThreshold;
  value: number;
  collectibleId: number;
  collectibleType: CollectibleType;
}

export async function createPriceAlert({
  collectibleId,
  name,
  threshold,
  value,
  collectibleType,
}: CreatePriceAlertParams) {
  const apiResponse = await authenticatedFetchFromSciApi<ApiAddEditPriceAlertResponse>('/alert', {
    method: 'POST',
    body: JSON.stringify({
      name,
      value,
      comparison: apiComparisonFromThreshold(threshold),
      card_id: isSportsCardCollectibleType(collectibleType) ? collectibleId : null,
      sealed_wax_id: isSealedWaxCardCollectibleType(collectibleType) ? collectibleId : null,
      criteria: 'sale',
    }),
  });

  return priceAlertResponseFromApi(apiResponse, { collectibleType });
}

export interface EditPriceAlertParams {
  id: number;
  name: string;
  threshold: AlertThreshold;
  value: number;
  collectibleType: CollectibleType;
}

export async function editPriceAlert({ id, name, threshold, value, collectibleType }: EditPriceAlertParams) {
  const apiResponse = await authenticatedFetchFromSciApi<ApiAddEditPriceAlertResponse>(`/alert/${id}`, {
    method: 'PATCH',
    body: JSON.stringify({
      name,
      value,
      comparison: apiComparisonFromThreshold(threshold),
      criteria: 'sale',
    }),
  });

  return priceAlertResponseFromApi(apiResponse, { collectibleType });
}

export interface DeletePriceAlertParams {
  id: number;
}

export async function deletePriceAlert({ id }: DeletePriceAlertParams) {
  await authenticatedFetchFromSciApi<ApiPriceAlert>(`/alert/${id}`, {
    method: 'DELETE',
  });
}

export interface ReactivatePriceAlertParams {
  id: number;
  collectibleType: CollectibleType;
}

export async function reactivatePriceAlert({ id, collectibleType }: ReactivatePriceAlertParams) {
  const apiResponse = await authenticatedFetchFromSciApi<ApiAddEditPriceAlertResponse>(`/alert/activate/${id}`, {
    method: 'POST',
  });

  return priceAlertResponseFromApi(apiResponse, { collectibleType });
}

export type SavedAlertsSortField =
  | 'name'
  | 'threshold'
  | 'my-alert-price'
  | 'status'
  | 'last-7-price'
  | 'last-14-price'
  | 'last-30-price'
  | 'last-90-price'
  | 'all-end-avg';

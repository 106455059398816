import React, { useMemo } from 'react';
import NextLink from 'next/link';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import Button from '../../../sci-ui-components/buttons/Button/Button';
import checkIfHasMinimalFilters from '../../sales/UntrackedCollectibleSalesSearch/utils/checkIfHasMinimalFilters';
import getFiltersDescriptionText from '../../sales/UntrackedCollectibleSalesSearch/utils/getFiltersDescriptionText';
import { useUntrackedCollectibleSales } from '../../sales/UntrackedCollectibleSalesSearch/useUntrackedCollectibleSales';
import useUntrackedCollectibleSalesAdvancedFilters from '../../sales/UntrackedCollectibleSalesSearch/useUntrackedCollectibleSalesAdvancedFilters';
import UntrackedCollectiblesChart from '../ChartUntrackedCollectiblesPage/UntrackedCollectiblesChart/UntrackedCollectiblesChart';
import { generateChartUntrackedCollectiblesPageHref } from '../ChartUntrackedCollectiblesPage/utils/generateChartUntrackedCollectiblesPageHref';
import aggregateUntrackedCollectibleSales from '../ChartUntrackedCollectiblesPage/utils/aggregateUntrackedCollectibleSales';
import useChartSettings from '../useChartSettings';
import useUntrackedCollectibleSearchFilters from '../../collection/useUntrackedCollectibleSearchFilters';
import { ChartUntrackedCollectiblesDialogProps } from './types';

import classes from './ChartUntrackedCollectiblesDialog.module.scss';

export default function ChartUntrackedCollectiblesDialog({
  customCollectible,
  collectibleType = 'sports-card',
  isOpen,
  onClose,
}: ChartUntrackedCollectiblesDialogProps & {
  isOpen: boolean;
  onClose: (hasGoneToCharts: boolean) => void;
}) {
  const { filters } = useUntrackedCollectibleSearchFilters({ customCollectible, collectibleType });
  const hasMinimalFilters = checkIfHasMinimalFilters(filters);
  const { advancedFilters } = useUntrackedCollectibleSalesAdvancedFilters();
  const filtersDescriptionText = useMemo(() => getFiltersDescriptionText(filters), [filters]);
  const [chartUrl] = useMemo(
    () => generateChartUntrackedCollectiblesPageHref(filters, collectibleType),
    [filters, collectibleType]
  );
  const { data: { generatedQuery, items } = {}, isLoading } = useUntrackedCollectibleSales(
    {
      filters,
      advancedFilters,
      collectibleType,
    },
    {
      enabled: hasMinimalFilters,
    }
  );
  const { chartSettings } = useChartSettings({ dateRange: null });
  const aggregatedUntrackedCollectibleSales = useMemo(
    () => aggregateUntrackedCollectibleSales(items, chartSettings.groupBy),
    [items, chartSettings.groupBy]
  );

  const visible = isOpen;

  return (
    <ModalDialog
      visible={visible}
      title={
        <NextLink href={chartUrl} legacyBehavior>
          <Button onClick={() => onClose(true)} href={chartUrl} type="primary" className={classes.button}>
            View on Charts
          </Button>
        </NextLink>
      }
      titleAlign="right"
      footer={null}
      width="100%"
      onCancel={() => onClose(false)}
      dense
      centered
    >
      <UntrackedCollectiblesChart
        data={aggregatedUntrackedCollectibleSales}
        emptyMessage="No results found"
        filtersDescriptionText={filtersDescriptionText}
        isLoading={isLoading}
        generatedQuery={generatedQuery}
        noFullScreen
      />
    </ModalDialog>
  );
}

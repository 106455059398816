import React, { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import QueryString from 'qs';
import Search from '../../sci-ui-components/Search/Search';
import {
  SportsCardSearch,
  SealedWaxSearch,
  isFeaturedCollectibleSearch,
  FeaturedCollectibleSearch,
} from '../../services/sciApi/search/types';
import SportsSearchItem from '../../sci-ui-components/Search/items/SearchItem/SportSearchItem';
import SealedWaxSearchItem from '../../sci-ui-components/Search/items/SearchItem/SealedWaxSearchItem';
import { SalesHistoryItem } from '../sales-history/SalesHistoryItem';
import { CollectibleType } from '../../sci-ui-components/types/collectibleType';
import useCollectibleType from '../collectibles/useCollectibleType';
import { useCommonCollectiblesActions } from '../collectibles/useCommonCollectiblesActions';
import { RawCompletedSaleSearchItem } from '../sales-history/useRawCompletedSalesSearch';
import { openSaleDetails } from '../sales-history/SaleDetailsDialog';
import TrySalesHistoryMessage from '../../sci-ui-components/Search/TrySalesHistoryMessage';
import { Route } from '../app/constants';
import { convertCollectiblesSearchItemsToOldSearchItems } from '../collectibles/useCollectiblesSearchOld';
import SelectedCards from './SelectedCards';
import { useUnifiedSearch } from './useUnifiedSearch';
import useSelectedCollectibleIds from 'features/search/useSelectedCollectibleIds';
import { ClickableWrapper } from '@/sci-ui-components/ClickableWrapper';

const placeholderByType: Record<CollectibleType, string> = {
  'sports-card': 'e.g. Luka Doncic Prizm',
  'sealed-wax-card': 'e.g. 2003 Topps Chrome Basketball Hobby Box',
};

const QuickSearch = ({
  isOpen,
  onOpenChange,
  className,
}: {
  onOpenChange: (isOpen: boolean) => void;
  isOpen: boolean;
  className?: string;
}) => {
  const { collectibleType } = useCollectibleType();
  const { selectedCollectibleIds, isEmpty, toggleSelectCollectibleId } = useSelectedCollectibleIds();
  const [searchText, setSearchText] = useState('');
  const [isClickOutsideToCloseEnabled, setIsClickOutsideToCloseEnabled] = useState(true);
  const router = useRouter();

  const unifiedSearch = useUnifiedSearch(
    {
      collectibleType,
      searchText,
      debounceTimeInMs: 200,
      enableSpellCheck: true,
      enableForEmptySearchText: true,
    },
    {
      enabled: isOpen,
    }
  );
  const { searchResultsType, toggleSearchResultsType } = unifiedSearch;
  const featuredExactMatchItemsOld = useMemo(
    () => convertCollectiblesSearchItemsToOldSearchItems(unifiedSearch.featured.exactMatchItems),
    [unifiedSearch.featured.exactMatchItems]
  );

  const actions = useCommonCollectiblesActions({ collectibleType });

  const handleRawSaleItemClick = async (item: RawCompletedSaleSearchItem) => {
    setIsClickOutsideToCloseEnabled(false);
    try {
      await openSaleDetails({ sale: item, query: searchText });
    } finally {
      setIsClickOutsideToCloseEnabled(true);
    }
  };

  const handleChartCollectible = async (collectible: SportsCardSearch | SealedWaxSearch) => {
    if (selectedCollectibleIds.length) {
      toggleSelectCollectibleId(collectible.id);
    } else {
      setIsClickOutsideToCloseEnabled(false);
      try {
        const hasGoneToCharts = await actions.chartCollectible([collectible.id]);
        if (hasGoneToCharts) {
          setSearchText('');
          onOpenChange(false);
        }
      } finally {
        setIsClickOutsideToCloseEnabled(true);
      }
    }
  };

  const handleEnterPress = () => {
    if (searchResultsType === 'salesHistory') {
      router.push(
        `${Route.SalesHistory}${QueryString.stringify(
          { search: searchText },
          { addQueryPrefix: true, skipNulls: true }
        )}`
      );
      setSearchText('');
      onOpenChange(false);
    }
  };

  return (
    <Search<FeaturedCollectibleSearch | RawCompletedSaleSearchItem>
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className={className}
      searchTerm={searchText}
      isFetching={unifiedSearch[searchResultsType].isFetchingNextPage}
      isLoading={unifiedSearch[searchResultsType].isLoading}
      hasSelectedCards={!isEmpty}
      collectibleType={collectibleType}
      listItems={
        searchResultsType === 'featured' ? featuredExactMatchItemsOld : unifiedSearch.salesHistory.exactMatchItems
      }
      extraItems={searchResultsType === 'featured' ? undefined : unifiedSearch.salesHistory.fuzzyMatchItems}
      spellCheckAlternatives={unifiedSearch[searchResultsType].spellCheckSuggestions}
      searchInputProps={{
        placeholder: placeholderByType[collectibleType],
      }}
      emptyMessage={
        searchResultsType === 'featured' ? (
          <TrySalesHistoryMessage searchText={searchText} noTextWrapper />
        ) : (
          <TrySalesHistoryMessage
            searchText={searchText}
            customTopText={'No Results Found'}
            customBottomText={''}
            noTextWrapper
          />
        )
      }
      onPressEnter={handleEnterPress}
      renderItem={(item, _i, isExtra) => {
        const key = getItemKey(item, isExtra);
        if (!isFeaturedCollectibleSearch(item)) {
          return (
            <ClickableWrapper className="p-2" key={key} onClick={() => handleRawSaleItemClick(item)}>
              <SalesHistoryItem item={item} collectibleType={collectibleType} size="md" />
            </ClickableWrapper>
          );
        }
        const isSelected = selectedCollectibleIds.some((id) => id === item.id);
        switch (collectibleType) {
          case 'sports-card':
            const sportsCard = item as SportsCardSearch;
            return (
              <SportsSearchItem
                {...sportsCard}
                selected={isSelected}
                key={key}
                onChartButtonClick={() => toggleSelectCollectibleId(sportsCard.id)}
                onClick={() => handleChartCollectible(sportsCard)}
                onPopulationCountClick={() => actions.chartPopulationCount(item.id)}
              />
            );
          case 'sealed-wax-card':
            const wax = item as SealedWaxSearch;
            return (
              <SealedWaxSearchItem
                {...wax}
                selected={isSelected}
                key={key}
                onChartButtonClick={() => toggleSelectCollectibleId(wax.id)}
                onClick={() => handleChartCollectible(wax)}
              />
            );
          default:
            return <p> un mapped collectible card</p>;
        }
      }}
      onSearch={(value) => setSearchText(value ?? '')}
      hasNextPage={unifiedSearch[searchResultsType].hasNextPage}
      fetchNextPage={unifiedSearch[searchResultsType].fetchNextPageIfAvailable}
      listWrapper={{
        beforeBlock:
          searchResultsType === 'featured' ? <SelectedCards onCardsChart={() => onOpenChange(false)} /> : null,
      }}
      searchType={searchResultsType}
      onChangeSearchType={toggleSearchResultsType}
      closeOnClickAway={isClickOutsideToCloseEnabled}
    />
  );
};

function getItemKey(item: FeaturedCollectibleSearch | RawCompletedSaleSearchItem, isExtra: boolean): string {
  return isFeaturedCollectibleSearch(item)
    ? `${item.id}${isExtra ? '-extra' : ''}`
    : `${item._id}${isExtra ? '-extra' : ''}`;
}

export default QuickSearch;

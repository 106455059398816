/**
 * Returns a function that given X value returns Y value of the trend line for the supplied data set
 */
export function createTrendLineEquation<TItem>({
  data,
  getXValue,
  getYValue,
}: {
  data: TItem[];
  getXValue: (item: TItem) => number;
  getYValue: (item: TItem) => number | null;
}): (xValue: number) => number {
  // first, create array of continious points
  const points = data.reduce<[number, number][]>((acc, item, index) => {
    const x = getXValue(item);
    let y = getYValue(item);
    if (y === null) {
      const prevPoint = acc[index - 1];
      y = prevPoint ? prevPoint[1] : null;
    }
    if (y !== null) {
      acc.push([x, y]);
    }
    return acc;
  }, []);
  // Trend Line Equation: Y = M * X + B, where M is slope and B is Y-intercept
  // calculating slope (M)
  const numberOfPoints = points.length;
  // slope (M) = (a - b) / (c - d)
  // a = N * SUM(Xn * Yn)
  const a = numberOfPoints * points.reduce((acc, [x, y]) => acc + x * y, 0);
  // b = SUM(Xn) * SUM(Yn)
  const sumOfXValues = points.reduce((acc, [x]) => acc + x, 0);
  const sumOfYValues = points.reduce((acc, [_, y]) => acc + y, 0);
  const b = sumOfXValues * sumOfYValues;
  // c = N * SUM(Xn^2)
  const c = numberOfPoints * points.reduce((acc, [x]) => acc + Math.pow(x, 2), 0);
  // d = SUM(Xn)^2
  const d = Math.pow(sumOfXValues, 2);
  const slope = (a - b) / (c - d);

  // calculating Y-intercept
  // yIntercept (B) = (e - f) / N
  // e = SUM(Yn)
  const e = sumOfYValues;
  // f = slope * SUM(Xn)
  const f = slope * sumOfXValues;
  const yIntercept = (e - f) / numberOfPoints;
  return (xValue: number) => slope * xValue + yIntercept;
}

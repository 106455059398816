import React from 'react';
import { makeCollectibleDescriptionsFromParts } from '@sportscardinvestor/collectible-helpers';
import { formatDateInTimeZone } from '../../../utils/date';

import { SealedWaxSearch } from '../../../../services/sciApi/search/types';
import SearchItem from './SearchItem';
import useFormatStatValue from 'hooks/useFormatStatValue';

const SealedWaxSearchItem: React.FC<
  SealedWaxSearch & { selected?: boolean; onChartButtonClick?: () => void; onClick?: () => void }
> = (item) => {
  const { formatStatValue } = useFormatStatValue();
  const { title, descriptionLine1, descriptionLine2 } = makeCollectibleDescriptionsFromParts({
    collectibleType: 'sealed-wax-card',
    boxType: item.boxTypeName,
    setYear: item.setYear,
    sportName: item.sportName,
    setName: item.setName,
  });

  return (
    <SearchItem
      imageUrl={item.imageUrl}
      title={title}
      titlePrefix={null}
      selected={item.selected}
      descriptionText={descriptionLine1}
      extraText={descriptionLine2}
      gradeAndCount={<>&nbsp;</>}
      priceText={item.lastSaleDate ? formatStatValue({ value: item.allEndAvg, type: 'price' }) : ''}
      saleDateText={item.lastSaleDate ? formatDateInTimeZone(item.lastSaleDate) : 'No Recorded Sales'}
      onChartButtonClick={() => item.onChartButtonClick?.()}
      onClick={() => item.onClick?.()}
    />
  );
};

export default SealedWaxSearchItem;

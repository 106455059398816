import { useMemo } from 'react';
import { CollectibleType, isSealedWaxCardCollectibleType } from '../../../sci-ui-components/types/collectibleType';
import { SearchUntrackedCollectiblesParams } from '../../../services/sciApi/sales/searchUntrackedCollectibleSales';
import { defaultSealedWaxBoxTypeQuery, sealedWaxBoxTypeOptions } from '../../sealedWaxBoxTypes/sealedWaxBoxTypeOptions';
import { UntrackedCollectibleSalesAdvancedFilters, UntrackedCollectibleSalesSearchFilters } from './types';
import useUntrackedCollectibleSalesSearchExtendedFilters from './useUntrackedCollectibleSalesSearchExtendedFilters';

export default function useUntrackedCollectibleSalesParams({
  filters,
  advancedFilters,
  collectibleType,
}: {
  filters: UntrackedCollectibleSalesSearchFilters;
  advancedFilters: UntrackedCollectibleSalesAdvancedFilters;
  collectibleType: CollectibleType;
}): {
  params: SearchUntrackedCollectiblesParams;
  isLoading: boolean;
} {
  const { extendedFilters, isLoading } = useUntrackedCollectibleSalesSearchExtendedFilters({
    filters,
    collectibleType,
  });
  const params = useMemo<SearchUntrackedCollectiblesParams>(() => {
    const singleFieldQuery = filters.singleFieldQuery ?? '';
    const sportQuery =
      extendedFilters.sport?.query ??
      extendedFilters.sport?.name ??
      (filters.sportName || undefined) ??
      extendedFilters.cardSet?.sport?.query ??
      extendedFilters.cardSet?.sport?.name;
    const yearQuery = (extendedFilters.year || undefined) ?? extendedFilters.cardSet?.year;
    const playerQuery = extendedFilters.player?.query ?? extendedFilters.player?.name ?? filters.playerName;
    const cardSetQuery =
      (extendedFilters.cardSet?.query === 'ONLY USE FOR WAX' ? null : extendedFilters.cardSet?.query) ??
      extendedFilters.cardSet?.name ??
      filters.cardSetName;
    const cardSetVariationQuery = extendedFilters.cardSetVariation?.variation_name ?? filters.cardSetVariationName;
    const cardGradeQuery =
      extendedFilters.sportsCardGrade?.query ?? extendedFilters.sportsCardGrade?.name ?? filters.sportsCardGradeName;
    const maybeSealedWaxBoxTypeOption = filters?.sealedWaxBoxTypeName
      ? sealedWaxBoxTypeOptions?.find(({ label }) => label === filters.sealedWaxBoxTypeName)
      : undefined;
    const sealedWaxBoxTypeQuery =
      maybeSealedWaxBoxTypeOption?.value ??
      maybeSealedWaxBoxTypeOption?.label ??
      extendedFilters.sealedWaxBoxType?.query ??
      extendedFilters.sealedWaxBoxType?.name ??
      filters.sealedWaxBoxTypeName;

    return {
      collectibleType,
      singleFieldQuery,
      sportQueries: sportQuery ? [sportQuery] : undefined,
      playerNameQueries: playerQuery ? [playerQuery] : undefined,
      setYearQueries: yearQuery ? [String(yearQuery)] : undefined,
      setQueries: cardSetQuery ? [cardSetQuery] : undefined,
      setVariationQueries: cardSetVariationQuery ? [cardSetVariationQuery] : undefined,
      sportsCardGradeQueries: cardGradeQuery ? [cardGradeQuery] : undefined,
      sealedWaxBoxTypeQuery:
        sealedWaxBoxTypeQuery ??
        (isSealedWaxCardCollectibleType(collectibleType) ? defaultSealedWaxBoxTypeQuery : undefined),
      excludeBreaks: advancedFilters.excludeBreaks,
      excludeLots: advancedFilters.excludeLots,
      excludeSealedWaxTerms: isSealedWaxCardCollectibleType(collectibleType)
        ? false
        : advancedFilters.excludeSealedWaxTerms,
      removeSportsFromSetNames: advancedFilters.removeSportsFromSetNames,
      termsToInclude: filters.termsToInclude,
      termsToExclude: filters.termsToExclude,
      setName: filters.cardSetName,
    };
  }, [filters, advancedFilters, collectibleType, extendedFilters]);
  return {
    isLoading,
    params,
  };
}

import { CollectionImportDataKey, cardKeys, waxKeys } from '../../../sci-ui-components/types/collectionImport';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';

const collectionCsvColumns = [
  'Sport',
  'Grade',
  'PlayerName',
  'BoxType',
  'Year',
  'Set',
  'Variation',
  'CardNumber',
  'SpecificQualifier',
  'Quantity',
  'DatePurchased',
  'PurchasePricePerCard',
  'Notes',
  'Category',
] as const;

export type CollectionCsvColumn = (typeof collectionCsvColumns)[number];

const collectionExportDataKeys = ['date-sold', 'sold-price', 'last-sale-price', 'last-sale-date'] as const;
export type CollectionExportOnlyDataKey = (typeof collectionExportDataKeys)[number];

export const collectionCsvHeaders: Record<CollectionImportDataKey, CollectionCsvColumn> = {
  sport: 'Sport',
  grade: 'Grade',
  'player-name': 'PlayerName',
  'box-type': 'BoxType',
  year: 'Year',
  set: 'Set',
  variation: 'Variation',
  'card-number': 'CardNumber',
  'specific-qualifier': 'SpecificQualifier',
  quantity: 'Quantity',
  'date-purchased': 'DatePurchased',
  'purchase-price-per-card': 'PurchasePricePerCard',
  notes: 'Notes',
  category: 'Category',
};

export const collectionExportOnlyCsvHeaders: Record<CollectionExportOnlyDataKey, string> = {
  'date-sold': 'Date Sold',
  'sold-price': 'Sold Price',
  'last-sale-price': 'Last Sale Price',
  'last-sale-date': 'Last Sale Date',
};

export const getCollectibleHeaders = (collectibleType: CollectibleType) => {
  const collectibleHeaders = structuredClone(collectionCsvHeaders);
  (collectibleType === 'sealed-wax-card' ? cardKeys : waxKeys).forEach((key) => {
    delete collectibleHeaders[key];
  });
  return collectibleHeaders;
};

import { useQuery } from 'react-query';
import useAuth from 'features/auth/useAuth';
import { CollectibleType } from 'sci-ui-components/types/collectibleType';
import { getPriceAlerts } from 'services/sciApi/alerts';

export const getAlertsKey = (collectibleType?: CollectibleType) => [
  `alerts`,
  ...(collectibleType ? [collectibleType] : []),
];

export function usePriceAlerts({ collectibleType }: { collectibleType: CollectibleType }) {
  const { isLoggedIn } = useAuth();
  const queryResult = useQuery(
    getAlertsKey(collectibleType),
    () =>
      getPriceAlerts({
        collectibleType,
      }),
    {
      enabled: isLoggedIn,
    }
  );

  return queryResult;
}

import clsx from 'clsx';
import { CollectibleType, CollectionItem } from '@sportscardinvestor/schemas';
import { collectibleNames } from '../collectibles/constants';
import useFormatStatValue from 'hooks/useFormatStatValue';
import { formatDate } from '@/sci-ui-components/utils/date';

export default function CollectionItemPurchaseDetails({
  quantity,
  totalPriceOfOwnershipPerItem,
  datePurchased,
  totalPriceOfOwnershipTotal,
  doNotConvertToDisplayCurrency,
  collectibleType,
  className,
}: Pick<
  CollectionItem,
  'quantity' | 'totalPriceOfOwnershipPerItem' | 'datePurchased' | 'totalPriceOfOwnershipTotal'
> & {
  className?: string;
  collectibleType: CollectibleType;
  doNotConvertToDisplayCurrency?: boolean;
}) {
  const { formatStatValue } = useFormatStatValue();
  return (
    <p className={clsx('text-base', className)}>
      <strong className="text-lg font-medium">{quantity}</strong>
      &nbsp;
      {collectibleNames[collectibleType][quantity === 1 ? 'shortSingular' : 'shortPlural']}
      &nbsp;for&nbsp;
      <strong className="text-lg font-medium">
        {formatStatValue({
          type: 'price',
          value: totalPriceOfOwnershipPerItem,
          doNotConvertToDisplayCurrency,
        })}
      </strong>
      &nbsp;each
      {datePurchased ? (
        <>
          &nbsp;on&nbsp;
          <strong className="text-lg font-medium">{formatDate(datePurchased)}</strong>
        </>
      ) : null}
      <br />
      Total purchase price:&nbsp;
      <strong className="text-lg font-medium">
        {formatStatValue({
          type: 'price',
          value: totalPriceOfOwnershipTotal,
          doNotConvertToDisplayCurrency,
        })}
      </strong>
    </p>
  );
}

import React from 'react';
import { Dropdown, Menu } from 'antd';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { collectibleNames, CollectibleType } from '../constants';
import useCollectibleType from '../useCollectibleType';

import classes from './CollectibleTypeSelector.module.scss';

export default function CollectibleTypeSelector({ className, short }: { className?: string; short?: boolean }) {
  const { collectibleType, setCollectibleType } = useCollectibleType();

  const menuItems: {
    label: string;
    key: CollectibleType;
  }[] = [
    {
      label: collectibleNames['sports-card']['shortPlural'],
      key: 'sports-card',
    },
    {
      label: collectibleNames['sealed-wax-card'][short ? 'shortPlural' : 'plural'],
      key: 'sealed-wax-card',
    },
  ];

  return (
    <Dropdown
      trigger={['hover']}
      className={clsx(classes.root, className)}
      overlay={
        <Menu
          onClick={({ key }) => setCollectibleType(key as CollectibleType)}
          items={menuItems}
          className={classes.menu}
        />
      }
      placement="bottomRight"
    >
      <a>
        <label className={classes.label}>Collectible:</label>
        <div className={classes.selection}>
          <span className={classes.value}>
            {collectibleNames[collectibleType][short || collectibleType === 'sports-card' ? 'shortPlural' : 'plural']}
          </span>
          <FontAwesomeIcon className={classes.icon} icon={faCaretDown} />
        </div>
      </a>
    </Dropdown>
  );
}

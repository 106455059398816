import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion as faCircleQuestionSolid, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import {
  faCircleQuestion,
  faEnvelope,
  faLifeRing,
  faGift,
  faFileInvoiceDollar,
} from '@fortawesome/pro-light-svg-icons';
import classes from './HelpMenu.module.scss';
import { useSubmitBugReport, BugReportInput } from './useSubmitBugReport';
import useUser from 'features/user/useUser';
import SupportTicketModal from '@/features/help/SupportTicket/SupportTicketModal';
import IconButton from 'sci-ui-components/buttons/IconButton/IconButton';
import { isUserOfMembershipTier } from 'features/user/utils';
import { trackEvent } from '@/features/analytics/trackEvent';

export default function HelpMenu({ className }: { className?: string }) {
  const { data: user } = useUser();
  const [problemModalVisible, setProblemModalVisible] = useState(false);
  const { submitBugReport } = useSubmitBugReport();

  const handleProblem = () => {
    setProblemModalVisible(true);
  };

  const onCancel = () => {
    setProblemModalVisible(false);
  };

  const submitBugReportWithDelay = async (input: BugReportInput) => {
    const DELAY_TO_GRAB_SCREEN_AFTER_MODAL_CLOSED = 2000;
    setTimeout(() => submitBugReport(input), DELAY_TO_GRAB_SCREEN_AFTER_MODAL_CLOSED);
  };

  const REPORT_A_PROBLEM = 'Report a Problem';

  const helpMenuItems = [
    {
      key: '0',
      label: (
        <div className={classes.helpMenuItem}>
          <FontAwesomeIcon icon={faFileInvoiceDollar} />
          <a
            className={classes.helpMenuItemLabel}
            href="https://www.sportscardinvestor.com/account/?action=subscriptions"
            rel="noreferrer"
            target="_blank"
            onClick={() => {
              trackEvent({
                eventName: 'HELP_MENU_ITEM_CLICKED',
                menuItem: 'SUBSCRIPTION',
                linkURL: 'https://www.sportscardinvestor.com/account/?action=subscriptions',
              });
              return;
            }}
          >
            MANAGE SUBSCRIPTION
          </a>
        </div>
      ),
    },
    {
      key: '1',
      label: (
        <div className={classes.helpMenuItem}>
          <FontAwesomeIcon icon={faLifeRing} />
          <span
            className={classes.helpMenuItemLabel}
            onClick={() => {
              handleProblem();
            }}
          >
            {REPORT_A_PROBLEM.toLocaleUpperCase()}
          </span>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div className={classes.helpMenuItem}>
          <FontAwesomeIcon icon={faCircleQuestion} />
          <a
            className={classes.helpMenuItemLabel}
            href="https://marketmoversapp.com/faqs/"
            rel="noreferrer"
            target="_blank"
            onClick={() => {
              trackEvent({
                eventName: 'HELP_MENU_ITEM_CLICKED',
                menuItem: 'FAQ',
                linkURL: 'https://marketmoversapp.com/faqs/',
              });
              return;
            }}
          >
            FAQ
          </a>
        </div>
      ),
    },
    /*
   TODO uncomment when we have the video
    {
      key: '3',
      label: (
        <div className={classes.helpMenuItem}>
          <FontAwesomeIcon icon={faPlay} />
          <a
            className={classes.helpMenuItemLabel}
            href="https://marketmoversapp.com/video-tutorials/"
            rel="noreferrer"
            target="_blank"
            onClick={() => {
              trackEvent({
                eventName: 'HELP_MENU_ITEM_CLICKED',
                menuItem: 'Video Tutorials',
                linkURL: 'https://marketmoversapp.com/video-tutorials/',
              });
              return;
            }}
          >
            Video Tutorials
          </a>
        </div>
      ),
    },
    */
    {
      key: '4',
      label: (
        <div className={classes.helpMenuItem}>
          <FontAwesomeIcon icon={faEnvelope} />
          <a
            className={classes.helpMenuItemLabel}
            href="https://marketmoversapp.com/contact-support/"
            rel="noreferrer"
            target="_blank"
            onClick={() => {
              trackEvent({
                eventName: 'HELP_MENU_ITEM_CLICKED',
                menuItem: 'Contact Support',
                linkURL: 'https://marketmoversapp.com/contact-support/',
              });
              return;
            }}
          >
            Contact Support
          </a>
        </div>
      ),
    },
  ];

  const helpMenuItemsForUnlimitedOnly = [
    {
      key: '5',
      label: (
        <div className={classes.helpMenuItem}>
          <FontAwesomeIcon icon={faGift} />
          <a
            className={classes.helpMenuItemLabel}
            href="https://marketmoversapp.com/unlimited-member-benefits/"
            rel="noreferrer"
            target="_blank"
            onClick={() => {
              trackEvent({
                eventName: 'HELP_MENU_ITEM_CLICKED',
                menuItem: 'Unlimited Benefits',
                linkURL: 'https://marketmoversapp.com/unlimited-member-benefits/',
              });
              return;
            }}
          >
            Unlimited Benefits
          </a>
        </div>
      ),
    },
  ];

  if (user && isUserOfMembershipTier(user, 'unlimited')) helpMenuItems.push(...helpMenuItemsForUnlimitedOnly);

  const menu = (
    <Menu className={classes.helpMenuOverlay} style={{ borderRadius: '4px', padding: '8px' }} items={helpMenuItems} />
  );

  return (
    <div className={clsx(className, classes.helpMenu)}>
      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" align={{ offset: [0, -2] }}>
        <div>
          <a
            onClick={(e) => {
              e.preventDefault();
              trackEvent({
                eventName: 'HELP_MENU_CLICKED',
              });
            }}
            className={classes.link}
          >
            <FontAwesomeIcon icon={faCircleQuestionSolid} />
            <span>HELP</span>
            <FontAwesomeIcon icon={faCaretDown} />
          </a>
          <div className={classes.mobileLink}>
            <IconButton faIcon={faCircleQuestionSolid} title="Open help menu" size="large" color="lightGrey" />
          </div>
        </div>
      </Dropdown>
      <SupportTicketModal
        title={REPORT_A_PROBLEM}
        onCancel={onCancel}
        onProblemFormFilled={(bugReportInput) => {
          onCancel();
          submitBugReportWithDelay(bugReportInput);
        }}
        visible={problemModalVisible}
      ></SupportTicketModal>
    </div>
  );
}

import InfoBarUi from '../../sci-ui-components/InfoBar/InfoBar';
import useCollectibleType from '../collectibles/useCollectibleType';
import usePlatformInfoBanners from '../platform/usePlatformInfoBanners';
import usePlatformStats from '../platform/usePlatformStats';

export default function InfoBar({ className }: { className?: string }) {
  const { collectibleType } = useCollectibleType();
  const { banners } = usePlatformInfoBanners({});
  const { stats } = usePlatformStats({
    dateRangeInDays: 30,
  });

  return (
    <InfoBarUi
      className={className}
      collectibleType={collectibleType}
      banners={banners ?? []}
      platformStats={stats ?? null}
    />
  );
}

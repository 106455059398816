import { useMemo } from 'react';
import { mmApiClient, MmApiInput, MmApiOutput, MmApiQueryOptions, usePublicMMApiQuery } from '@/services/mmApiX';

export type UsePlatformStatsInput = MmApiInput['public']['platform']['stats'];
export type UsePlatformStatsOutput = Exclude<MmApiOutput['public']['platform']['stats'], void>;

export type PlatformStats = UsePlatformStatsOutput & {
  dateRangeInDays: number;
};

export const usePlatformStatsQueryKey = 'public.platform.stats';
type QueryKey = [typeof usePlatformStatsQueryKey, UsePlatformStatsInput];

export default function usePlatformStats(
  params: UsePlatformStatsInput,
  options?: MmApiQueryOptions<UsePlatformStatsOutput, QueryKey>
) {
  const queryKey: QueryKey = [usePlatformStatsQueryKey, params];

  const result = usePublicMMApiQuery(queryKey, () => mmApiClient.public.platform.stats.query(params), options);

  const stats = useMemo<PlatformStats | null>(() => {
    if (!result?.data?.stats) {
      return null;
    }
    return {
      stats: result.data.stats,
      dateRangeInDays: params.dateRangeInDays,
    };
  }, [result?.data?.stats, params.dateRangeInDays]);

  return {
    ...result,
    stats,
  };
}

import { MmApiInput, MmApiOutput, MmApiQueryOptions, mmApiClient, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';

type UseAvailableGradesByCollectibleInput = MmApiInput['private']['collectibles']['filters']['availableGradesForCard'];
type UseAvailableGradesByCollectibleOutput =
  MmApiOutput['private']['collectibles']['filters']['availableGradesForCard'];
type UseAvailableGradesByCollectibleKey = [
  'private.collectibles.filters.availableGradesForCard',
  UseAvailableGradesByCollectibleInput
];

export function useAvailableGradesByCollectible(
  input: UseAvailableGradesByCollectibleInput,
  options?: MmApiQueryOptions<UseAvailableGradesByCollectibleOutput, UseAvailableGradesByCollectibleKey>
) {
  const key: UseAvailableGradesByCollectibleKey = ['private.collectibles.filters.availableGradesForCard', input];
  const result = useAuthenticatedMMAPIQuery(
    key,
    () => mmApiClient.private.collectibles.filters.availableGradesForCard.query(input),
    options
  );

  return result;
}

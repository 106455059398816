import { normalizeListingType } from '@sportscardinvestor/marketplace-helpers';
import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import {
  ForSaleListingItem,
  ForSaleListingItemBase,
  ForSaleListingItemSportsCard,
  ForSaleListingItemSealedWax,
} from '../../../../sci-ui-components/types/sales';
import { ApiForSaleListingItem, ApiForSaleListingItemSportsCards, ApiForSaleListingItemSealedWax } from '../../types';
import { marketplaceHelpers, ensureValidMarketplace } from '../../../marketplaceHelpers';
import getSellerInfo from './getSellerInfo';

export default function forSaleListingItemFromApi(
  apiItem: ApiForSaleListingItem,
  collectibleType: CollectibleType
): ForSaleListingItem {
  const marketplace = ensureValidMarketplace(apiItem.source);
  const base: Omit<ForSaleListingItemBase, 'collectibleId'> = {
    auctionBidsCount: apiItem.number_of_bids ? Number(apiItem.number_of_bids) : 0,
    endDateTime: apiItem.end_time,
    auctionId: apiItem.auction_id,
    auctionUrl: marketplaceHelpers.makeDirectItemUrl({
      url: apiItem.auction_url,
      marketplace,
      listingType: normalizeListingType(apiItem.listing_type),
    }),
    bestOfferEnabled: apiItem.best_offer_enabled,
    buyItNowAvailable: apiItem.buy_it_now_available,
    buyItNowCurrency: apiItem.buy_it_now_currency,
    buyItNowPrice: apiItem.buy_it_now_price,
    collectibleType,
    imageUrl: apiItem.image_url,
    listingId: apiItem.id,
    listingType: apiItem.listing_type,
    offerPrice: apiItem.offer_price ? Number(apiItem.offer_price) : null,
    offerPriceMessage: apiItem.offer_price_message,
    sellingPrice: apiItem.selling_price,
    title: apiItem.title,
    totalItemListingsCount: Number(apiItem.total_count),
    sellerInfo: getSellerInfo(apiItem.source, null),
    priceChangeAmount: apiItem.price_change_amount,
    priceChangePercentage: apiItem.price_change_percentage,
    lastSale: apiItem.last_sale,
  };

  switch (collectibleType) {
    case 'sports-card': {
      const sportsCardApiItem = apiItem as ApiForSaleListingItemSportsCards;
      const result: ForSaleListingItemSportsCard = {
        ...base,
        collectibleId: sportsCardApiItem.card_id,
        playerName: sportsCardApiItem.player_name,
        setName: sportsCardApiItem.set_name,
        setYear: Number(sportsCardApiItem.set_year),
      };
      return result;
    }
    case 'sealed-wax-card': {
      const sportsCardApiItem = apiItem as ApiForSaleListingItemSealedWax;
      const result: ForSaleListingItemSealedWax = {
        ...base,
        collectibleId: sportsCardApiItem.sealed_wax_id,
        setName: sportsCardApiItem.set_name,
        setYear: Number(sportsCardApiItem.set_year),
      };
      return result;
    }
  }
}

import { useMemo } from 'react';
import clsx from 'clsx';
import { ViewToggles, ViewToggleItem } from '@sportscardinvestor/sci-ui/components/buttons/view-toggles';
import { CollectibleType } from '@sportscardinvestor/schemas';
import { collectibleNames } from '../collectibles/constants';
import { UnifiedSearchResultsType } from './types';

export interface SearchResultsTypeTabsToggleProps {
  searchResultsType: UnifiedSearchResultsType;
  onSearchResultsTypeChange: (searchResultsType: UnifiedSearchResultsType) => void;
  className?: string;
  collectibleType: CollectibleType;
}

export function SearchResultsTypeTabsToggle({
  onSearchResultsTypeChange,
  searchResultsType,
  className,
  collectibleType,
}: SearchResultsTypeTabsToggleProps) {
  const items = useMemo<ViewToggleItem<UnifiedSearchResultsType>[]>(
    () => [
      {
        label: `Featured ${collectibleNames[collectibleType].shortPlural}`,
        value: 'featured',
      },
      {
        label: 'Sales History',
        value: 'salesHistory',
      },
    ],
    [collectibleType]
  );
  return (
    <div className={clsx(className, 'flex items-end justify-start border-b-2 border-lightFg')}>
      <ViewToggles
        activeItem={searchResultsType}
        onActiveItemChange={onSearchResultsTypeChange}
        items={items}
        size="medium"
        variant="mm-view-toggle"
      />
    </div>
  );
}

import {
  faBell,
  faChartColumn,
  faChartLineUp,
  faHouse,
  faRectangleVerticalHistory,
  faTag,
  faWavePulse,
  faFileChartColumn,
  faScrewdriverWrench,
  faFileExport,
  faFileImport,
  faFileInvoiceDollar,
  faCardsBlank,
} from '@fortawesome/pro-solid-svg-icons';
import { MembershipTier } from '../auth/types';
import { NavigationMenuItemConfig } from '../../sci-ui-components/navigationMenus/types';
import CollectionExport from '../collection/CollectionExport/CollectionExport';
import { Route } from './constants';

const navigationConfig: NavigationMenuItemConfig<Route, MembershipTier>[] = [
  {
    id: 'dashboard',
    route: Route.Dashboard,
    icon: faHouse,
    label: 'Dashboard',
  },
  {
    id: 'collections-menu',
    route: Route.Collections,
    icon: faRectangleVerticalHistory,
    label: 'Collections',
    children: [
      {
        id: 'my-collection',
        route: Route.Collections,
        icon: faRectangleVerticalHistory,
        label: 'My Collection',
      },
      {
        id: 'collections-stats-movements',
        route: Route.CollectionsStatsMovements,
        icon: faChartLineUp,
        label: 'Collection Movements',
        collectibleTypes: ['sports-card'],
      },
      {
        id: 'collection-matches',
        route: Route.CollectionMatches,
        icon: faCardsBlank,
        label: 'Sync Manual',
        collectibleTypes: ['sports-card', 'sealed-wax-card'],
      },
      {
        id: 'mass-collection-import',
        route: Route.MassImport,
        icon: faFileImport,
        label: 'Collection Import',
        collectibleTypes: ['sports-card'],
        membershipTierRequirementsForCollectibles: [
          {
            collectibleTypes: ['sealed-wax-card'],
            membershipTierRequirement: 'premium',
          },
        ],
      },
      {
        id: 'mass-collection-export',
        icon: faFileExport,
        label: 'Collection Export',
        Wrapper: CollectionExport,
      },
    ],
  },
  {
    id: 'charts-search',
    route: Route.ChartsSearch,
    alternateRoutes: [Route.Charts],
    icon: faChartColumn,
    label: ['Featured', 'Charts'],
  },
  {
    id: 'sales-history',
    route: Route.SalesHistory,
    icon: faFileInvoiceDollar,
    label: ['Sales', 'History'],
  },
  {
    id: 'stats-movements',
    route: Route.StatsMovements,
    icon: faChartLineUp,
    label: 'Movements',
  },
  {
    id: 'deals',
    route: Route.Deals,
    icon: faTag,
    label: 'Deals',
    collectibleTypes: ['sports-card'],
  },
  {
    id: 'market-pulse',
    route: Route.MarketPulse,
    icon: faWavePulse,
    label: 'Market Pulse',
    collectibleTypes: ['sports-card'],
  },
  {
    id: 'ratios',
    route: Route.Ratios,
    icon: faFileChartColumn,
    label: ['Intelligence', 'Reports'],
    collectibleTypes: ['sports-card'],
    membershipTierRequirement: 'unlimited',
  },
  {
    id: 'price-alerts',
    route: Route.Alerts,
    icon: faBell,
    label: 'Alerts',
  },
  {
    id: 'admin',
    route: Route.Admin,
    icon: faScrewdriverWrench,
    label: 'Admin',
    adminOnly: true,
  },
];

export default navigationConfig;

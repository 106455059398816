import { ApiPriceAlert, ApiSealedWaxPriceAlert, ApiSportsCardPriceAlert } from 'services/sciApi/types';
import {
  CollectibleType,
  isSealedWaxCardCollectibleType,
  isSportsCardCollectibleType,
} from 'sci-ui-components/types/collectibleType';
import { CollectibleHistoricalStats } from 'sci-ui-components/types/collectibleHistoricalStats';
import collectibleHistoricalStatsFromApi from 'services/sciApi/stats/transformers/collectibleHistoricalStatsFromApi';

interface Params {
  collectibleType: CollectibleType;
  apiItem: ApiPriceAlert;
}

export function historicalStatsFromAlertsApi({ collectibleType, apiItem }: Params): CollectibleHistoricalStats {
  if (isSportsCardCollectibleType(collectibleType)) {
    const apiHistoricalStats = (apiItem as ApiSportsCardPriceAlert).card.historical_stats;
    return collectibleHistoricalStatsFromApi(apiHistoricalStats, collectibleType);
  }

  if (isSealedWaxCardCollectibleType(collectibleType)) {
    const apiHistoricalStats = (apiItem as ApiSealedWaxPriceAlert).sealed_wax.sealed_wax_stats;
    return collectibleHistoricalStatsFromApi(apiHistoricalStats, collectibleType);
  }

  throw new Error('Unable to retrieve historical stats');
}

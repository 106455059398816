import { AlertThreshold } from './../../../../sci-ui-components/types/priceAlert';
import { ApiComparison } from './../../types';

export function thresholdFromApiComparison(comparison: ApiComparison): AlertThreshold {
  if (comparison === '>') return 'Above';
  if (comparison === '<') return 'Below';

  throw new TypeError('Comparison expected to be: > or <');
}

export function apiComparisonFromThreshold(threshold: AlertThreshold): ApiComparison {
  if (threshold === 'Above') return '>';
  else return '<';
}

import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { NavigationMenuItem, NavigationMenuItemConfig } from '../../sci-ui-components/navigationMenus/types';
import { CollectibleType } from '../../sci-ui-components/types/collectibleType';
import { collectibleNames } from '../../sci-ui-components/collectibles/constants';
import useUser, { MembershipTier, User } from '../user/useUser';
import { isUserAtLeastOfMembershipTier } from '../user/utils';
import { formatList } from 'sci-ui-components/utils/formatList';

export default function useNavigationMenuItems<TRoute extends string>(
  navigationConfig: NavigationMenuItemConfig<TRoute, MembershipTier>[],
  {
    collectibleType,
  }: {
    collectibleType?: CollectibleType;
  } = {}
) {
  const { pathname } = useRouter();
  const { data: user } = useUser();

  const navigationMenuItems = useMemo(
    () =>
      getNavigationMenuItems<TRoute>(navigationConfig, {
        collectibleType,
        pathname,
        user,
      }),
    [collectibleType, pathname, user, navigationConfig]
  );
  return navigationMenuItems;
}

function getNavigationMenuItems<TRoute extends string>(
  navMenuItemConfigs: NavigationMenuItemConfig<TRoute, MembershipTier>[],
  {
    pathname,
    collectibleType,
    isParentDisabled = false,
    parentDisabledReason,
    isSubMenuItem = false,
    user,
  }: {
    pathname: string;
    collectibleType?: CollectibleType;
    isParentDisabled?: boolean;
    parentDisabledReason?: string;
    isSubMenuItem?: boolean;
    user: User | undefined;
  }
): NavigationMenuItem<TRoute>[] {
  return navMenuItemConfigs.reduce<NavigationMenuItem<TRoute>[]>((acc, { children: configChildren, ...itemConfig }) => {
    if (itemConfig.adminOnly && !user?.isAdmin) {
      return acc;
    }
    const collectibleTypeReason = checkCollectibleType({
      itemConfig,
      collectibleType,
    });
    const membershipTierReason = checkMembershipTier({
      itemConfig,
      user,
    });
    const membershipTierAndCollectibleReason = checkMembershipTierRequirementsForCollectibles({
      itemConfig,
      collectibleType,
      user,
    });

    const isDisabled =
      isParentDisabled || !!collectibleTypeReason || !!membershipTierReason || !!membershipTierAndCollectibleReason;

    const disabledReason =
      parentDisabledReason ??
      collectibleTypeReason ??
      membershipTierReason ??
      membershipTierAndCollectibleReason ??
      undefined;

    if (!configChildren?.length) {
      acc.push({
        ...itemConfig,
        isActive: isActiveMenuItem(itemConfig, { pathname }),
        isDisabled,
        disabledReason,
        isSubMenuItem,
      });
    } else {
      const children: NavigationMenuItem<TRoute>[] = getNavigationMenuItems(configChildren, {
        pathname,
        collectibleType,
        isParentDisabled: isDisabled,
        parentDisabledReason: disabledReason,
        isSubMenuItem: true,
        user,
      });
      const hasActiveChildren = children.some((item) => item.isActive);
      acc.push({
        ...itemConfig,
        children,
        isActive: hasActiveChildren || isActiveMenuItem(itemConfig, { pathname }),
        isDisabled,
        disabledReason,
        isSubMenuItem,
      });
    }
    return acc;
  }, []);
}

function checkCollectibleType<TRoute extends string>({
  itemConfig,
  collectibleType,
}: {
  itemConfig: NavigationMenuItemConfig<TRoute, MembershipTier>;
  collectibleType?: CollectibleType;
}): string | null {
  if (!itemConfig.collectibleTypes) {
    return null;
  }
  if (!collectibleType) {
    return `This feature is only available for ${formatList(
      itemConfig.collectibleTypes.map((ct) => collectibleNames[ct].singular)
    )}`;
  }
  if (!itemConfig.collectibleTypes.includes(collectibleType)) {
    return `This feature is not available for ${collectibleNames[collectibleType].singular}`;
  }
  return null;
}

function checkMembershipTier<TRoute extends string>({
  itemConfig,
  user,
}: {
  itemConfig: NavigationMenuItemConfig<TRoute, MembershipTier>;
  user?: User;
}): string | null {
  if (!itemConfig.membershipTierRequirement) {
    return null;
  }
  if (!isUserAtLeastOfMembershipTier(user, itemConfig.membershipTierRequirement)) {
    return `This feature is not available for ${user?.membershipTier} users`;
  }
  return null;
}

function checkMembershipTierRequirementsForCollectibles<TRoute extends string>({
  itemConfig,
  user,
  collectibleType,
}: {
  itemConfig: NavigationMenuItemConfig<TRoute, MembershipTier>;
  user?: User;
  collectibleType?: CollectibleType;
}): string | null {
  if (!itemConfig.membershipTierRequirementsForCollectibles) {
    return null;
  }
  if (!collectibleType) {
    return checkCollectibleType({ itemConfig, collectibleType });
  }
  const requirement = itemConfig.membershipTierRequirementsForCollectibles.find(
    (r) =>
      r.collectibleTypes.includes(collectibleType) && !isUserAtLeastOfMembershipTier(user, r.membershipTierRequirement)
  );
  if (requirement) {
    return `This feature is not available on ${collectibleNames[collectibleType].singular} for ${user?.membershipTier} users`;
  }
  return null;
}

function isActiveMenuItem<TRoute extends string>(
  itemConfig: NavigationMenuItemConfig<TRoute, MembershipTier>,
  {
    pathname,
  }: {
    pathname: string;
  }
): boolean {
  return (
    pathname === itemConfig.route ||
    !!itemConfig.alternateRoutes?.some((route) => pathname === route) ||
    !!itemConfig.routeTemplate?.test(pathname)
  );
}

export function useActiveRouteCollectibleTypes<TRoute extends string>(
  navigationConfig: NavigationMenuItemConfig<TRoute, MembershipTier>[]
) {
  const { pathname } = useRouter();
  const collectibleTypesFromActiveRoute = useMemo<CollectibleType[]>(() => {
    // NOTE: parent and child menu items might both be active, but have different collectibleTypes assigned.
    // NOTE: get all collectible types for active routes (menus and sub-menus)
    const activeItemsCollectibleTypes = getActiveItemsCollectibleTypes(navigationConfig, { pathname });
    // NOTE: get intersection of collectible types for active routes
    const activeCollectibleTypesSet = activeItemsCollectibleTypes.reduce<Set<CollectibleType>>(
      (acc, collectibleTypes, index) => {
        if (index === 0) {
          collectibleTypes.forEach((collectibleType) => acc.add(collectibleType));
        } else {
          collectibleTypes.forEach((collectibleType) => {
            if (!acc.has(collectibleType)) {
              acc.delete(collectibleType);
            }
          });
        }
        return acc;
      },
      new Set()
    );
    return Array.from(activeCollectibleTypesSet);
  }, [pathname, navigationConfig]);
  return collectibleTypesFromActiveRoute;
}

function getActiveItemsCollectibleTypes<TRoute extends string>(
  navItems: NavigationMenuItemConfig<TRoute, MembershipTier>[],
  { pathname }: { pathname: string }
) {
  return navItems.reduce<CollectibleType[][]>((acc, item) => {
    const collectibleTypes = item?.collectibleTypes;
    if (isActiveMenuItem(item, { pathname }) && collectibleTypes?.length) {
      acc.push(collectibleTypes);
    }
    if (item.children?.length) {
      acc.push(...getActiveItemsCollectibleTypes(item.children, { pathname }));
    }
    return acc;
  }, []);
}

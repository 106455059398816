import clsx from 'clsx';
import { Column } from '../types';

import classes from './IndicatorColumn.module.scss';

export default function createIndicatorColumn<
  TRowData extends object,
  TRowKey extends string | number = number,
  TSortKey extends string = string,
  TFilters extends { [key: string]: any[] } = { [key: string]: any[] }
>({
  key = 'indicator',
  groupId,
  getValue,
  ...other
}: Omit<Column<TRowData, TRowKey, TSortKey, TFilters>, 'renderData' | 'renderTitle' | 'sortKey' | 'key' | 'width'> & {
  getValue: (rowData: TRowData) => number | null | undefined | (number | null | undefined)[];
  key?: string;
}): Column<TRowData, TRowKey, TSortKey, TFilters> {
  return {
    ...other,
    key,
    width: '8px',
    renderData: (rowData) => {
      const value = getValue(rowData);

      return <div className={clsx(classes.indicator, getIndicatorClasses(value))} />;
    },
    groupId,
  };
}

function getIndicatorClasses(value: number | (number | null | undefined)[] | null | undefined) {
  const isFullPositive = Array.isArray(value) ? value.every(checkIfPositive) : checkIfPositive(value);
  const isFullNegative = Array.isArray(value) ? value.every(checkIfNegative) : checkIfNegative(value);
  const isFullNeutral = Array.isArray(value) ? value.every(checkIfNeutral) : checkIfNeutral(value);
  const isPartialNegative = Array.isArray(value) ? value.some(checkIfNegative) : false;
  const isPartialPositive = Array.isArray(value) ? value.some(checkIfPositive) : false;
  const isPartialNeutral = Array.isArray(value) ? value.some(checkIfNeutral) : false;
  const isPositiveNeutralNegative = isPartialPositive && isPartialNeutral && isPartialNegative;
  const isPositiveNeutral = isPartialPositive && isPartialNeutral && !isPartialNegative;
  const isPositiveNegative = isPartialPositive && !isPartialNeutral && isPartialNegative;
  const isNeutralNegative = !isPartialPositive && isPartialNeutral && isPartialNegative;

  if (isFullPositive) return classes.positive;
  if (isFullNegative) return classes.negative;
  if (isFullNeutral) return classes.neutral;
  if (isPositiveNeutralNegative) classes.positiveNeutralNegative;
  if (isPositiveNeutral) return classes.positiveNeutral;
  if (isPositiveNegative) return classes.positiveNegative;
  if (isNeutralNegative) return classes.neutralNegative;

  return classes.neutral;
}

function checkIfPositive(v: number | null | undefined) {
  return typeof v === 'number' && v > 0;
}

function checkIfNegative(v: number | null | undefined) {
  return typeof v === 'number' && v < 0;
}

function checkIfNeutral(v: number | null | undefined) {
  return typeof v !== 'number' || (typeof v === 'number' && v === 0);
}

import React, { ReactNode, useMemo } from 'react';
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';
import { useRouter } from 'next/router';

import navigationConfig from '../features/app/navigationConfig';
import { NavigationMenuItemConfig } from '../sci-ui-components/navigationMenus/types';
import { Route } from '../features/app/constants';
import { isUserAtLeastOfMembershipTier } from '../features/user/utils';
import useUser, { MembershipTier } from '../features/user/useUser';
import InfoPageWrapper from '../sci-ui-components/InfoPageWrapper/InfoPageWrapper';

const flatRoutes = (navItems: NavigationMenuItemConfig<Route, MembershipTier>[]) => {
  return navItems.reduce<NavigationMenuItemConfig<Route, MembershipTier>[]>((acc, item) => {
    acc.push(item);
    if (item.children?.length) {
      acc.push(...flatRoutes(item.children));
    }
    return acc;
  }, []);
};

export default function PermissionGuard({ children }: { children: ReactNode }) {
  const { pathname } = useRouter();
  const { data: user } = useUser();

  const userCantAccess = useMemo(() => {
    const routerItem = flatRoutes(navigationConfig).find(({ route }) => route === pathname);
    return (
      user &&
      routerItem &&
      !!routerItem.membershipTierRequirement &&
      !isUserAtLeastOfMembershipTier(user, routerItem.membershipTierRequirement)
    );
  }, [pathname, user]);

  if (userCantAccess)
    return (
      <InfoPageWrapper
        message={{ text: `This feature is not available for ${user?.membershipTier} users.`, faIcon: faLockKeyhole }}
      />
    );

  return <>{children}</>;
}

import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import SimpleSelect from '../../../../sci-ui-components/forms/SimpleSelect/SimpleSelect';
import useSavedSearchCategories from '../useSavedSearchCategories';
import { defaultCategoryName } from '../constants';
import useFeatureSavedSearchCategories from '../useFeatureSavedSearchCategories';
import { ApiSavedSearchType } from '../../../../services/sciApi/types';

export default function SavedSearchCategorySelect({
  onChange,
  value,
  id,
  savedSearchType,
  noValueLabel = defaultCategoryName,
  collectibleType,
}: {
  onChange: (categoryId: string | null) => void;
  value: string | null | undefined;
  id?: string;
  noValueLabel?: string;
  collectibleType: CollectibleType | undefined | null;
  savedSearchType?: ApiSavedSearchType;
}) {
  const { data: savedSearchCategories } = useSavedSearchCategories(
    { collectibleType: collectibleType! },
    { enabled: !!collectibleType }
  );

  const { data: featureCategories } = useFeatureSavedSearchCategories({
    collectibleType: collectibleType!,
    savedSearchType: savedSearchType!,
    enabled: !!collectibleType && !!savedSearchType,
  });

  const categories = savedSearchType ? featureCategories.categories : savedSearchCategories;

  return (
    <SimpleSelect
      options={
        categories?.map(({ id, name }) => ({
          value: id,
          label: name ?? '',
        })) ?? []
      }
      value={value ?? null}
      onChange={onChange}
      noValueLabel={!savedSearchType ? noValueLabel : ''}
      id={id}
    />
  );
}

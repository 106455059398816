import { useMemo } from 'react';
import {
  CollectibleType,
  isSealedWaxCardCollectibleType,
  isSportsCardCollectibleType,
} from '../../../sci-ui-components/types/collectibleType';
import { useSports } from '../../sports/useSports';
import usePlayers from '../../players/usePlayers';
import useCardSets from '../../cardSets/useCardSets';
import useCardGrades from '../../cardGrades/useCardGrades';
import useSealedWaxBoxTypes from '../../sealedWaxBoxTypes/useSealedWaxBoxTypes';
import { UntrackedCollectibleSalesSearchExtendedFilters, UntrackedCollectibleSalesSearchFilters } from './types';
import { useSetVariationOptions } from '@/features/setVariations/useSetVariationOptions';

/**
 * Extends filters with looked up entities
 */
export default function useUntrackedCollectibleSalesSearchExtendedFilters({
  filters,
  collectibleType,
}: {
  filters: UntrackedCollectibleSalesSearchFilters;
  collectibleType: CollectibleType;
}): {
  extendedFilters: UntrackedCollectibleSalesSearchExtendedFilters;
  isLoading: boolean;
} {
  const { data: sports, isLoading: isLoadingSports } = useSports();
  const { data: players, isLoading: isLoadingPlayers } = usePlayers();
  const { data: cardSets, isLoading: isLoadingCardSets } = useCardSets();
  const { items: setVariations, isLoading: isLoadingCardSetVariations } = useSetVariationOptions({});
  const { data: cardGrades, isLoading: isLoadingCardGrades } = useCardGrades(
    {},
    {
      enabled: isSportsCardCollectibleType(collectibleType),
    }
  );
  const { data: sealedWaxBoxTypes, isLoading: isLoadingSealedWaxBoxTypes } = useSealedWaxBoxTypes(
    {},
    {
      enabled: isSealedWaxCardCollectibleType(collectibleType),
    }
  );
  const isLoading =
    isLoadingSports ||
    isLoadingPlayers ||
    isLoadingCardSets ||
    isLoadingCardSetVariations ||
    isLoadingCardGrades ||
    isLoadingSealedWaxBoxTypes;

  const extendedFilters = useMemo<UntrackedCollectibleSalesSearchExtendedFilters>(() => {
    const sport = filters?.sportName ? sports?.find(({ name }) => name === filters.sportName) : null;
    const player = filters?.playerName ? players?.find(({ name }) => name === filters.playerName) : null;
    const cardSet = filters?.cardSetName
      ? cardSets?.find(({ displayText }) => displayText === filters.cardSetName)
      : null;
    const cardSetVariation = filters?.cardSetVariationName
      ? setVariations?.find(({ variation_name }) => variation_name === filters.cardSetVariationName)
      : null;
    const sportsCardGrade = filters?.sportsCardGradeName
      ? cardGrades?.find(({ name }) => name === filters.sportsCardGradeName)
      : null;
    const sealedWaxBoxType = filters?.sealedWaxBoxTypeName
      ? sealedWaxBoxTypes?.find(({ name }) => name === filters.sealedWaxBoxTypeName)
      : null;
    return {
      ...filters,
      sport: sport ?? null,
      player: player ?? null,
      cardSet: cardSet ?? null,
      cardSetVariation: cardSetVariation ?? null,
      sportsCardGrade: sportsCardGrade ?? null,
      sealedWaxBoxType: sealedWaxBoxType ?? null,
    };
  }, [filters, sports, players, cardSets, setVariations, cardGrades, sealedWaxBoxTypes]);

  return {
    isLoading,
    extendedFilters,
  };
}

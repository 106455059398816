import { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import SaleDetailsDialog from './SaleDetailsDialog';
import { registerDialog, SaleDetailsDialogProviderProps } from './index';

type State = Partial<SaleDetailsDialogProviderProps> & {
  isOpen: boolean;
};

export default withErrorBoundary(function SaleDetailsDialogProvider({ id }: { id: string }) {
  const [{ isOpen, sale, query, resolve }, setState] = useState<State>({
    isOpen: false,
    sale: null,
    query: '',
  });

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          isOpen: true,
          ...props,
        });
      }),
    [id]
  );

  const handleClose = () => {
    setState((s) => ({ ...s, isOpen: false }));
    resolve?.();
  };

  return <SaleDetailsDialog isOpen={isOpen} onClose={handleClose} sale={sale} query={query || ''} />;
});

import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useRouter } from 'next/router';
import { getItem, setItem } from '../../utils/localStorage';
import { mmApiClient, MmApiInput, MmApiOutput, MmApiQueryOptions, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';
import useAuth from 'features/auth/useAuth';

export type UsePlatformInfoBannersInput = Exclude<MmApiInput['public']['platform']['infoBanners'], void>;
export type UsePlatformInfoBannersOutput = Exclude<MmApiOutput['public']['platform']['infoBanners'], void>;
export type PlatformInfoBanner = UsePlatformInfoBannersOutput['items'][number];

export const usePlatformInfoBannersQueryKey = 'public.platform.infoBanners';
type QueryKey = [typeof usePlatformInfoBannersQueryKey, UsePlatformInfoBannersInput, boolean];

const storageKey = 'dismissed_banners';
type DismissedBanners = Record<string, string[]>;
const globalKey = '/all';

/*
IMPORTANT
Web UI does not have dismiss banner feature but the code should work
*/
export default function usePlatformInfoBanners(
  params: UsePlatformInfoBannersInput,
  options?: MmApiQueryOptions<UsePlatformInfoBannersOutput, QueryKey>
) {
  const { isLoggedIn } = useAuth();
  const { pathname } = useRouter();

  const queryKey: QueryKey = [usePlatformInfoBannersQueryKey, params, isLoggedIn];
  const { isLoading, data } = useAuthenticatedMMAPIQuery(
    queryKey,
    () =>
      isLoggedIn
        ? mmApiClient.private.platform.infoBanners.query(params)
        : mmApiClient.public.platform.infoBanners.query(params),
    { keepPreviousData: true, ...options }
  );

  const { data: dismissed = {}, refetch: refetchDismissedBanners } = useQuery(
    [`dismissed-${usePlatformInfoBannersQueryKey}`],
    async () => {
      const ids = getItem<DismissedBanners>(storageKey, true);
      return ids ?? {};
    }
  );

  const allBanners = useMemo(() => data?.items ?? [], [data?.items]);

  const visibleBanners = useMemo(() => {
    if (isLoading) {
      return [];
    }
    const allDismissedIdsSet = new Set([...(dismissed[pathname] ?? []), ...(dismissed[globalKey] ?? [])]);
    return (
      allBanners?.filter(
        (banner) =>
          !allDismissedIdsSet.has(String(banner.id)) &&
          (banner.url?.includes(pathname) || banner.url?.includes(globalKey)) &&
          // NOTE web doesn't have it's own "prefix" for some reason
          !banner.url?.includes('/mobile-')
      ) ?? []
    );
  }, [allBanners, dismissed, isLoading, pathname]);

  const dismissBanner = useCallback(
    async (id: string) => {
      setItem<DismissedBanners>(storageKey, {
        ...dismissed,
        [pathname]: addToUniqueArray(dismissed[pathname], id),
        [globalKey]: addToUniqueArray(dismissed[globalKey], id),
      });
      refetchDismissedBanners();
    },
    [refetchDismissedBanners, dismissed, pathname]
  );

  useEffect(() => {
    if (!isLoading) {
      // NOTE: delete dismissed banners if they are no longer returned by the API so that they can appear again if they are re-enabled
      const bannerIdsSet = new Set(allBanners.map((banner) => banner.id));
      setItem<DismissedBanners>(storageKey, {
        ...dismissed,
        [pathname]: (dismissed[pathname] ?? []).filter((id) => bannerIdsSet.has(Number(id))),
        [globalKey]: (dismissed[globalKey] ?? []).filter((id) => bannerIdsSet.has(Number(id))),
      });
    }
  }, [dismissed, allBanners, isLoading, pathname]);

  return {
    isLoading,
    banners: visibleBanners,
    dismissBanner,
  };
}

function addToUniqueArray(arr: string[] | undefined, item: string) {
  const set = new Set(arr ?? []);
  set.add(item);
  return Array.from(set);
}

import { ApiPriceAlert, ApiSportsCardPriceAlert, ApiSealedWaxPriceAlert } from 'services/sciApi/types';
import {
  CollectibleType,
  isSportsCardCollectibleType,
  isSealedWaxCardCollectibleType,
} from 'sci-ui-components/types/collectibleType';

interface Params {
  collectibleType: CollectibleType;
  apiItem: ApiPriceAlert;
}

export function queryFromApiAlert({ collectibleType, apiItem }: Params): string {
  if (isSportsCardCollectibleType(collectibleType)) {
    return (apiItem as ApiSportsCardPriceAlert).card.query;
  }

  if (isSealedWaxCardCollectibleType(collectibleType)) {
    return (apiItem as ApiSealedWaxPriceAlert).sealed_wax.query;
  }

  return '';
}

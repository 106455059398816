import { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import FieldLabelBox from 'sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import ModalDialog from 'sci-ui-components/ModalDialog/ModalDialog';
import PriceField from 'sci-ui-components/forms/PriceField/PriceField';
import { AlertThreshold, AddEditPriceAlertResponse } from 'sci-ui-components/types/priceAlert';
import usePriceAlertMutations from 'features/alerts/usePriceAlertMutations';
import TextField from 'sci-ui-components/forms/TextField/TextField';
import PriceThresholdSelect from 'sci-ui-components/alerts/PriceThresholdSelect/PriceThresholdSelect';
import { PriceAlertDialogInfoProps } from 'features/alerts/PriceAlertDialog/types';
import FormFieldsRow from 'sci-ui-components/forms/FormFieldsRow/FormFieldsRow';
import FormSection from 'sci-ui-components/forms/FormSection/FormSection';
import { useCollectibleOld } from 'features/collectibles/useCollectibleOld';
import { makeCollectibleDescription } from 'sci-ui-components/utils/collectibleDescription';
import { Checkbox } from 'sci-ui-components/forms/Checkboxes/Checkboxes';

const formId = 'price-alert-dialog';
const nameFieldId = `${formId}-name`;
const thresholdFieldId = `${formId}-threshold`;
const alertPriceFieldId = `${formId}-alertPrice`;

type FormValues = {
  name?: string;
  threshold?: AlertThreshold;
  alertPrice?: number;
  status?: string;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  name: Yup.string().required('Required'),
  threshold: Yup.mixed().oneOf(['Above', 'Below']).required('Required'),
  alertPrice: Yup.number().required('Required'),
  status: Yup.string().notRequired(),
});

interface Props extends PriceAlertDialogInfoProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (alert: AddEditPriceAlertResponse | unknown) => void;
}

export default function PriceAlertDialog({
  isOpen,
  onClose,
  onSubmit,
  initialValues,
  collectibleId,
  collectibleType = 'sports-card',
  mode,
}: Props) {
  const { data: collectible = null } = useCollectibleOld({ id: collectibleId, collectibleType });
  const { fullDescription } = makeCollectibleDescription(collectible, {
    includeGrade: true,
  });
  const isValidAddMode = collectibleId && collectibleType;
  const isValidEditMode = initialValues;
  const [isAlertActive, setIsAlertActive] = useState(false);

  if (mode === 'Add' && !isValidAddMode) {
    throw new TypeError('collectibleId and collectibleType are required for adding a price alert');
  }

  if (mode === 'Edit' && !isValidEditMode) {
    throw new TypeError('initialValues is required for editing a price alert');
  }

  const { editAlert, createAlert, reactivateAlert } = usePriceAlertMutations();

  const handleReactivateAlert = async (resetForm: () => void) => {
    await reactivateAlert.mutateAsync({
      id: initialValues!.id,
      collectibleType: collectibleType!,
    });
    resetForm();
  };

  const handleSubmit = async (
    { name, threshold, alertPrice }: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ) => {
    const alertItem =
      mode === 'Add'
        ? await createAlert.mutateAsync({
            collectibleId: collectibleId!,
            name: name!,
            threshold: threshold!,
            value: alertPrice!,
            collectibleType: collectibleType!,
          })
        : await editAlert.mutateAsync({
            id: initialValues!.id,
            name: name!,
            threshold: threshold!,
            value: alertPrice!,
            collectibleType: initialValues!.collectibleType,
          });

    onSubmit?.(alertItem);
    onClose();
    setSubmitting(false);
    resetForm();
  };

  if ((mode === 'Edit' && !isValidEditMode) || (mode === 'Add' && !isValidAddMode)) {
    return null;
  }

  return (
    <Formik<FormValues>
      initialValues={mode === 'Edit' ? initialValues! : { name: fullDescription }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ values, handleChange, errors, isSubmitting, resetForm }) => (
        <ModalDialog
          width={720}
          visible={isOpen}
          title={`${mode === 'Add' ? 'Add new' : 'Update'} price alert`}
          okText={mode === 'Add' ? 'Add' : 'Update'}
          onCancel={() => {
            resetForm();
            onClose();
          }}
          cancelText="Cancel"
          okButtonProps={{
            htmlType: 'submit',
            form: formId,
            disabled: isSubmitting,
          }}
          cancelButtonProps={{
            disabled: isSubmitting,
          }}
        >
          <Form id={formId}>
            <FormSection>
              <FieldLabelBox label="Name" fieldId={nameFieldId} error={errors.name}>
                <TextField
                  id={nameFieldId}
                  name="name"
                  value={values.name}
                  onChange={(value, name) => handleChange({ target: { value, name } })}
                />
              </FieldLabelBox>
              <FormFieldsRow>
                <FieldLabelBox label="Threshold" fieldId={thresholdFieldId} error={errors.threshold}>
                  <PriceThresholdSelect
                    onChange={(threshold) => handleChange({ target: { name: 'threshold', value: threshold } })}
                    value={values.threshold ?? null}
                    id={thresholdFieldId}
                  />
                </FieldLabelBox>
                <FieldLabelBox label={`Alert Price`} fieldId={alertPriceFieldId} error={errors.alertPrice}>
                  <PriceField
                    value={values.alertPrice ?? 0}
                    name="alertPrice"
                    onChange={(value, name) => handleChange({ target: { value, name } })}
                    placeholder="Enter price.."
                    min={0}
                    id={alertPriceFieldId}
                  />
                </FieldLabelBox>
              </FormFieldsRow>
              {values.status && values.status !== 'Active' ? (
                <FormFieldsRow>
                  <FieldLabelBox label="Reactivate Alert">
                    <Checkbox
                      checked={isAlertActive}
                      onChange={() => {
                        handleReactivateAlert(resetForm);
                        setIsAlertActive(isAlertActive ? false : true);
                      }}
                    />
                  </FieldLabelBox>
                </FormFieldsRow>
              ) : null}
            </FormSection>
          </Form>
        </ModalDialog>
      )}
    </Formik>
  );
}

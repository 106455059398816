import { ErrorBoundary } from '../../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import { CollectibleDetails } from './CollectibleDetailsFlyout';
import { useShallowFlyoutStore } from './store';

export default function CollectibleDetailsModalProvider() {
  const { onClose, collectibleId, collectibleType, isCustom } = useShallowFlyoutStore(
    'onClose',
    'collectibleId',
    'collectibleType',
    'isCustom'
  );

  return (
    <ErrorBoundary dependencies={[collectibleId, collectibleType, isCustom]}>
      <div className="twp pointer-events-none z-modal fixed top-0 bottom-0 left-0 right-0">
        <div
          className={`w-full h-full backdrop-brightness-0 transition ${
            collectibleId ? 'backdrop-opacity-45 pointer-events-auto' : 'backdrop-opacity-0 pointer-events-none'
          }`}
          role="button"
          onClick={onClose}
        />

        <div
          className={`${
            collectibleId ? 'pointer-events-auto -translate-x-[500px] 2xl:-translate-x-[34vw]' : 'pointer-events-none'
          } absolute top-0 right-[-500px] 2xl:right-[-34vw] h-full z-modal bg-aside shadow-[-6px_0_16px_#0000001f] w-[500px] 2xl:w-[34vw] transform duration-300`}
        >
          <div className="flex h-full pt-[60px]">
            <CollectibleDetails />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

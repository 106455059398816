import { ReactNode } from 'react';
import { CollectibleType } from '../../sci-ui-components/types/collectibleType';
import useCollectibleType from '../collectibles/useCollectibleType';
import useUser, { MembershipTier } from 'features/user/useUser';

/**
 * OnlyIf restricts access to content based on collectible types or membership tiers.
 * Provides optional fallback content if criteria is not met.
 */
export default function OnlyIf({
  collectibleTypes,
  membershipTiers,
  children,
  fallback = null,
  isAdmin,
}: {
  collectibleTypes?: CollectibleType[];
  membershipTiers?: MembershipTier[];
  isAdmin?: boolean;
  children: ReactNode | ReactNode[];
  fallback?: JSX.Element | null;
}) {
  const { collectibleType } = useCollectibleType();
  const { data } = useUser();
  const membershipTier = data?.membershipTier ?? null;

  if (collectibleTypes?.length && !collectibleTypes.includes(collectibleType)) {
    return fallback;
  }

  if (membershipTiers?.length && (!membershipTier || !membershipTiers.includes(membershipTier))) {
    return fallback;
  }

  if (typeof isAdmin !== 'undefined' && isAdmin !== data?.isAdmin) {
    return fallback;
  }

  return <>{children}</>;
}

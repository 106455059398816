import clsx from 'clsx';
import { collectibleNames } from '../../collectibles/constants';
import { CollectibleType } from '../../types/collectibleType';
import formatStatValue from '../../utils/formatStatValue';

import classes from './PlatformStatsSummary.module.scss';
import { PlatformStats } from 'features/platform/usePlatformStats';

export default function PlatformStatsSummary({
  className,
  platformStats,
  collectibleType,
}: {
  className?: string;
  platformStats?: PlatformStats | null;
  collectibleType: CollectibleType;
}) {
  if (!platformStats) {
    return null;
  }
  const collectibleStats = platformStats.stats[collectibleType];
  if (!collectibleStats) {
    return null;
  }
  return (
    <div className={clsx(classes.root, className)}>
      <header className={classes.header}>Database Updates:</header>
      <ul className={classes.list}>
        <PlatformStat
          labelText={`Featured ${collectibleNames[collectibleType].shortPlural}`}
          valueText={formatStatValue({
            value: Number(collectibleStats.totalCollectiblesCount),
            type: 'count',
          })}
        />
        <PlatformStat
          labelText={`Added Last ${platformStats.dateRangeInDays} Days`}
          valueText={formatStatValue({
            value: Number(collectibleStats.collectiblesAddedInRange),
            type: 'count',
          })}
        />
      </ul>
    </div>
  );
}

function PlatformStat({ valueText, labelText }: { valueText: string; labelText: string }) {
  return (
    <li className={classes.stat}>
      <p className={classes.statText}>
        <span className={classes.value}>{valueText}</span> {labelText}
      </p>
    </li>
  );
}

import YoutubeEmbed from './YoutubeEmbed';
import classes from './YoutubeIntroDialog.module.scss';
import ModalDialog from 'sci-ui-components/ModalDialog/ModalDialog';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function YoutubeIntroDialog({ isOpen, onClose }: Props) {
  return (
    <ModalDialog
      className={classes.modalDialogWidth}
      visible={isOpen}
      title={`An intro to MMx`}
      onCancel={onClose}
      footer={null}
    >
      <YoutubeEmbed embedId="zk1glGLsUTk" />
    </ModalDialog>
  );
}

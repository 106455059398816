import { ApiSealedWaxCardSet } from 'services/sciApi/types';
import { SealedWaxCardSet } from 'sci-ui-components/types/priceAlert';

export default function sealedWaxCardSetFromApi(apiSet: ApiSealedWaxCardSet): SealedWaxCardSet {
  return {
    name: apiSet.name,
    sport: apiSet.sport,
    year: apiSet.year,
  };
}

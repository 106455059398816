import { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import EditSavedSearchCategoryDialog from './EditSavedSearchCategoryDialog';
import { registerDialog, EditSavedSearchCategoryDialogProviderProps } from './index';

type State = Partial<EditSavedSearchCategoryDialogProviderProps> & {
  isOpen: boolean;
};

export default withErrorBoundary(function EditSavedSearchCategoryDialogProvider({ id }: { id: string }) {
  const [{ isOpen, resolve, categoryId, initialName, collectibleType = null, savedSearchType }, setState] =
    useState<State>({
      isOpen: false,
    });

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          isOpen: true,
          ...props,
        });
      }),
    [id]
  );

  return (
    <EditSavedSearchCategoryDialog
      isOpen={isOpen}
      categoryId={categoryId!}
      initialName={initialName}
      onClose={() => setState((s) => ({ ...s, isOpen: false }))}
      onCreate={(createdCategory) => resolve?.(createdCategory)}
      savedSearchType={savedSearchType}
      collectibleType={collectibleType}
    />
  );
});

import { ReactNode, useEffect, useMemo } from 'react';
import { TylerModeContextProvider as Provider } from '../../sci-ui-components/0401/useTylerMode';
import useLocalStorageValue from 'hooks/useLocalStorageValue';
import useQueryString from 'hooks/useQueryString';

export function TylerModeContextProvider({ children }: { children: ReactNode }) {
  const { query } = useQueryString();
  const queryValue = query['tyler_mode'];
  const [isEnabled, setIsEnabled] = useLocalStorageValue<boolean>('tyler_mode', true);
  const value = useMemo<[boolean, (v: boolean) => void]>(
    () => [isEnabled ?? false, setIsEnabled],
    [isEnabled, setIsEnabled]
  );
  useEffect(() => {
    if (queryValue === '1') {
      setIsEnabled(true);
    } else if (queryValue === '0') {
      setIsEnabled(false);
    }
  }, [queryValue, setIsEnabled]);

  return <Provider value={value}>{children}</Provider>;
}

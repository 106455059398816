import React, { ChangeEvent, ChangeEventHandler, useState } from 'react';
import { Typography } from 'antd';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import { BugReportInput } from '../HelpMenu/useSubmitBugReport';
import classes from './SupportTicketModal.module.scss';
import Button from 'sci-ui-components/buttons/Button/Button';

export default function ProblemTicketModal({
  title,
  onCancel,
  onProblemFormFilled,
  visible,
}: {
  title: string;
  onCancel: () => void;
  onProblemFormFilled: (problemReport: BugReportInput) => void;
  visible: boolean;
}) {
  const DEFAULT_PROBLEM_DESCRIPTION = '';

  const [problemDescription, setProblemDescription] = useState<string>(DEFAULT_PROBLEM_DESCRIPTION);

  const onProblemDescriptionChange: ChangeEventHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setProblemDescription(event.currentTarget.value);
  };

  const submitProblemReport = async () => {
    onProblemFormFilled({
      problemDescription,
    });
    setProblemDescription(DEFAULT_PROBLEM_DESCRIPTION);
  };

  return (
    <ModalDialog
      className={classes.supportTicketModal}
      title={title}
      onCancel={onCancel}
      okButtonProps={{ className: classes.okButton }}
      visible={visible}
      footer={
        <>
          <Button key="cancel" type="ghost" className={classes.button} onClick={submitProblemReport}>
            Submit bug report
          </Button>
          <br />
        </>
      }
    >
      <Typography.Text>
        We are sorry that you are running into issues with Market Movers!
        <br />
        <br />
        <label className={classes.label}>
          <span>Problem description</span>
          <textarea rows={10} onChange={onProblemDescriptionChange} placeholder="Tell us what's up">
            {problemDescription}
          </textarea>
        </label>
      </Typography.Text>
    </ModalDialog>
  );
}

import { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import AddSavedSearchDialog from './AddSavedSearchDialog';
import { registerDialog, AddSavedSearchDialogProviderProps } from './index';

type State = Partial<AddSavedSearchDialogProviderProps> & {
  isOpen: boolean;
};

export default withErrorBoundary(function AddToCollectionDialogProvider({ id }: { id: string }) {
  const [
    { isOpen, resolve, collectibleIds = null, collectibleType = null, savedSearchType, savedSearchQuery },
    setState,
  ] = useState<State>({
    isOpen: false,
  });

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          isOpen: true,
          ...props,
        });
      }),
    [id]
  );

  return (
    <AddSavedSearchDialog
      isOpen={isOpen}
      collectibleIds={collectibleIds}
      onClose={() => setState((s) => ({ ...s, isOpen: false }))}
      onAdd={() => resolve?.()}
      collectibleType={collectibleType}
      savedSearchType={savedSearchType}
      savedSearchQuery={savedSearchQuery}
    />
  );
});

import { Select as AntdSelect } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { useAvailableVariationsByCollectible } from '../useAvailableVariationsByCollectible';
import { useAvailableBoxTypesByCollectible } from '../useAvailableBoxTypesByCollectible';
import { useAvailableGradesByCollectible } from '../useAvailableGradesByCollectible';
import { useShallowFlyoutStore } from './store';
import { NonCustomSportsCardCollectible, NonCustomSealedWaxCollectible } from '@/sci-ui-components/types/collectible';
import { formatDateInTimeZone } from '@/sci-ui-components/utils/date';
import makeSearchTextRegex from '@/sci-ui-components/utils/escapeSearchText';
import useFormatStatValue from '@/hooks/useFormatStatValue';

export function SportsCardOptions({ sportsCard }: { sportsCard: NonCustomSportsCardCollectible }) {
  const { setCollectibleId } = useShallowFlyoutStore('setCollectibleId');
  const { formatStatValue } = useFormatStatValue();

  const { data: variations } = useAvailableVariationsByCollectible({
    player_id: sportsCard.playerId,
    set_id: sportsCard.cardSetId,
    grade_id: sportsCard.gradeId,
  });

  const variationKey = `${sportsCard.variation.name}_${sportsCard.specificQualifier ?? ''}_${
    sportsCard.setVariation.printRun ?? ''
  }`;

  const variationSelectOptions = variations?.options?.map(
    ({
      print_run,
      specific_qualifier,
      variation_name,
      label,
      cardGrades,
      all_end_avg,
      last_sale_date,
      populationCount,
    }) => ({
      value: `${variation_name}_${specific_qualifier ?? ''}_${print_run ?? ''}`,
      label,
      cardGrades,
      all_end_avg,
      last_sale_date,
      populationCount,
    })
  ) || [
    {
      value: variationKey,
      label: sportsCard.variation.name,
      cardGrades: [],
      all_end_avg: sportsCard.historicalStats?.all.endAvg,
      last_sale_date: sportsCard.historicalStats?.all.lastSale,
      populationCount: undefined,
    },
  ];

  const { data: grades } = useAvailableGradesByCollectible({
    player_id: sportsCard.playerId,
    set_id: sportsCard.cardSetId,
    variation_id: sportsCard.variationId,
    card_number: sportsCard.cardNumber,
    specific_qualifier: sportsCard.specificQualifier,
  });

  const gradeSelectOptions = grades?.options || [
    {
      grade_id: sportsCard.grade.id,
      grade_name: sportsCard.grade.name,
      all_end_avg: sportsCard.historicalStats?.all.endAvg,
      last_sale_date: sportsCard.historicalStats?.all.lastSale,
      population_count: undefined,
    },
  ];

  return (
    <>
      <AntdSelect
        showSearch
        placeholder="Card Variation"
        optionFilterProp="children"
        optionLabelProp="label"
        className="rounded-sm w-full border text-white bg-gray-primary [&>*]:!cursor-pointer [&_.ant-select-selector]:!h-fit [&_.ant-select-selection-item]:!text-wrap [&_.ant-select-arrow]:!h-full [&_.ant-select-arrow]:!top-[6px] [&_.ant-select-arrow]:!w-[32px] [&_.ant-select-arrow]:!-mr-3 [&_.ant-select-arrow]:!bg-white [&_.ant-select-arrow]:!rounded-r-sm [&_.ant-select-arrow]:!border [&_.ant-select-arrow]:!border-gray-primary [&_.ant-select-selector]:!items-center"
        bordered={false}
        suffixIcon={<FontAwesomeIcon icon={faCaretDown} className="text-gray-primary h-[30px] w-[30px]" />}
        value={variationKey}
        filterOption={(input, option) => makeSearchTextRegex(input).test(option?.label?.toString() ?? '')}
        dropdownStyle={{ pointerEvents: 'auto' }}
        onChange={(_, o) => {
          if (!Array.isArray(o)) {
            const newGrade =
              o.opt.cardGrades.find(({ grade_id }: { grade_id: number }) => grade_id === sportsCard.grade.id) ??
              o.opt.cardGrades[0];
            setCollectibleId({ collectibleId: newGrade.card_id });
          }
        }}
      >
        {variationSelectOptions.map((opt) => (
          <AntdSelect.Option key={opt.value} value={opt.value} label={opt.label} opt={opt}>
            <div className="flex flex-row items-center">
              <div className="flex flex-col mr-auto">
                <span className="text-primary font-normal text-wrap">{opt.label}</span>
                {!!opt.populationCount && (
                  <span className="text-xs text-white-bg-gray-muted">(pop: {opt.populationCount})</span>
                )}
              </div>
              <div className="flex flex-col items-center w-[65px]">
                <span className="font-bold">
                  {!!opt.all_end_avg && formatStatValue({ value: opt.all_end_avg, type: 'price' })}
                </span>
                <span className="text-xs text-white-bg-gray-muted">{formatDateInTimeZone(opt.last_sale_date)}</span>
              </div>
            </div>
          </AntdSelect.Option>
        ))}
      </AntdSelect>
      <AntdSelect
        showSearch
        placeholder="Card Grade"
        optionFilterProp="children"
        optionLabelProp="label"
        className="rounded-sm w-full border text-white bg-primary [&>*]:!cursor-pointer"
        bordered={false}
        suffixIcon={
          <FontAwesomeIcon
            icon={faCaretDown}
            className="text-primary h-[30px] w-[30px] -mr-3 bg-white rounded-r-sm border border-primary"
          />
        }
        value={sportsCard.grade.id}
        filterOption={(input, option) => makeSearchTextRegex(input).test(option?.label?.toString() ?? '')}
        dropdownStyle={{ pointerEvents: 'auto' }}
        onChange={(_, o) => {
          if (!Array.isArray(o)) setCollectibleId({ collectibleId: o.opt.card_id });
        }}
      >
        {gradeSelectOptions.map((opt) => (
          <AntdSelect.Option key={opt.grade_id} value={opt.grade_id} label={opt.grade_name} opt={opt}>
            <div className="flex flex-col">
              <div className="flex flex-row items-center gap-2">
                <span className="max-w-[200px] truncate text-primary font-normal mr-auto">{opt.grade_name}</span>{' '}
                <span className="font-bold">
                  {!!opt.all_end_avg && formatStatValue({ value: opt.all_end_avg, type: 'price' })}
                </span>
                <span className="text-xs text-white-bg-gray-muted">{formatDateInTimeZone(opt.last_sale_date)}</span>
              </div>
              {!!opt.population_count && (
                <span className="text-xs text-white-bg-gray-muted">(pop: {opt.population_count})</span>
              )}
            </div>
          </AntdSelect.Option>
        ))}
      </AntdSelect>
    </>
  );
}

export function SealedWaxOptions({ sealedWax }: { sealedWax: NonCustomSealedWaxCollectible }) {
  const { setCollectibleId } = useShallowFlyoutStore('setCollectibleId');
  const { formatStatValue } = useFormatStatValue();

  const { data: boxTypes } = useAvailableBoxTypesByCollectible({
    set_id: sealedWax.cardSetId,
  });

  const boxTypeSelectOptions = boxTypes?.options?.map(
    ({ box_type_name, box_type_id, all_end_avg, last_sale_date, sealed_id }) => ({
      value: box_type_id,
      label: box_type_name,
      all_end_avg,
      last_sale_date,
      sealed_id,
    })
  ) || [
    {
      value: sealedWax.boxType.id,
      label: sealedWax.boxType.name,
      all_end_avg: sealedWax.historicalStats?.all.endAvg,
      last_sale_date: sealedWax.historicalStats?.all.lastSale,
      sealed_id: sealedWax.id,
    },
  ];

  return (
    <AntdSelect
      showSearch
      placeholder="Box Type"
      optionFilterProp="children"
      optionLabelProp="label"
      className="rounded-sm w-full border text-white bg-gray-primary [&>*]:!cursor-pointer"
      bordered={false}
      suffixIcon={
        <FontAwesomeIcon
          icon={faCaretDown}
          className="text-gray-primary h-[30px] w-[30px] -mr-3 bg-white rounded-r-sm border border-gray-primary"
        />
      }
      defaultValue={sealedWax.boxType.id}
      filterOption={(input, option) => makeSearchTextRegex(input).test(option?.label?.toString() ?? '')}
      dropdownStyle={{ pointerEvents: 'auto' }}
      onChange={(_, o) => {
        if (!Array.isArray(o)) {
          setCollectibleId({ collectibleId: o.opt.sealed_id });
        }
      }}
    >
      {boxTypeSelectOptions.map((opt) => (
        <AntdSelect.Option key={opt.value} value={opt.value} label={opt.label} opt={opt}>
          <div className="flex flex-col">
            <div className="flex flex-row items-center gap-2">
              <span className="max-w-[200px] text-primary truncate font-normal mr-auto">{opt.label}</span>{' '}
              <span className="font-bold">
                {!!opt.all_end_avg && formatStatValue({ value: opt.all_end_avg, type: 'price' })}
              </span>
              <span className="text-xs text-white-bg-gray-muted">{formatDateInTimeZone(opt.last_sale_date)}</span>
            </div>
          </div>
        </AntdSelect.Option>
      ))}
    </AntdSelect>
  );
}

import React, { ReactNode, ReactElement } from 'react';
import { Input, InputProps } from 'antd';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

import VerticalTilesList from '../VerticalTilesList/VerticalTilesList';
import useClickedOutside from '../../hooks/useClickedOutside';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import PillToggles from '../buttons/PillToggles/PillToggles';
import classes from './Search.module.scss';
import TrySalesHistoryMessage from './TrySalesHistoryMessage';
import SpellCheckSuggestions from './SpellCheckSuggestions';
import { CollectibleType } from 'sci-ui-components/types/collectibleType';
import { collectibleNames } from 'sci-ui-components/collectibles/constants';

export type QuickSearchType = 'featured' | 'salesHistory';

interface SearchProps<T> {
  id?: string;
  name?: string;
  className?: string;
  onSearch: (value: string | null) => void;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  listItems: T[];
  isLoading: boolean;
  isFetching: boolean;
  searchTerm: string | null;
  renderItem: (item: T, index: number, isExtra: boolean) => ReactElement;
  closeOnClickAway?: boolean;
  searchType?: QuickSearchType;
  onChangeSearchType?: (value: QuickSearchType) => void;
  hasSelectedCards?: boolean;
  searchInputProps?: InputProps;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  listWrapper?: {
    beforeBlock?: ReactNode;
    afterBlock?: ReactNode;
  };
  emptyMessage?: ReactNode;
  onPressEnter?: () => void;
  collectibleType: CollectibleType;
  spellCheckAlternatives?: string[];
  extraItems?: T[];
}

function Search<T>(props: SearchProps<T>) {
  const {
    id,
    name,
    className,
    searchInputProps,
    hasSelectedCards,
    isLoading,
    isFetching,
    listItems,
    searchTerm,
    onSearch,
    renderItem,
    isOpen,
    onOpenChange,
    closeOnClickAway = true,
    listWrapper,
    fetchNextPage,
    hasNextPage,
    emptyMessage,
    searchType = 'featured',
    onChangeSearchType,
    onPressEnter,
    collectibleType,
    spellCheckAlternatives,
    extraItems,
  } = props;

  const reset = (emptySearchTerm?: boolean) => {
    onOpenChange(false);
    if (emptySearchTerm) {
      onSearch(null);
    }
  };

  const handleFocus = () => {
    if (!isOpen) {
      onOpenChange(true);
      onSearch(searchTerm);
    }
  };

  const outsideClickRef = useClickedOutside(closeOnClickAway && isOpen, reset);

  const FeaturedCardsHeader = (
    <div className={classes.featuredCardsHeader} key="featured-cards-header">
      <div>Item</div>
      <div>Price</div>
      <div>Compare</div>
    </div>
  );

  const RawSaleSuggestionHeader = (
    <div className={clsx(classes.featuredCardsHeader, classes.containerDivider)} key="featured-cards-header">
      <div>Results matching fewer words:</div>
    </div>
  );

  return (
    <div ref={outsideClickRef} className={clsx(classes.search, { [classes.searching]: isOpen }, className)}>
      <Input
        {...searchInputProps}
        id={id}
        name={name}
        className={clsx(isOpen && classes.listHasItems)}
        value={searchTerm === null ? undefined : searchTerm}
        onClick={handleFocus} // NOTE: need to have both onClick and onFocus
        onFocus={handleFocus}
        allowClear={{
          clearIcon: (
            <FontAwesomeIcon
              size={'lg'}
              icon={faXmark}
              color={'grey'}
              onClick={(event) => {
                event.stopPropagation();
                onSearch(null);
              }}
            />
          ),
        }}
        onChange={(event) => {
          const value = event.target.value;
          onSearch(value);
        }}
        prefix={<FontAwesomeIcon className={classes.searchIcon} icon={faSearch} />}
        onPressEnter={onPressEnter}
      />
      <div
        id="searchResults"
        className={clsx(classes.searchList, {
          [classes.hasSelectedCards]: hasSelectedCards,
          [classes.open]: isOpen,
        })}
      >
        <SpellCheckSuggestions
          suggestions={listItems.length ? [] : spellCheckAlternatives}
          onSelect={onSearch}
          originalSearchTerm={searchTerm || undefined}
        />
        <PillToggles
          className={classes.searchTypeButton}
          activeClassName={classes.activeSearchTypeButton}
          options={[
            { label: `Featured ${collectibleNames[collectibleType].shortPlural}`, value: 'featured' },
            { label: 'Sales History', value: 'salesHistory' },
          ]}
          onChange={(searchType) => onChangeSearchType?.(searchType)}
          value={searchType}
        />
        {!!listWrapper?.beforeBlock && <div className={classes.beforeBlock}>{listWrapper?.beforeBlock}</div>}
        <VerticalTilesList
          className={classes.list}
          endApproachThreshold={10}
          onApproachingEnd={() => hasNextPage && !extraItems?.length && fetchNextPage()}
          emptyMessage={emptyMessage}
          isLoading={isLoading}
        >
          {[
            ...(searchType === 'featured' ? [FeaturedCardsHeader] : []),
            ...(listItems?.length ? listItems?.map((item, index) => renderItem(item, index, false)) : []),
            ...(extraItems?.length ? [RawSaleSuggestionHeader] : []),
            ...(extraItems?.length ? extraItems?.map((item, index) => renderItem(item, index, true)) : []),
            ...(isLoading
              ? []
              : [
                  <TrySalesHistoryMessage
                    key={'endMessage'}
                    searchText={searchTerm || ''}
                    className={classes.bottomOfListContainer}
                    customTopText={searchType === 'salesHistory' ? 'No Additional Results Found' : undefined}
                    customBottomText={searchType === 'salesHistory' ? '' : undefined}
                    onClick={() => onOpenChange(false)}
                  />,
                ]),
          ]}
        </VerticalTilesList>
        {isFetching && !isLoading && <LoadingSpinner className={classes.spinner} />}
        {listWrapper?.afterBlock}
      </div>
    </div>
  );
}

export default Search;

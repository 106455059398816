import { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import AddOrEditCollectionCategoryDialog from './AddOrEditCollectionCategoryDialog';
import { registerDialog, AddOrEditCollectionCategoryDialogProviderProps } from './index';
import { trackEvent } from '@/features/analytics/trackEvent';

type State = Partial<AddOrEditCollectionCategoryDialogProviderProps> & {
  isOpen: boolean;
};

export default withErrorBoundary(function AddOrEditCollectionCategoryDialogProvider({ id }: { id: string }) {
  const [{ isOpen, resolve, collectibleType, categoryId, name }, setState] = useState<State>({
    isOpen: false,
  });

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          isOpen: true,
          categoryId: null,
          name: '',
          ...props,
        });
        trackEvent({
          eventName: props.categoryId ? 'COLLECTION_CATEGORY_EDIT_STARTED' : 'COLLECTION_CATEGORY_ADD_STARTED',
          collectibleType: props.collectibleType,
        });
      }),
    [id]
  );

  return (
    <AddOrEditCollectionCategoryDialog
      isOpen={isOpen}
      collectibleType={collectibleType!}
      onClose={() => setState((s) => ({ ...s, isOpen: false }))}
      onCreated={(createdCategory) => resolve?.(createdCategory)}
      onEdited={() => resolve?.(null)}
      categoryId={categoryId}
      name={name}
    />
  );
});

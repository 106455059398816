import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import SimpleCollapsible from '../SimpleCollapsible/SimpleCollapsible';
import { getTermsToHighlightFromRawStrings } from '../../utils/collectibleComparisonUtils';
import classes from './SpellCheckSuggestions.module.scss';

const SpellCheckSuggestions = ({
  suggestions,
  originalSearchTerm,
  onSelect,
}: {
  suggestions?: string[];
  originalSearchTerm?: string;
  onSelect: (term: string) => void;
}) => {
  const [searchTermStack, setSearchTermStack] = useState<string[]>([]);

  const pushStack = (term: string) => setSearchTermStack([...searchTermStack, term]);

  const popStack = () => {
    const stackCopy = [...searchTermStack];
    stackCopy.splice(-1);
    setSearchTermStack(stackCopy);
  };

  const [historicalTerms, setHistoricalTerms] = useState<string[]>([]);

  useEffect(() => {
    if (suggestions?.length) {
      return setHistoricalTerms(suggestions);
    }
  }, [suggestions]);

  const getHTMLTerms = (suggestion: string) => {
    if (originalSearchTerm) {
      const termsToHighlight = new Set(getTermsToHighlightFromRawStrings(suggestion, originalSearchTerm));
      return (
        <>
          {suggestion.split(' ').map((term) =>
            termsToHighlight.has(term.toLowerCase()) ? (
              <b key={term} className={classes.highlightTerm}>
                {term}{' '}
              </b>
            ) : (
              <span key={term}>{term} </span>
            )
          )}
        </>
      );
    } else {
      return <span>{suggestion}</span>;
    }
  };

  const handleSelect = (term: string, shouldPop = false) => {
    if (shouldPop) {
      popStack();
    } else if (originalSearchTerm) {
      pushStack(originalSearchTerm);
    }
    onSelect(term);
  };

  const SubHeader = searchTermStack.length ? (
    <div
      onClick={() => handleSelect(searchTermStack[searchTermStack.length - 1], true)}
      className={clsx([classes.smallLabel, classes.interactiveLabel])}
    >
      <FontAwesomeIcon className={classes.backIcon} icon={faArrowLeft} size={'sm'} />
      Back to &quot;{searchTermStack[searchTermStack.length - 1]}&quot;
    </div>
  ) : (
    <div className={classes.smallLabel}>Did You Mean?</div>
  );

  useEffect(() => {
    if (!originalSearchTerm) {
      setSearchTermStack([]);
    }
  }, [originalSearchTerm]);

  const formattedSuggestions = useMemo<string[]>(() => {
    if (historicalTerms.includes(originalSearchTerm || '')) {
      return [];
    }
    return suggestions || [];
  }, [suggestions, historicalTerms, originalSearchTerm]);

  return (
    <SimpleCollapsible isExpanded={!!formattedSuggestions?.length || !!searchTermStack.length}>
      <div className={classes.mainContainer}>
        {SubHeader}
        {suggestions?.map((text) => (
          <div className={classes.suggestion} onClick={() => handleSelect(text)} key={`suggestion-${text}`}>
            {getHTMLTerms(text)}
          </div>
        ))}
      </div>
    </SimpleCollapsible>
  );
};

export default SpellCheckSuggestions;

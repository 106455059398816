import { ReactNode } from 'react';
import { User } from './useUser';
import { showError } from 'services/toaster';
import { MembershipTierLimitType } from 'features/user/utils';

const MessageTemplates: Partial<Record<MembershipTierLimitType, (user: User | undefined) => ReactNode>> = {
  alerts: () => {
    return (
      <div>
        You&apos;ve reached your plan&apo;s limit for alerts.{' '}
        <a href="https://www.sportscardinvestor.com/account/?action=subscriptions" target="_blank" rel="noreferrer">
          Remove an alert or upgrade your plan to add more alerts.
        </a>
      </div>
    );
  },
  // TODO: add more of these / consolidate from rest of app
};

function displayMessage(message: ReactNode) {
  showError({
    description: message,
  });
}

export function displayLimitReachedMessageForUserAndCategory(
  user: User | undefined,
  membershipTierLimitType: MembershipTierLimitType
) {
  return displayMessage(MessageTemplates[membershipTierLimitType]?.(user));
}

import React, { useCallback, useMemo } from 'react';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import useCustomFeaturedCollectiblePriceMutations from '../useCustomFeaturedCollectiblePriceMutations';
import { useEditFeaturedCollectibleCustomValueDialogStore } from './store';
import {
  EditFeaturedCollectibleCustomValueForm,
  EditFeaturedCollectibleCustomValueFormInitialValues,
  OnEditFeaturedCollectibleCustomValueFormSubmitFn,
} from './EditFeaturedCollectibleCustomValueForm';
import { collectibleNames } from 'sci-ui-components/collectibles/constants';
import { useCollectible } from '@/features/collectibles/useCollectible';
import useCurrencyFormatter from '@/hooks/useCurrencyFormatter';
import { CustomPrice } from '@/features/collectibles/CollectibleItem';
import { trackEvent } from '@/features/analytics/trackEvent';

export default function EditFeaturedCollectibleCustomValueDialog() {
  const { close, collectibleId, collectibleType, currentValue, currentValueDate } =
    useEditFeaturedCollectibleCustomValueDialogStore();
  const { updateCollectibleCustomPrice } = useCustomFeaturedCollectiblePriceMutations();
  const { data: collectible, isLoading } = useCollectible(
    {
      collectibleId: collectibleId!,
      collectibleType,
    },
    {
      enabled: !!collectibleId,
    }
  );
  const { convertPriceToUSD, convertPriceFromUSD } = useCurrencyFormatter();

  const handleSubmit = useCallback<OnEditFeaturedCollectibleCustomValueFormSubmitFn>(
    ({ currentPriceInDisplayCurency }) => {
      if (collectible) {
        updateCollectibleCustomPrice.mutate(
          {
            collectibleId: collectible.id,
            collectibleType: collectible.collectibleType,
            price: convertPriceToUSD({ value: currentPriceInDisplayCurency }) ?? 0,
          },
          {
            onSuccess: () => {
              trackEvent({
                eventName: 'COLLECTION_EDIT_FEATURED_PRICE_COMPLETED',
                collectibleType: collectible.collectibleType,
              });
              close();
            },
            onError: () => {
              trackEvent({
                eventName: 'COLLECTION_EDIT_FEATURED_PRICE_FAILED',
                collectibleType: collectible.collectibleType,
              });
            },
          }
        );
      }
    },
    [collectible, convertPriceToUSD, updateCollectibleCustomPrice, close]
  );
  const handleCancel = useCallback(() => {
    trackEvent({
      eventName: 'COLLECTION_EDIT_FEATURED_PRICE_CANCELLED',
      collectibleType,
    });
    close();
  }, [close, collectibleType]);
  const currentValueInDisplayCurrency = useMemo(
    () => convertPriceFromUSD({ value: currentValue }),
    [currentValue, convertPriceFromUSD]
  );
  const initialValues = useMemo<EditFeaturedCollectibleCustomValueFormInitialValues>(
    () => ({
      currentPriceInDisplayCurency: currentValueInDisplayCurrency,
    }),
    [currentValueInDisplayCurrency]
  );
  const customPrice = useMemo<CustomPrice>(
    () => ({
      currentPrice: currentValue,
      currentPriceDate: currentValueDate,
    }),
    [currentValue, currentValueDate]
  );

  return (
    <ModalDialog
      open={!!collectibleId}
      title={
        <>
          Featured {collectibleNames[collectibleType].shortSingular}
          <br /> Custom Value
        </>
      }
      onCancel={handleCancel}
      footer={null}
    >
      <EditFeaturedCollectibleCustomValueForm
        collectibleType={collectibleType}
        collectible={collectible ?? null}
        customPrice={customPrice}
        isLoadingCollectible={isLoading}
        isLoading={updateCollectibleCustomPrice.isLoading}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        initialValues={initialValues}
      />
    </ModalDialog>
  );
}

import React from 'react';
import NextLink from 'next/link';
import qs from 'qs';
import { Route } from '../../features/app/constants';
import { collectibleNames } from '../collectibles/constants';
import useCollectibleType from '../../features/collectibles/useCollectibleType';
import NoticeText from '../NoticeText/NoticeText';
import classes from './TrySalesHistoryMessage.module.scss';

export default function TrySalesHistoryMessage({
  onClick,
  searchText = '',
  className,
  customTopText,
  customBottomText,
  noTextWrapper,
}: {
  onClick?: () => void;
  searchText?: string;
  className?: string;
  customTopText?: string;
  customBottomText?: string;
  noTextWrapper?: boolean;
}) {
  const { collectibleType } = useCollectibleType();

  const href = `${Route.SalesHistory}${qs.stringify(
    {
      search: searchText,
    },
    {
      addQueryPrefix: true,
      arrayFormat: 'repeat',
    }
  )}`;

  const content = (
    <>
      {' '}
      {customTopText !== undefined
        ? customTopText
        : `Looking for another ${collectibleNames[collectibleType].shortSingular}?`}
      <br />
      <NextLink href={href} className={classes.link} onClick={onClick}>
        {customBottomText !== undefined ? customBottomText : 'Try our Sales History Search'}
      </NextLink>
    </>
  );

  return <span className={className}>{noTextWrapper ? content : <NoticeText>{content}</NoticeText>}</span>;
}

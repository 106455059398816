import { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import UserPreferencesModal from './UserPreferencesModal';
import { registerDialog, UserPreferencesModalProviderProps } from './index';

type State = Partial<UserPreferencesModalProviderProps> & {
  isOpen: boolean;
};

export default withErrorBoundary(function UserPreferencesModalProvider({ id }: { id: string }) {
  const [{ isOpen }, setState] = useState<State>({
    isOpen: false,
  });

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          ...props,
          isOpen: true,
        });
      }),
    [id]
  );

  return <UserPreferencesModal isOpen={isOpen} onClose={() => setState((s) => ({ ...s, isOpen: false }))} />;
});

import clsx from 'clsx';
import { InfoBannerItem } from '@sportscardinvestor/schemas';
import { CollectibleType } from '../types/collectibleType';
import PlatformStatsSummary from './PlatformStatsSummary/PlatformStatsSummary';
import PlatformNotifications from './PlatformNotifications/PlatformNotifications';
import classes from './InfoBar.module.scss';
import { PlatformStats } from 'features/platform/usePlatformStats';

export default function InfoBar({
  className,
  platformStats,
  collectibleType,
  banners,
}: {
  className?: string;
  platformStats?: PlatformStats | null;
  collectibleType: CollectibleType;
  banners: InfoBannerItem[];
}) {
  return (
    <div className={clsx(classes.root, className)}>
      <PlatformNotifications className={classes.notifications} banners={banners} collectibleType={collectibleType} />
      <PlatformStatsSummary className={classes.stats} platformStats={platformStats} collectibleType={collectibleType} />
    </div>
  );
}

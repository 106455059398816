import { useEffect, useState } from 'react';

export interface BrowserEnvironmentState {
  [key: string | symbol]: string | number | ConnectionType | undefined;
  connectionType: ConnectionType | undefined;
  deviceMemory: number;
}

/**
 * Hook to sync state with local storage
 */
export default function useBrowserEnvironmentState() {
  const [state, setState] = useState<BrowserEnvironmentState>({
    connectionType: navigator.connection?.type,
    deviceMemory: (navigator as any).deviceMemory,
  });
  const [connectionType, setConnectionType] = useState<ConnectionType | undefined>();

  function updateConnectionStatus() {
    if (state.connectionType == navigator.connection?.type) return;

    console.debug(`Connection type changed from ${state.connectionType} to ${navigator.connection?.type}`);
    setConnectionType(navigator.connection?.type);
  }

  useEffect(() => {
    setState((s) => ({ ...s, connectionType: connectionType as ConnectionType }));
  }, [connectionType]);

  navigator?.connection?.addEventListener('change', updateConnectionStatus);

  return {
    data: state as BrowserEnvironmentState,
  };
}

import { useMemo } from 'react';
import { Select } from 'antd';
import clsx from 'clsx';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import classes from './CurrencySelector.module.scss';
import SubSectionHeader from 'sci-ui-components/SubSectionHeader/SubSectionHeader';
import useListExchangeRates from 'features/exchangeRates/useListExchangeRates';
import useUserSettings, { currencyUpdateSuccesMessage } from 'features/settings/useUserSettings';

function getCurrencyCodeWithFlag({ currencyCode }: { currencyCode: string }) {
  return `${getUnicodeFlagIcon(currencyCode)} ${currencyCode}`;
}

interface CurrencySelectorProps {
  onChange?: (newCurrencyCode: string) => void;
  shouldSaveChangesAutomatically?: boolean;
  hasHeader?: boolean;
  selectorClassName?: string;
}

export default function CurrencySelector({
  onChange,
  hasHeader = true,
  shouldSaveChangesAutomatically,
  selectorClassName,
}: CurrencySelectorProps) {
  const { data: userSettingsData, updateMutation, isLoading: isLoadingUserSettings } = useUserSettings();
  const selectedCurrencyCode = userSettingsData?.globalSettings?.preferences?.currencyCode;

  const { data: exchangeRatesData, isLoading: isLoadingExchangeRates } = useListExchangeRates({});

  const isLoading = isLoadingUserSettings || isLoadingExchangeRates;

  const currencyCodes = useMemo(() => {
    return Array.from(new Set(['USD', ...(exchangeRatesData?.data?.map(({ currency_code }) => currency_code) ?? [])]));
  }, [exchangeRatesData]);

  return (
    <div className={classes.currencyDropdownContainer}>
      {hasHeader && <SubSectionHeader className={classes.currencyHeader}>CURRENCY</SubSectionHeader>}
      <Select
        defaultValue={getCurrencyCodeWithFlag({ currencyCode: 'USD' })}
        value={selectedCurrencyCode ? getCurrencyCodeWithFlag({ currencyCode: selectedCurrencyCode }) : undefined}
        loading={isLoading}
        className={clsx(classes.currencyDropdown, selectorClassName)}
        onChange={(newCurrencyCode) => {
          onChange?.(newCurrencyCode);
          shouldSaveChangesAutomatically &&
            updateMutation.mutate({
              globalSettings: {
                preferences: {
                  currencyCode: newCurrencyCode,
                },
              },
              successMessage: currencyUpdateSuccesMessage,
            });
        }}
      >
        {currencyCodes.map((currencyCode) => (
          <Select.Option className={classes.currencyOption} key={currencyCode} value={currencyCode}>
            <p>{getCurrencyCodeWithFlag({ currencyCode })}</p>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export function CurrencyDisclaimer() {
  return (
    <p className={classes.currencyDisclaimer}>
      Please note that we store all values in US Dollars (USD). The currency conversation feature simply provides a
      conversion display based on the foreign exchange rate for that specific currency for the past 24 hour period.
      Historical exchange rates and values are not stored in the system.
    </p>
  );
}

import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import clsx from 'clsx';
import { formatISOIfExists } from '../../utils/date';
import classes from './DateSelector.module.scss';

// Using dateFns as a date provider introduces a bug which basically makes manually typing a date in a date picker impossible.
// For this, we had to revert back to using moment for this particular scenario, since when using the default moment configuration, the issue mentioned above does not happen.

export type DateSelectorProps = {
  className?: string;
  value: string | null;
  disabled?: boolean;
  onChange?: (value: string | null, name: string | undefined, date: Date | null) => void;
  isDateDisabled?: (date: Date) => boolean;
  name?: string;
  id?: string;
};

export default React.forwardRef<any, DateSelectorProps>(function DateSelector(
  { onChange, value, className, disabled, isDateDisabled, name, id }: DateSelectorProps,
  ref
) {
  return (
    <DatePicker
      ref={ref}
      id={id}
      onChange={(date) => {
        const maybeMomentDate = date || moment();
        onChange?.(formatISOIfExists(maybeMomentDate.toDate()), name, maybeMomentDate.toDate());
      }}
      name={name}
      format="M/D/yyyy"
      value={moment(value)}
      disabledDate={(date) => !!isDateDisabled?.(date.toDate())}
      disabled={disabled}
      className={clsx(classes.dateSelector, className)}
    />
  );
});

import { ReactNode } from 'react';
import clsx from 'clsx';
import { TitleProps } from '../types';
import DefaultCellPadding from './DefaultCellPadding';
import FilterButton from './Filter/FilterButton';

import classes from './DefaultTitle.module.scss';
import SortButton from 'sci-ui-components/buttons/SortButton/SortButton';

export type Justify = 'start' | 'end' | 'center';

export default function DefaultTitle<
  TRowData extends object,
  TRowKey extends string | number = number,
  TSortKey extends string = string,
  TFilters extends { [key: string]: any[] } = { [key: string]: any[] }
>({
  children,
  justify = 'center',
  isSortable,
  sortDirection,
  onSortChange,
  sortKey,
  filterKey,
  filterOptions,
  filters,
  onFiltersChange,
  isSingleSelectFilter,
}: TitleProps<TRowData, TRowKey, TSortKey, TFilters> & {
  children: ReactNode;
  justify?: Justify;
}) {
  const hasFilter = !!filterKey && !!filterOptions?.length && !!onFiltersChange;

  return (
    <DefaultCellPadding
      className={clsx(classes.root, {
        [classes.justifyStart]: justify === 'start',
        [classes.justifyEnd]: justify === 'end',
        [classes.justifyCenter]: justify === 'center',
      })}
    >
      <span>{children}</span>
      {isSortable && (
        <SortButton
          onSortChange={onSortChange}
          sortDirection={sortDirection}
          sortKey={sortKey}
          className={classes.sortButton}
          isSorted={!!sortDirection}
        />
      )}
      {hasFilter && (
        <FilterButton<TFilters>
          filterKey={filterKey}
          filterOptions={filterOptions}
          filters={filters}
          onFiltersChange={onFiltersChange}
          isSingleSelect={isSingleSelectFilter}
        />
      )}
    </DefaultCellPadding>
  );
}

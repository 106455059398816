import { useMemo } from 'react';
import { MmApiInfiniteQueryOptions, MmApiQueryOptions } from '../../services/mmApiX/index';
import { SealedWaxSearch, SportsCardSearch } from '../../services/sciApi/search/types';
import useCollectiblesSearchInfinite, {
  CollectiblesSearchItem,
  UseCollectiblesSearchInput,
  UseCollectiblesSearchOutput,
  useCollectiblesSearchPaginated,
  UseCollectiblesSearchQueryKey,
} from './useCollectiblesSearch';

export const convertCollectiblesSearchItemsToOldSearchItems = (
  items: CollectiblesSearchItem[]
): (SportsCardSearch | SealedWaxSearch)[] => {
  return items.map(({ item }) => {
    if (item.collectibleType === 'sports-card') {
      return {
        sportName: item.sport.name,
        sportId: Number(item.sport.id),
        specificQualifier: item.specificQualifier,
        gradeName: item.grade.name,
        printRun: item.setVariation.printRun,
        playerAlias: item.player.aliases?.[0],
        playerId: Number(item.player.id),
        setName: item.set.name,
        gradeId: Number(item.grade.name),
        setId: Number(item.set.id),
        packOdds: item.setVariation.packOdds,
        playerName: item.player.name,
        setYear: item.set.year,
        cardNumber: item.cardNumber,
        rc: item.isRookie,
        variationId: Number(item.setVariation.variation.id),
        variationName: item.setVariation.variation.name,
        populationCount: item.stats.currentPopulationCount,
        id: Number(item.id),
        adminImageUrl: item.imageUrl,
        allEndAvg: item.stats.all.endAvgPrice,
        lastSaleDate: item.stats.lastSaleDate,
        imageUrl: item.gradelessImageUrl,
        last14Count: item.stats.last14.totalSalesCount,
        last14EndAvg: item.stats.last14.endAvgPrice,
        last14StartAvg: item.stats.last14.startAvgPrice,
        last14Total: item.stats.last14.totalSalesAmount,
        last14ChangeAmount: item.stats.last14.priceChangeAmount,
        last14ChangePercentage: item.stats.last14.priceChangePercentage,
      } as SportsCardSearch;
    }
    if (item.collectibleType === 'sealed-wax-card') {
      return {
        sportName: item.sport.name,
        sportId: Number(item.sport.id),
        boxTypeName: item.boxType.name,
        boxTypeId: Number(item.boxType.id),
        setName: item.set.name,
        setId: Number(item.set.id),
        setYear: item.set.year,
        id: Number(item.id),
        adminImageUrl: item.imageUrl,
        allEndAvg: item.stats.all.endAvgPrice,
        lastSaleDate: item.stats.lastSaleDate,
        imageUrl: item.imageUrl,
        last14Count: item.stats.last14.totalSalesCount,
        last14EndAvg: item.stats.last14.endAvgPrice,
        last14StartAvg: item.stats.last14.startAvgPrice,
        last14Total: item.stats.last14.totalSalesAmount,
        last14ChangeAmount: item.stats.last14.priceChangeAmount,
        last14ChangePercentage: item.stats.last14.priceChangePercentage,
      } as SealedWaxSearch;
    } else {
      return {} as SportsCardSearch;
    }
  });
};

/**
 * Please do not use for new featured / rework. Use useCollectiblesSearchPaginated instead
 */
export function usePaginatedCollectiblesSearchOld(
  input: UseCollectiblesSearchInput,
  options?: MmApiQueryOptions<UseCollectiblesSearchOutput, UseCollectiblesSearchQueryKey>
) {
  const result = useCollectiblesSearchPaginated(input, options);

  const items = useMemo(() => convertCollectiblesSearchItemsToOldSearchItems(result.items ?? []), [result.items]);

  return {
    ...result,
    items,
    totalCount: result?.totalCount,
  };
}

/**
 * Please do not use for new featured / rework. Use useCollectiblesSearchInfinite instead
 */
export default function useCollectiblesSearchOld(
  input: UseCollectiblesSearchInput,
  options?: MmApiInfiniteQueryOptions<UseCollectiblesSearchOutput, UseCollectiblesSearchQueryKey>
) {
  const result = useCollectiblesSearchInfinite(input, options);
  const allItems = useMemo(() => convertCollectiblesSearchItemsToOldSearchItems(result.items), [result.items]);

  return {
    ...result,
    allItems,
    verifiedSpellCheckedAlternatives: result.spellCheckSuggestions,
    totalCount: result.totalCount,
  };
}

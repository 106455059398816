import { useQuery, UseQueryOptions } from 'react-query';
import {
  getPopulationChartData,
  GetPopulationChartDataParams,
  GetPopulationChartDataResponse,
} from '../../../services/sciApi/charts/index';
import { ApiError } from '../../../utils/api';
import useAuth from '../../auth/useAuth';

export type { PopulationChartDataPoint } from '../../../services/sciApi/charts/index';

type UseChartDataParams = GetPopulationChartDataParams;
type Key = ['pop-count-chart', GetPopulationChartDataParams];

export default function usePopulationCountChartData(
  params: UseChartDataParams,
  options: UseQueryOptions<GetPopulationChartDataResponse, ApiError, GetPopulationChartDataResponse, Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResults = useQuery(
    ['pop-count-chart', params],
    async ({ signal }) => getPopulationChartData(params, signal),
    {
      staleTime: 1000 * 60 * 60 * 6, // 6 hours
      ...options,
      enabled: !!isLoggedIn && !!params.collectibleId && (options?.enabled ?? true),
    }
  );
  return queryResults;
}

import { useCallback } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { CollectionCategory } from '@sportscardinvestor/schemas';
import FieldLabelBox from '../../../sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import TextField from '../../../sci-ui-components/forms/TextField/TextField';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import useCollectionCategoryMutations from '../useCollectionCategoryMutations';
import { AddOrEditCollectionCategoryDialogInfoProps } from './types';
import { trackEvent } from '@/features/analytics/trackEvent';

const formId = 'add-collection-category';
const nameFieldId = `${formId}-name`;

type FormValues = {
  name: string;
};
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  name: Yup.string().min(1).required('Required'),
});

export default function AddOrEditCollectionCategoryDialog({
  isOpen,
  onClose,
  onCreated,
  onEdited,
  collectibleType,
  name: initialName,
  categoryId,
}: AddOrEditCollectionCategoryDialogInfoProps & {
  isOpen: boolean;
  onClose: () => void;
  onCreated?: (createdCategory: CollectionCategory) => void;
  onEdited?: () => void;
}) {
  const { create, update } = useCollectionCategoryMutations();

  const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    if (categoryId) {
      await update.mutateAsync(
        {
          id: categoryId,
          name: values.name,
          collectibleType,
        },
        {
          onSuccess: () => {
            trackEvent({
              eventName: 'COLLECTION_CATEGORY_EDIT_COMPLETED',
              collectibleType,
            });
          },
          onError: () => {
            trackEvent({
              eventName: 'COLLECTION_CATEGORY_EDIT_FAILED',
              collectibleType,
            });
          },
        }
      );
      onEdited?.();
    } else {
      const newCategory = await create.mutateAsync(
        {
          name: values.name,
          collectibleType,
        },
        {
          onSuccess: () => {
            trackEvent({
              eventName: 'COLLECTION_CATEGORY_ADD_COMPLETED',
              collectibleType,
            });
          },
          onError: () => {
            trackEvent({
              eventName: 'COLLECTION_CATEGORY_ADD_FAILED',
              collectibleType,
            });
          },
        }
      );
      onCreated?.(newCategory);
    }
    onClose();
    setSubmitting(false);
    resetForm();
  };

  const handleCancel = useCallback(() => {
    trackEvent({
      eventName: categoryId ? 'COLLECTION_CATEGORY_EDIT_CANCELLED' : 'COLLECTION_CATEGORY_ADD_CANCELLED',
      collectibleType,
    });
    onClose();
  }, [onClose, collectibleType, categoryId]);

  return (
    <Formik
      initialValues={{
        name: initialName ?? '',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ values, handleChange, errors, isSubmitting }) => (
        <ModalDialog
          open={isOpen}
          title={categoryId ? 'Edit Collection Category' : 'Add New Collection Category'}
          okText={categoryId ? 'Update' : 'Create'}
          onCancel={handleCancel}
          cancelText="Cancel"
          okButtonProps={{
            htmlType: 'submit',
            form: formId,
            disabled: isSubmitting,
          }}
          cancelButtonProps={{
            disabled: isSubmitting,
          }}
        >
          <Form id={formId}>
            <FieldLabelBox label="New Category Name" fieldId={nameFieldId} error={errors.name}>
              <TextField
                id={nameFieldId}
                name="name"
                value={values.name}
                onChange={(value, name) => handleChange({ target: { name, value } })}
              />
            </FieldLabelBox>
          </Form>
        </ModalDialog>
      )}
    </Formik>
  );
}

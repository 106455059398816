import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import { formatDateInTimeZone } from '../../../sci-ui-components/utils/date';
import searchUntrackedCollectibleSales, {
  SearchUntrackedCollectiblesParams,
  SearchUntrackedCollectiblesResponse,
} from '../../../services/sciApi/sales/searchUntrackedCollectibleSales';
import { ApiError } from '../../../utils/api';
import useAuth from '../../auth/useAuth';
import { UntrackedCollectibleSalesSearchFilters, UntrackedCollectibleSalesAdvancedFilters } from './types';
import useUntrackedCollectibleSalesParams from './useUntrackedCollectibleSalesParams';

export const keyPrefix = 'untracked-sales';
type Key = [typeof keyPrefix, SearchUntrackedCollectiblesParams];

export function useUntrackedCollectibleSales(
  {
    filters,
    advancedFilters,
    collectibleType,
    truncateLimit,
  }: {
    filters: UntrackedCollectibleSalesSearchFilters;
    advancedFilters: UntrackedCollectibleSalesAdvancedFilters;
    collectibleType: CollectibleType;
    truncateLimit?: number;
  },
  options: UseQueryOptions<SearchUntrackedCollectiblesResponse, ApiError, SearchUntrackedCollectiblesResponse, Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const { params, isLoading } = useUntrackedCollectibleSalesParams({
    filters,
    advancedFilters,
    collectibleType,
  });
  const queryResult = useQuery(
    [keyPrefix, params],
    async ({ signal }) => searchUntrackedCollectibleSales(params, signal),
    {
      staleTime: 1000 * 60 * 60 * 6, // 6 hours - more aggressive as this api is expensive
      ...options,
      enabled: !!isLoggedIn && (options?.enabled ?? true),
    }
  );

  const truncatedItems = useMemo(() => {
    const items = queryResult?.data?.items;
    if (truncateLimit && items?.length) {
      return items.slice(0, truncateLimit);
    }
    return items || [];
  }, [queryResult?.data?.items, truncateLimit]);

  const lastDayAverageSalePrice = useMemo(() => {
    let lastDayTimestamp: string | null = null;
    const lastDaySales = truncatedItems
      .sort((a, b) => (a.endDateTime < b.endDateTime ? 1 : -1))
      .filter(({ endDateTime }) => {
        if (!lastDayTimestamp) {
          lastDayTimestamp = formatDateInTimeZone(endDateTime);
        }
        return formatDateInTimeZone(endDateTime) === lastDayTimestamp;
      });

    return lastDaySales.reduce((acc, { finalPrice }) => (acc += finalPrice), 0) / lastDaySales.length;
  }, [truncatedItems]);

  return {
    ...queryResult,
    truncatedItems,
    lastDayAverageSalePrice,
    isLoading: isLoading || queryResult.isLoading,
  };
}

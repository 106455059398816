import { CollectibleType } from '@sportscardinvestor/schemas';
import { create } from 'zustand';
import { trackEvent } from '@/features/analytics/trackEvent';

interface EditCustomCollectibleValueDialogState {
  customCollectibleId: number | null;
  collectibleType: CollectibleType;
  close: () => void;
}

const defaultCollectibleType: CollectibleType = 'sports-card';

export const useEditCustomCollectibleValueDialogStore = create<EditCustomCollectibleValueDialogState>()((set) => ({
  customCollectibleId: null,
  collectibleType: defaultCollectibleType,
  close: () => set({ customCollectibleId: null, collectibleType: defaultCollectibleType }),
}));

export interface EditCustomCollectibleValueDialogProps {
  customCollectibleId: number;
  collectibleType: CollectibleType;
}
export function showEditCustomCollectibleValueDialog({
  customCollectibleId,
  collectibleType,
}: EditCustomCollectibleValueDialogProps) {
  useEditCustomCollectibleValueDialogStore.setState({
    customCollectibleId,
    collectibleType,
  });
  trackEvent({
    eventName: 'COLLECTION_CHECK_PRICE_STARTED',
    collectibleType,
  });
}

import { startOfWeek, startOfMonth, parseISO } from 'date-fns';
import { UntrackedCollectibleSale } from '../../../../sci-ui-components/types/sales';
import { formatISODate, getISODatesInRange } from '../../../../sci-ui-components/utils/date';
import { sortByStringField } from '../../../../utils/sort';
import { GroupOption } from '../../useChartSettings';
import { AggregatedUntrackedCollectibleSales } from '../types';

export type IntermediateAggregationValues = {
  totalAverageSalePrice: number;
  totalSalesNumber: number;
};

export default function aggregateUntrackedCollectibleSales(
  data: UntrackedCollectibleSale[] | undefined,
  groupBy: GroupOption
): AggregatedUntrackedCollectibleSales[] {
  if (!data?.length) {
    return [];
  }
  const sortedData = sortByStringField(data, (item) => item.endDateTime) ?? [];
  // NOTE: ensure there are no holes = every groupKey in range is present
  const allGroupKeys = getAllGroupKeys(sortedData, groupBy);
  // NOTE: create map of groupKey to intermediate aggregation values
  const dataByDateMap = (sortedData ?? []).reduce<Map<string, IntermediateAggregationValues | null>>((acc, item) => {
    const groupKey = getGroupValue(item.endDateTime, groupBy);
    if (!acc.has(groupKey)) {
      // NOTE: should not happen, but handling anyway to be safe
      const groupValue: IntermediateAggregationValues = makeInitialAggregationValues(item);
      acc.set(groupKey, groupValue);
    } else {
      const group = acc.get(groupKey)!;
      const groupValue = group ? aggregateValues(group, item) : makeInitialAggregationValues(item);
      acc.set(groupKey, groupValue);
    }
    return acc;
  }, new Map(allGroupKeys.map((groupKey) => [groupKey, null])));
  // NOTE: map intermediate values to final aggregated values
  const aggregated = Array.from(dataByDateMap.entries()).map<AggregatedUntrackedCollectibleSales>(
    ([groupKey, values]) => ({
      groupKey,
      averageSalePrice: values ? values.totalAverageSalePrice / values.totalSalesNumber : null,
      totalSalesCount: values?.totalSalesNumber ?? null,
    })
  );
  return sortByStringField(aggregated, (item) => item.groupKey);
}

function getAllGroupKeys(sortedData: UntrackedCollectibleSale[], groupBy: GroupOption): string[] {
  const startDate = formatISODate(parseISO(sortedData[0].endDateTime));
  const endDate = formatISODate(parseISO(sortedData[sortedData.length - 1].endDateTime));
  const allDates = getISODatesInRange([startDate, endDate]);
  return allDates.reduce<string[]>((acc, date) => {
    const groupKey = getGroupValue(date, groupBy);
    if (!acc || acc[acc.length - 1] !== groupKey) {
      acc.push(groupKey);
    }
    return acc;
  }, []);
}

function getGroupValue(date: string, groupBy: GroupOption): string {
  switch (groupBy) {
    case 'day':
      return formatISODate(parseISO(date));
    case 'month':
      return formatISODate(startOfMonth(parseISO(date)));
    case 'week':
      return formatISODate(startOfWeek(parseISO(date)));
    default:
      return date;
  }
}

function makeInitialAggregationValues(item: UntrackedCollectibleSale): IntermediateAggregationValues {
  return {
    totalAverageSalePrice: item.finalPrice,
    totalSalesNumber: 1,
  };
}

function aggregateValues(
  acc: IntermediateAggregationValues,
  item: UntrackedCollectibleSale
): IntermediateAggregationValues {
  return {
    totalAverageSalePrice: acc.totalAverageSalePrice + item.finalPrice,
    totalSalesNumber: acc.totalSalesNumber + 1,
  };
}

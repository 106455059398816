import { useContext } from 'react';
import { CollectibleTypeContext } from './CollectibleTypeContextProvider';

export default function useCollectibleType() {
  const value = useContext(CollectibleTypeContext);
  if (!value) {
    throw new Error('useCollectibleType must be used within CollectibleTypeContextProvider');
  }
  return value;
}
